<template>
  <svg fill="none" width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.29 17.251C13.837 17.251 17.965 10.998 17.965 5.57596C17.965 5.39796 17.965 5.22096 17.953 5.04596C18.7562 4.46448 19.4493 3.74457 20 2.91996C19.2511 3.25196 18.4566 3.46972 17.643 3.56596C18.4996 3.05302 19.1408 2.24631 19.447 1.29596C18.6417 1.77384 17.7607 2.11069 16.842 2.29196C16.2234 1.63372 15.405 1.19779 14.5136 1.05166C13.6222 0.905532 12.7075 1.05736 11.9111 1.48363C11.1147 1.9099 10.4811 2.58683 10.1083 3.40962C9.7355 4.2324 9.64437 5.15514 9.849 6.03496C8.21759 5.9532 6.6216 5.52928 5.16465 4.79073C3.70769 4.05217 2.42233 3.01548 1.392 1.74796C0.867318 2.65116 0.706589 3.72038 0.942536 4.73792C1.17848 5.75547 1.79337 6.64483 2.662 7.22496C2.00926 7.20606 1.37063 7.03046 0.8 6.71296V6.76496C0.800389 7.71225 1.1284 8.63026 1.7284 9.36331C2.3284 10.0964 3.16347 10.5993 4.092 10.787C3.48781 10.9517 2.85389 10.9756 2.239 10.857C2.50116 11.6724 3.01168 12.3856 3.69913 12.8966C4.38658 13.4076 5.21657 13.6909 6.073 13.707C5.22212 14.3755 4.24779 14.8698 3.20573 15.1615C2.16367 15.4532 1.07432 15.5366 0 15.407C1.8766 16.6113 4.06019 17.2501 6.29 17.247"
        :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "TwitterIcon",
  props: {
    fill: {
      required: false,
      default: '#9EADC1'
    }
  },
}
</script>