<template>
  <div class="dashboard_user_info">
    <div class="dashboard_user_info_top">
      <div class="user_info">
        <h2 class="user_name">Hello, <b>{{ user.name }}</b>
          <div class="hand_icon">
            <CopyIcon @click="copy"/>
          </div>
        </h2>
      </div>

      <button class="primary" @click="$emit('create')">+Create Gift</button>
    </div>
    <div class="dashboard_user_info_bottom">
      <div class="dashboard_user_info_bottom_head">
        <FilterDate :items="filterItems" :selected="selected" @change-filter="changeFilter"/>
        <h3>{{ user?.role_id === 1 ? 'Gift Funds' : 'Spendings' }}</h3>
      </div>
      <div class="earnings">
        <span class="total_earnings">{{ formatAmount(funds[current], user?.location?.code) }}</span>
        <span class="today_earnings">+{{ formatAmount(funds.today, user?.location?.code) }} Today</span>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from "@/router/components/icons/CopyIcon.vue";
import {mapGetters} from "vuex";
import FilterDate from "@/router/components/dashboard/FilterDate.vue";
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "UserInfo",
  components: {FilterDate, CopyIcon},
  mixins: [currencyHelper],
  computed: {
    ...mapGetters({user: 'auth/user'}),
  },
  data() {
    return {
      current: 'month',
      funds: {},
      filterItems: ['30D', '1Y', 'All'],
      selected: '30D'
    }
  },
  mounted() {
    this.getFunds()
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(`giftxyz.com/${this.user.username}`)
      this.toast.info('Your link has been copied: giftxyz.com/' + this.user.username)
    },
    getFunds() {
      this.axios.get('/order/funds').then(res => {
        this.funds = res.data
      })
    },
    changeFilter(item) {
      this.selected = item
      if (item === '30D') {
        this.current = 'month'
      } else if (item === '1Y') {
        this.current = 'year'
      } else {
        this.current = 'all'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard_user_info {
  max-width: 300px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }

  .user_name {
    font-weight: 400;
    font-size: 50.4px;
    line-height: 50px;
    color: #0B254E;
    @media (max-width: 768px) {
      font-size: 31.64px;
      line-height: 32px;
    }

    svg {
      width: 37px;
      height: 37px;
      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .hand_icon {
    margin-left: 15px;
    display: inline-block;
  }

  .primary {
    font-weight: 700;
    font-size: 24.3936px;
    line-height: 37px;
    width: 293px;
    height: 72px;
    margin-top: 19px;
    @media (max-width: 768px) {
      width: 100%;
      height: 45px;
      padding: 0;
      font-size: 13.552px;
      line-height: 20px;
      margin-top: 20px;
    }

    svg {
      color: #fff;
    }
  }

  .dashboard_user_info_bottom {
    margin-top: 12px;
    @media (max-width: 768px) {
      margin-top: 0;
    }

    .dashboard_user_info_bottom_head {
      @media (max-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 39px;
      }

      h3 {
        font-weight: 700;
        font-size: 50px;
        line-height: 43px;
        color: #0B254E;
        white-space: nowrap;
        margin-top: 27px;
        @media (max-width: 768px) {
          font-size: 32px;
          line-height: 28px;
          margin-top: 23px;
        }
      }
    }

    .earnings {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 12px;

      @media (max-width: 768px) {
        margin-top: 5px;
        gap: 9px;
      }

      .total_earnings {
        font-weight: 700;
        font-size: 50.4px;
        color: #0B254E;
        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      .today_earnings {
        font-weight: 700;
        font-size: 27px;
        color: #00AFF0;
        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>