<template>
    <div class="input_label">
        <h4>{{ label }}</h4>
        <div class="input_block">
            <span v-if="price">$</span>
            <input type="text" @input="$emit('update:modelValue', $event.target.value)">
        </div>
    </div>
</template>

<script>
export default {
    name: "InputLabel",
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        price: {
            type: Boolean,
            required: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.input_label {
  width: 100%;
  margin-bottom: 18px;

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0B254E;
    margin-bottom: 6px;
  }

  .input_block {
      display: flex;
      align-items: center;
    background: #EEEEEE;
    border-radius: 12px;
    width: 100%;
    height: 45px;

      span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 43px;
          height: 45px;
          font-size: 16px;
          line-height: 24px;
          color: #0B254E;
          background: #EEEEEE;
          border: 1px solid rgba(0, 0, 0, 0.5);
          border-radius: 12px;
      }

    input {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      padding-left: 10px;
    }
  }
}
</style>