<template>
  <div class="verify-main">
    <div class="card-verify">
      <h1 v-if="verified" class="message-title">Verified!</h1>
      <h1 v-else class="message-title">Failed</h1>
      <p>{{ message }}</p>
      <img v-if="verified" src="../../../assets/images/success-verified.png" alt="#">
      <img v-else src="../../../assets/images/failed-verified.png" alt="#">
    </div>
  </div>
</template>

<script>
export default {
  name: "StripeVerifyPage",
  data() {
    return{
      verified: false,
      message: false,
    }
  },
  methods:{
    getPathFromUrl(url) {
      return url.split(/[?#]/)[0];
    }
  },
  mounted() {
    const queryString = window.location.search;
    const status = new URLSearchParams(queryString);
    let valid = status.get('valid');
    if(valid){
      this.verified = true
      this.message = 'Stripe Account is verified'
    }else if (!valid){
      this.verified = false
      this.message = 'Oops! Having problems with verification'
    }
    const url =  this.getPathFromUrl(window.location.href);
    window.history.pushState({}, '', url);
  }
}
</script>

<style lang="scss" scoped>
.message-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #0B254E;
}

.verify-main {
  height: 83vh;
  display: flex;
  align-items: center;
}

h1 {
  color: #88B04B;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.color-red {
  color: red;
}

p {
  color: #404F5E;
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}


.dark {
  .card-verify {
    background: #272727;

    p {
      color: white;
    }

    .message-title {
      color: white;
    }
  }
}

.card-verify {
  background: white;
  padding: 38px 0 64px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
  width: 480px;
  height: auto;
  text-align: center;

  img {
    padding-top: 58px;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 20px;
  }
  @media (max-width: 375px) {
    p {
      font-size: 18px;
    }
  }
}


.blue-background {
  background: aliceblue !important;
}

.checkmark {
  &.red {
    color: red;
  }
}
</style>