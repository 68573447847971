import BuyForm from "@/router/Modals/BuyGift/BuyForm.vue";
export default {
    data() {
        return {
            buyModalStatus: false,
        }
    },
    watch: {
        "$store.state.buyProductId"(n) {
            if (n) {
                this.buyModalStatus = true
                console.log(n)
            }
        },
    },
    components: {
        BuyForm
    },
    methods: {
        purchase() {
            this.buyModalStatus = false
        }
    }
}