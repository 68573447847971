<template>
  <base-modal
      v-if="dialog"
      @close="dialog = false"
      :width="'480px'"
      :height="'311px'"
  >
    <template v-slot:body>
      <div class="d-flex flex-column align-items-center gap-12 modal-body-main">
        <div class="modal-body-image">
          <img v-if="receiver.avatar" :src="receiver.avatar" alt="#">
          <img v-else src="@assets/images/user-default.png" alt="#">
        </div>
        <div class="modal-body-title">
          <p>
            {{ sended ? '  Send amount' : 'Request amount' }}
          </p>
        </div>
        <div class="modal-body-desc">
          <p>
            Type the amount you want to {{ sended ? 'send' : 'request' }}
          </p>
        </div>
        <div class="modal-body-input">
          <base-input
              v-model="requestAmount"
              :input-type="'number'"
              :input-name="'amount'"
          >
            <template v-slot:inputEndIcon>
              {{ currencyCode }}
            </template>
          </base-input>
        </div>

        <div class="modal-body-tip">
          <p @click="sendRequestTip">
            {{ sended ? 'Send' : 'Request' }} a tip <span v-if="requestAmount">(${{ requestAmount }})</span> <span
              v-else>({{ currencyCode }}0)</span>
          </p>
        </div>

      </div>
    </template>
  </base-modal>
</template>

<script>
import currencyHelper from "../../mixins/currencyHelper";
import BaseModal from "../UI/BaseModal";
import BaseInput from "../UI/BaseInput";

export default {
  name: "SendAmountModal",
  components: {
    'base-modal': BaseModal,
    'base-input': BaseInput
  },
  mixins: [currencyHelper],
  data() {
    return {
      dialog: !!this.value,
      openModal: false,
      requestAmount: null
    }
  },
  props: {
    value: {
      required: false
    },
    receiver: {
      required: false
    },
    sended: {
      required: false
    }
  },
  watch: {
    value() {
      this.dialog = !!this.value;
      if (this.dialog === false) {
        this.requestAmount = null;
      }
    },
    dialog(n) {
      if(n){
        document.querySelector('html').classList.add('overflow-hidden')
      }else {
        document.querySelector('html').classList.remove('overflow-hidden')
      }
      this.$emit("input", this.dialog);
    },
  },
  methods: {
    sendRequestTip() {
      this.$emit('send', this.requestAmount)
    }
  }
}
</script>