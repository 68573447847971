<template>
    <label :class="{labelPrice: price}">
        <span>{{ label }}</span>
        <input type="text" @input="$emit('update:modelValue', $event.target.value)"/>
    </label>
</template>

<script>

export default {
    name: 'BuyInput',
    props: {
        modelValue: {
            type: String,
            required: false
        },
        label: {
            type: String
        },
        value: {
            require: false
        },
        price: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
}

</script>

<style scoped lang="scss">

label {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 21px;

  span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b254e;
    margin-bottom: 6px;
  }
}

input {
  background: #eeeeee;
  border-radius: 12px;
  min-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
}

.labelPrice {
  input {
    padding-left: 50px;
  }

  span {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 43px;
      height: 45px;
      padding: 10px 12px 11px 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      background: url("../../../assets/images/$.svg") no-repeat center center;
      color: #0b254e;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 12px;
    }
  }
}
</style>
