<template>
  <div class="th-container">
    <h2>Almost there</h2>
    <p>We’ve sent you an email to verify your account</p>
    <img src="@/assets/images/almost_there.svg" alt="Almost there">
  </div>
</template>

<script>
import preloader from "@/mixins/preloader";

export default {
  name: "VerifyPage",
  mixins: [preloader],
  mounted() {
    this.setLoading(false);
  }
}
</script>

<style lang="scss" scoped>

.th-container {
  max-width: 480px;
  min-height: calc(100vh - 152px);
  text-align: center;
  margin-top: 74px;
  padding-top: 87px;

  @media screen and (max-width: 480px) {
    padding-top: 40px;
  }

  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #252525;
    text-align: left;
    @media screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  p {
    font-weight: 300;
    font-size: 21px;
    line-height: 26px;
    color: #252525;
    margin-top: 40px;
    text-align: left;
    @media screen and (max-width: 480px) {
      font-size: 14px;
      line-height: 17px;
      margin-top: 27px;
    }
  }

  img {
    padding-top: 53px;
    @media screen and (max-width: 480px) {
      padding-top: 36px;
      width: 70%;
    }
  }

}
</style>