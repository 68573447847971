<template>
  <div class="success_page">
    <div class="back" @click="back">
      <BackIcon/>
    </div>

    <div class="success_block">
      <img src="@/assets/images/checkout_fail.svg" alt="Fail">
      <h2>Payment Failed</h2>
      <p>Your payment was unsuccessful. Please try again later or use a different payment method.</p>
      <div class="buttons">
        <router-link to="/" class="secondary">Go Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/router/components/icons/BackIcon.vue";
import preloader from "@/mixins/preloader";

export default {
  name: "SuccessPage",
  mixins: [preloader],
  components: {BackIcon},
  mounted() {
    this.setLoading(false);
  },
  methods: {
    back() {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss" scoped>
.success_page {
  background-image: url("@assets/images/checkout_bg.png");
  background-color: #F7F7F7;
  background-size: 100% 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .back {
    position: absolute;
    top: 62px;
    left: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #252525;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .success_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 106px;
      text-align: center;
      color: #0B254E;
      margin-top: 18px;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 66px;
        margin-top: 11px;
      }
    }

    p {
      max-width: 441px;
      width: 100%;
      font-weight: 600;
      font-size: 25.6px;
      line-height: 43px;
      text-align: center;
      color: #0B254E;
      opacity: 0.5;
      @media (max-width: 768px) {
        max-width: 276px;
        font-size: 16px;
        line-height: 27px;
        margin-top: 11px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 26px;
      margin-top: 51px;
      max-width: 441px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 276px;
        margin-top: 32px;
        gap: 16px;
      }

      a {
        border-width: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 25.6px;
        line-height: 32px;
        height: 73.6px;
        padding: 0;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
          height: 46px;
        }
      }
    }

  }
}
</style>