<template>
  <div class="leaderboard_container">
    <div class="th-container">
      <div class="recent_gifts_section">
        <h2>Recent Gifts</h2>
        <div class="random_users">
          <swiper
              :breakpoints="{
                        '320': {
                            slidesPerView: 3.5,
                            spaceBetween: 20
                        },'768': {
                            slidesPerView: 10,
                            spaceBetween: 20
                        },
                    }">
            <swiper-slide v-for="recentGift in recentGifts" :key="recentGift.id">
              <div class="random_user">
                <router-link :to="{name: 'user-profile', params: {username: recentGift.username}}"
                             class="image">
                  <img v-if="recentGift.avatar" :src="recentGift.avatar" alt="Avatar">
                  <img v-else width="48" height="48" src="@assets/images/user-default.png"
                       alt="Avatar">
                  <svg v-if="checkIsOnline(recentGift.id)" width="14" height="14" viewBox="0 0 14 14"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="6" fill="#20D930" stroke="white" stroke-width="2"/>
                  </svg>
                  <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7" cy="7" r="6" fill="#C1C1C1" stroke="white" stroke-width="2"/>
                  </svg>
                </router-link>
                <button @click="followUser(recentGift.id)"
                        :class="authenticated && user.favorites.find(e => e.id === recentGift.id) ? 'secondary' : 'primary'">
                  {{
                    authenticated && user.favorites.find(e => e.id === recentGift.id) ? 'Unfollow' : 'Follow'
                  }}
                </button>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="th-container bottom">
      <div class="tabs">
        <button v-on:click="creatorsTab" class="creatorsTab">Top Creators</button>
        <button v-on:click="tippersTab" class="tippersTab transparent">Top Gifters</button>
      </div>
      <div class="creators">
        <h1 class="title">Top Creators</h1>
        <div class="cards">
          <div class="card" v-for="(creator, index) in creators" :key="index">
            <div class="content">
              <p class="number">{{ index + 1 }}</p>
              <img v-if="creator.avatar && creator.display_account_on_leaderboard" :src="creator.avatar" alt="Avatar">
              <img v-else width="48" height="48" src="@assets/images/user-default.png" alt="Avatar">
              <div class="info">
                <h5 class="username">
                  <template v-if="creator.display_account_on_leaderboard">
                    {{ creator.id === user.id ? 'You' : creator.name }}
                  </template>
                  <template v-else>
                    {{ creator.id === user.id ? 'You' : 'Anonymous' }}
                  </template>
                </h5>
                <p class="nick">
                  <template v-if="creator.display_account_on_leaderboard">
                    @{{ creator.id === user.id ? 'You' : creator.username }}
                  </template>
                  <template v-else>
                    {{ creator.id === user.id ? 'You' : 'Anonymous' }}
                  </template>
                </p>
              </div>
            </div>
            <div class="actions" v-if="creator.display_account_on_leaderboard">
              <div>
                <router-link :to="userLink(creator.username)">
                  <SmallArrow/>
                </router-link>
              </div>
            </div>
          </div>
          <button v-if="totalCreators >= ( creatorsPage - 1 ) * per_page" class="link-btn"
                  @click="getTopUsers(creatorsPage, per_page, 'creators')">Expand
          </button>
        </div>
      </div>
      <div class="tippers hide">
        <h1 class="title">Top Gifters</h1>
        <div class="cards">
          <div v-for="(tipper, index) in gifters" :key="index" class="card">
            <div class="content">
              <p class="number"> {{ index + 1 }}</p>
              <img v-if="tipper.avatar && tipper.display_account_on_leaderboard" :src="tipper.avatar" alt="Avatar">
              <img v-else src="@assets/images/user-default.png" alt="Avatar">
              <div class="info">
                <h5 class="username">
                  <template v-if="tipper.display_account_on_leaderboard">
                    {{ tipper.id === user.id ? 'You' : tipper.name }}
                  </template>
                  <template v-else>
                    {{ tipper.id === user.id ? 'You' : 'Anonymous' }}
                  </template>
                </h5>
                <p class="nick">
                  <template v-if="tipper.display_account_on_leaderboard">
                    @{{ tipper.id === user.id ? 'You' : tipper.username }}
                  </template>
                  <template v-else>
                    {{ tipper.id === user.id ? 'You' : 'Anonymous' }}
                  </template>
                </p>
              </div>
            </div>
            <div v-if="tipper.display_account_on_leaderboard" class="actions">
              <div>
                <router-link :to="userLink(tipper.username)">
                  <SmallArrow/>
                </router-link>
              </div>
            </div>
          </div>
          <button v-if="totalGifters >= ( giftersPage - 1 ) * per_page" class="link-btn"
                  @click="getTopUsers(giftersPage, per_page, 'gifters')">Expand
          </button>
        </div>
      </div>
    </div>

<!--    <div class="th-container">-->
<!--      <div class="time_section">-->
<!--        <p class="time">Time Until Ranks Reset</p>-->
<!--        <p v-if="isEnded">Ended.</p>-->
<!--        <p v-else class="timer">{{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s</p>-->
<!--      </div>-->
<!--    </div>-->

    <IndexLeaderboard/>
  </div>
</template>

<script setup>
import "./LeaderboardPage.scss";
import SmallArrow from "../../../assets/svg/SmallArrow.vue";
import IndexLeaderboard from "@/router/pages/PageIndex/IndexLeaderboard/IndexLeaderboard.vue";

const creatorsTab = () => {
  document.querySelector('.tippers').classList.add('hide')
  document.querySelector('.creators').classList.remove('hide')
  document.querySelector('.tippersTab').classList.add('transparent')
  document.querySelector('.creatorsTab').classList.remove('transparent')
}
const tippersTab = () => {
  document.querySelector('.tippers').classList.remove('hide')
  document.querySelector('.creators').classList.add('hide')
  document.querySelector('.creatorsTab').classList.add('transparent')
  document.querySelector('.tippersTab').classList.remove('transparent')

}
</script>

<script>
import {mapActions, mapGetters} from "vuex";
import currencyHelper from "../../../mixins/currencyHelper";
import {Swiper, SwiperSlide} from "swiper/vue";
import moment from "moment";
import preloader from "@/mixins/preloader";

export default {
  name: "LeaderboardPage",
  mixins: [currencyHelper, preloader],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      creators: [],
      per_page: 5,
      totalCreators: 0,
      creatorsPage: 1,
      gifters: [],
      totalGifters: 0,
      giftersPage: 1,
      recentGifts: [],
      endDate: moment().endOf('month'),
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null
    }
  },
  mounted: async function (){
    await this.getTopUsers(1, this.per_page, 'all')
    await this.getrecentGifts()
    this.tick()
    this.timer = setInterval(this.tick.bind(this), 1000)
  },
  destroy() {
    clearInterval(this.timer)
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      onlineUsers: "auth/onlineUsers",
      currency: "currency",
      authenticated: 'auth/authenticated'
    }),
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
    }),
    userLink(username) {
      return {
        name: username !== this.user.username ? 'user-profile' : 'profile',
        params: {username: username !== this.user.username ? username : null}
      }
    },
    getTopUsers(page, per_page, type) {
      this.setLoading(true);
      this.axios.get('leaderboard', {
            params: {
              page,
              per_page,
              type
            }
          }
      ).then((res) => {
        if (type === 'all') {
          this.creators = res.data.creators
          this.totalCreators = res.data.total_creators
          this.creatorsPage++

          this.gifters = res.data.gifters
          this.totalGifters = res.data.total_gifters
          this.giftersPage++
        } else if (type === 'creators') {
          this.creators = [...this.creators, ...res.data.creators]
          this.creatorsPage++;
        } else if (type === 'gifters') {
          this.gifters = [...this.gifters, ...res.data.gifters]
          this.giftersPage++
        }
      }).finally(() => {
        this.setLoading(false);
      })
    },
    getrecentGifts() {
      this.setLoading(true);
      this.axios.get('recent-gifts').then((res) => {
        this.recentGifts = res.data
      }).finally(() => {
        this.setLoading(false);
      })
    },
    checkIsOnline(id) {
      return this.onlineUsers.findIndex(i => i.id === id) > -1;
    },
    followUser(id) {
      if (this.authenticated) {
        this.setLoading(true);
        this.axios.post('user/follow', {id}).then(() => {
          this.updateUser()
        }).catch((res) => {
          this.toast.error(res.data.message)
        }).finally(() => {
          this.setLoading(false);
        })
      } else {
        this.$router.push({name: 'login'})
      }
    },
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    },
    tick() {
      const currentTime = new Date()
      const distance = Math.max(this.endDate - currentTime, 0)
      this.updateRemaining(distance)

      if (distance === 0) {
        clearInterval(this.timer)
        this.isEnded = true
      }
    }
  },
}
</script>
