<template>
    <div class="footer">
        <div class="th-container">
            <div class="footer-logo">
                <FooterLogo/>
            </div>

            <ul class="footer_menu">
                <li>
                    <router-link to="/contact">Contact us</router-link>
                </li>
                <li>
                    <a href="/files/privacy-policy.pdf" target="_blank">Privacy Policy</a>
                </li>
            </ul>

            <p class="copyrights">© 2024 Patrion. All rights reserved!.</p>

            <ul class="footer_menu">
                <li>
                    <a href="/files/terms_of_service.pdf" target="_blank">Terms of Service</a>
                </li>
                <li>
                    <a href="/files/cookie_policy.pdf" target="_blank">Cookie Policy</a>
                </li>
            </ul>

            <ul class="footer_menu mobile">
                <li>
                    <router-link to="/contact">Contact us</router-link>
                </li>
                <li>
                    <a href="/files/privacy-policy.pdf" target="_blank">Privacy Policy</a>
                </li>
                <li>
                    <a href="/files/terms_of_service.pdf" target="_blank">Terms of Service</a>
                </li>
                <li>
                    <a href="/files/cookie_policy.pdf" target="_blank">Cookie Policy</a>
                </li>
            </ul>

            <div class="socials">
                <!--        <img src="/svg/instagram.svg" alt="instagram"/>-->
                <a href="https://twitter.com/patrion" target="_blank">
                    <img src="/svg/twitter.svg" alt="twitter"/>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MainFooter',
    mounted() {
        if (this.$route.path == '/login' || this.$route.path == '/email-verify' || this.$route.path == '/register') {
            document.body.classList.remove('dark')
        }
    },
}
</script>

<script setup>
import FooterLogo from "@/router/components/icons/FooterLogo.vue";
</script>

<style lang="scss">
@import "./MainFooter.scss";
</style>
