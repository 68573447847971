<template>
  <div v-if="!authenticated" class="leaderbord-section">
    <p class="subtitle">DON’T WAIT</p>
    <h3 class="title">Be on the leaderboard with others.

    </h3>


    <div class="action">
      <button v-if="Object.keys(user).length === 0" class="primary"><router-link :to="{name:'login'}" class="color-white">Get started</router-link></button>
      <button v-else class="primary">
        <router-link v-if="$route.name !== 'profile'" :to="{name: 'profile'}" class="color-white">Get started</router-link>
        <a class="color-white" v-else >Get started</a>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "IndexLeaderboard",
  computed: {
    ...mapGetters({user: 'auth/user', authenticated: 'auth/authenticated'})
  },
}
</script>

<script setup>
import "./IndexLeaderboard.scss";
</script>
