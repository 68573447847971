import {mapGetters} from "vuex";

export default {
    data() {
        return {
            perText: {
                "daily": "day",
                "weekly": "week",
                "monthly": "month"
            }
        }
    },
    computed: {
        ...mapGetters({currency: "currency", defaultCurrency: "defaultCurrency"}),
        currencyCode() {
            return this.$store.state.currency.symbol === 'USD' ? '$' : this.$store.state.currency.symbol;
        },
        currencyId() {
            return this.$store.state.currency?.id || 1;
        }
    },
    methods: {
        conversationAmount(obj, amount = 0) {
            const currencyCode = obj.currency?.code;
            const selectedCurrencyCode = this.user.selected_currency?.code;
            if(selectedCurrencyCode === currencyCode) {
                return amount ? amount :1;
            }
            const conversions = obj.conversions || {};
            const combineKey = `${currencyCode}_${selectedCurrencyCode}`;

            const convert =  conversions[combineKey] || 1;
            return amount ? amount * convert : convert;
        },
        convertPrice(obj, amount = 0) {
            const currencyCode = obj.currency?.code;
            const selectedCurrencyCode = this.$store.state.currency?.code || 'EUR';
            if(selectedCurrencyCode === currencyCode) {
                return amount ? amount : 1;
            }
            const conversions = obj.currency?.exchange_rates ? JSON.parse(obj.currency?.exchange_rates) : {};
            const convert = conversions[selectedCurrencyCode] || 1;
            return amount ? amount * convert : convert;
        },
        preMadePrice(amount) {
            const defaultCurrency = this.$store.state.defaultCurrency
            const selectedCurrencyCode = this.$store.state.currency?.code || 'EUR'
            if(selectedCurrencyCode === defaultCurrency?.code) {
                return amount ? amount : 1;
            }
            const conversions = defaultCurrency?.exchange_rates ? JSON.parse(defaultCurrency?.exchange_rates) : {};
            const convert = conversions[selectedCurrencyCode] || 1;
            return amount ? amount * convert : convert;
        },
        convertToUsd(amount) {
            const defaultCurrency = this.$store.state.defaultCurrency || this.$store.state.currency
            const conversions = defaultCurrency?.exchange_rates ? JSON.parse(defaultCurrency?.exchange_rates) : {};
            const convert = conversions['USD'];
            return amount ? amount * convert : convert;
        },
        currencyToCurrency(amount, from, to) {
            const conversions = from?.exchange_rates ? JSON.parse(from?.exchange_rates) : {};
            const convert = conversions[to];
            return amount ? amount * convert : convert;
        },
        formatAmount(
            value,
            currency = this.$store.state.currency?.code,
            minFraction = 2,
            maxFraction = 2,
        ) {
            let currencyWithSymbol =
                value || ~value
                    ? Number(value).toLocaleString('en-US', {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: minFraction,
                        maximumFractionDigits: maxFraction,
                    })
                    : null;

            if (currencyWithSymbol) {
                const exceptions = {
                    TRY: '₺',
                    PLN: 'zł',
                };
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(exceptions)) {
                    if (currencyWithSymbol.includes(key)) {
                        currencyWithSymbol = currencyWithSymbol.replace(key, exceptions[key]).replace(/\u00a0/g, '');
                    }
                }
            }

            return currencyWithSymbol;
        },
    }
}