<template>
    <div class="cycle">
        <span
                v-for="option in options"
                :key="option.value"
                class="item secondary"
                :class="{active: modelValue === option.value}"
                @click="$emit('update:modelValue', option.value)"
        >{{ option.label }}</span>
    </div>
</template>

<script>
export default {
    name: "RadioButtons",
    props: {
        options: {
            type: Array,
            required: true
        },
        modelValue: {
            type: String,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
.cycle {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #00aff0;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: block;
    padding: 12px 24px;
    border: 1px solid #00aff0;
    border-radius: 8px;
    margin: 0 10px;
    cursor: pointer;
    transition: all .3s;

    &.active {
      background: #00aff0;
      color: #FFFFFF;
    }
  }
}
</style>