<template>
    <div class="buyGiftPurchase">
        <ChooseToCreate
                v-if="currentStage === 'chooseToCreate'"
                @close="$emit('close')"
                @selected="($event) => (currentStage = $event)"
        />
        <UploadWish
                v-if="currentStage === 'Wishlist'"
                @close="$emit('close')"
                @selected="($event) => (currentStage = $event)"
        />
        <CreatePost
                v-if="currentStage === 'Post'"
                @close="$emit('close')"
        />
        <WishPurchase
                v-if="currentStage === 'wishPurchase'"
                @close="$emit('close')"
        />
        <MembershipCreate
                v-if="currentStage === 'Member'"
                @close="$emit('close')"
        />
        <ChooseGiftType
                v-if="currentStage === 'chooseGiftType'"
                @close="$emit('close')"
        />
        <CompleteModal
                @close="$emit('close')"
                v-if="currentStage === 'complete'"
                title="Membership Created"
        />
        <AutotweetSettings v-if="false"/>
    </div>
</template>

<script setup>
import ChooseToCreate from "./ChooseToCreate.vue"
import UploadWish from "./BuyGift/UploadWish.vue"
import WishPurchase from "./BuyGift/WishPurchase.vue"
import MembershipCreate from "./BuyGift/MembershipCreate.vue"
import ChooseGiftType from "./BuyGift/ChooseGiftType.vue"
import CompleteModal from "./BuyGift/CompleteModal.vue"
import AutotweetSettings from "./Settings/AutotweetSettings.vue"
import CreatePost from "@/router/components/modals/create/CreatePost.vue"
</script>

<script>
import {mapGetters} from "vuex";

export default {
    name: "buyGiftPurchase",
    props: {
        stage: {
            required: false,
        },
    },
    data() {
        return {
            currentStage: null
        };
    },
    created() {
        this.currentStage = this.stage;
    },
    computed: {
        ...mapGetters("auth", ["user"]),
    },
};

</script>