<template>
    <div class="create_post_wrapper">
        <div class="overlay"></div>
        <div class="create_post_modal">
            <div class="top">
                <div class="avatar">
                    <img v-if="user.avatar" :src="user.avatar" alt="#">
                    <img v-else src="@assets/images/user-default.png" alt="#">
                </div>
                <BaseButton class="secondary" @click="toggleAudience">
                    Mem...
                    <ArrowDown/>
                </BaseButton>

                <label class="secondary">
                    Choose Photo
                    <input type="file" ref="postFile" @change="changeImage">
                </label>
            </div>

            <div class="middle">
                <img
                        :src="previewImage || require('@assets/images/dummy.png')"
                        class=""
                        alt="Post Image"
                />
            </div>

            <div class="bottom">
                <div @click="$emit('close')" class="cancel">Cancel</div>
                <BaseButton class="primary" @click="createPost">Post</BaseButton>
            </div>
            <div class="audience" :class="{active: audienceStatus}">
                <div class="close" @click="toggleAudience">
                    <CloseIcon/>
                </div>
                <h3>Choose audience</h3>
                <div class="memberships_list">
                    <div class="membership_item" :class="{active: selectedMembership === null}"
                         @click="selectMembership(null)">Public
                    </div>
                    <div class="membership_item" :class="{active: selectedMembership === membership.id}"
                         v-for="membership in user.memberships" :key="membership.id"
                         @click="selectMembership(membership.id)">
                        {{ membership.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CreatePost",
    data() {
        return {
            image: null,
            previewImage: null,
            selectedMembership: null,
            audienceStatus: false,
        }
    },
    computed: {
        ...mapGetters({user: 'auth/user'})
    },
    methods: {
        ...mapActions({
            updateUser: "auth/login",
        }),
        toggleAudience() {
            this.audienceStatus = !this.audienceStatus
        },
        changeImage(e) {
            const file = e.target.files[0]
            this.image = file
            this.previewImage = URL.createObjectURL(file)
        },
        selectMembership(membership) {
            this.selectedMembership = membership
            this.toggleAudience()
        },
        createPost() {
            const headers = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }

            const data = {
                image: this.image,
                membership_id: this.selectedMembership
            }

            this.axios.post('posts/create', data, headers).then(() => {
                this.updateUser()
                this.image = null
                this.previewImage = null
                this.selectedMembership = null
                this.$emit('close')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.create_post_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .create_post_modal {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 12px;
    z-index: 9999;
    max-width: 390px;
    width: 100%;
    padding: 25px 20px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    overflow: hidden;

    @media only screen and (max-width: 420px) {
      width: 90%;
    }

    .top {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 19px;

      .avatar {
        width: 48px;
        height: 48px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .secondary {
        display: flex;
        align-items: center;
        gap: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #00AFF0;
        padding: 0 13px;

        input {
          display: none;
        }
      }
    }

      .middle {
          img {
            width: 100%;
            height: 284px;
            object-fit: cover;
            border-radius: 16px;
          }
      }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 19px;

      .cancel {
        font-weight: 700;
        font-size: 20px;
        line-height: 40px;
        color: #0B254E;
          cursor: pointer;
      }

      .primary {
        padding: 8px 46px;
      }
    }

    .audience {
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      padding: 5px 0 29px;
      transition: all .3s ease;

      &.active {
        bottom: 0;
      }

      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        color: #0B254E;
        margin-bottom: 10px;
      }

      .memberships_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        .membership_item {
          width: 300px;
          margin: 0 auto;
          padding: 7px 0;
          border: 2px solid transparent;
          background: #00AFF0;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
          cursor: pointer;
          transition: all .3s ease;

          &.active {
            border-color: rgb(11 37 78 / 50%);
          }
        }
      }
    }
  }
}
</style>

<script setup>
import BaseButton from "@/router/UI/BaseButton.vue";
import CloseIcon from "@/router/components/icons/CloseIcon.vue";
import ArrowDown from "@/router/components/icons/ArrowDown.vue";
</script>