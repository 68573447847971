<template>
    <div
            :class="{'no-image': productImage === null || product.content && product.content.type && product.content.type.includes('video'), 'slider-product': sliderProduct, 'selectable': selectable, 'selected': selectedProduct?.id === product.id }"
            :style="productImage ? `background-image: url(${productImage})` : ''" class="product"
            @click="selectProduct">
        <div class="product-top-items">
            <div class="left-icon">
                <img width="28" src="@assets/images/product-link.png" alt="Product Link">
            </div>
            <div v-if="product.content && product.content.type && product.content.type.includes('video')"
                 class="right-icon">
                {{ product.content.duration }}
            </div>
        </div>

        <div class="product-footer-items">
            <div class="left-items position-relative">
                <!--        <img width="18" src="@assets/images/tip-lock.svg" alt="#">-->
                <div class="position-relative">
                    <p class="product-price text-truncate" @mouseover="showProductPrice = true"
                       @mouseleave="showProductPrice = false">{{ formatAmount(product.price, currency.code) }}</p>
                    <base-tooltip

                            v-if="showProductPrice"
                            :active="true"
                            :center="true"
                            :bgc="'#524D4D'"
                            :tooltip-text="formatAmount(product.price, currency.code)"
                    />
                </div>
                <div class="position-relative">
                    <p class="product-name text-truncate" @mouseover="showProductName = true"
                       @mouseleave="showProductName = false">{{ product.name }}</p>
                    <base-tooltip
                            v-if="showProductName"
                            :active="true"
                            :center="true"
                            :bgc="'#524D4D'"
                            :tooltip-text="product.name"
                    />
                </div>

            </div>
            <div class="right-items">
                <template v-if="selectable && product.creator_id === user.id">
                    <base-button
                            class="primary product-btn"
                    >
                        <span>Select{{ selectedProduct?.id === product.id ? 'ed' : '' }}</span>
                    </base-button>
                </template>
                <template v-else-if="product.creator_id !== user.id">
                    <base-button
                            class="primary product-btn"
                    >
                        <span @click="buyProduct(product.id)">Buy</span>
                    </base-button>
                </template>
                <template v-else-if="product.creator_id === user.id && !isMessage">
                    <base-button
                            class="primary product-btn"
                    >
                        <span @click="editProduct(product.id, product.type)">Edit</span>
                    </base-button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "../UI/BaseButton";
import BaseToolTip from "../UI/BaseToolTip";
import {mapGetters} from "vuex";
import currencyHelper from "../../mixins/currencyHelper";

export default {
    name: "ProductItem",
    components: {
        'base-button': BaseButton,
        'base-tooltip': BaseToolTip,
    },
    mixins: [currencyHelper],
    data() {
        return {
            product: null,
            productImage: null,
            showProductPrice: false,
            showProductName: false
        }
    },
    props: {
        productData: {
            required: true
        },
        sliderProduct: {
            required: false
        },
        productContent: {
            required: false
        },
        selectable: {
            required: false
        },
        selectedProduct: {
            required: false
        },
        isMessage: {
            required: false
        }
    },
    computed: {
        ...mapGetters({user: 'auth/user', currency: "currency"})
    },
    watch: {
        productData(n) {
            if (n) {
                this.$data.product = n
                this.$data.productImage = this.product.content && this.product.content.type && this.product.content.type.includes('image') ? this.product.content.path : null
                if (n.image) {
                    this.$data.productImage = process.env.VUE_APP_LIVE_URL + n.image
                }
            }
        },
        "product.currency_code"(n) {
            if (n) {
                this.$data.product.currency_code = n
            }
        },
        "product.image"(n) {
            if (n) {
                this.productImage = n.startsWith('blob') ? n : process.env.VUE_APP_LIVE_URL + n
            }
        },
        productContent(n) {
            if (n === null) {
                this.$data.productImage = null
            }
            if (n && !n.path) {
                this.$data.productImage = n.type.includes('image') ? URL.createObjectURL(n) : null
            }
        },
    },
    created() {
        this.product = this.productData
    },
    methods: {
        buyProduct(id) {
            if (id) {
                this.$store.state.buyProductId = id
            }
            this.$emit('open-modal', 'wishPurchase')
        },
        editProduct(id, type) {
            this.$store.state.editContactProductId = id;
            this.$store.state.editContactProductType = type
        },
        selectProduct() {
            if (this.selectable) {
                this.$emit('select-product')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.selectable {
  cursor: pointer;
}

.product {
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin: auto;
  padding: 12px;
  width: 176px;
  height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.selected {
    border: 4px solid #b6beca;
  }

  &.slider-product {
    display: flex !important;
    margin: unset !important;
    width: 176px !important;
  }

  &.no-image {
    background: url(../../assets/images/product-default-image.png) no-repeat;
  }

  .product-footer-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .product-top-items {
    display: flex;
    justify-content: space-between;

    .right-icon {
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 24px;
      color: #FFFFFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .product-btn {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #FFFFFF;
    padding: 0;
    width: 74px;
    height: 29px;
    background: #00AFF0;
    border-radius: 16px;
    line-height: 16px;
    transition: all .3s ease-in-out;

    &:hover {
      opacity: .9;
    }
  }

  .product-price, .product-name {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #FFFFFF;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 80px;
    text-align: left;
  }
}

</style>