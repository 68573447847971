import { createStore } from 'vuex'
import auth from "./modules/auth"
import home from "./modules/home"
import chat from "./modules/chat"
import cart from "./modules/cart"
import axios from '../packages/axios'

export default createStore({
    state: {
        siteMode: localStorage.getItem('mode'),
        currency: null,
        defaultCurrency: null,
        headerSearchQuery: null,
        recentlyUsers: null,
        editContactProductId: null,
        editContactProductType: null,
        buyProductId: null,
        loading: true,
    },

    getters: {
        currency(state) {
            return state.currency
        },
        defaultCurrency(state) {
            return state.defaultCurrency
        },
        buyProductId(state) {
            return state.buyProductId
        },
        loading(state) {
            return state.loading
        }
    },

    mutations: {
        SET_CURRENCY(state, value) {
            state.currency = value
        },
        SET_DEFAULT_CURRENCY(state, value) {
            state.defaultCurrency = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        }
    },
    actions: {
        getCurrency({commit}, currency) {
            return axios.post('current/currency', {
                currency: currency
            }).then(res => {
                commit('SET_CURRENCY', res.data.currency)
            }).catch(() => {
                commit('SET_CURRENCY', {})
            })
        },
        getDefaultCurrency({commit}, id) {
            return axios.get('currency?id='+id).then(res => {
                commit('SET_DEFAULT_CURRENCY', res.data.currency)
            }).catch(() => {
                commit('SET_DEFAULT_CURRENCY', {})
            })
        },
        setLoading({commit}, value) {
            commit('SET_LOADING', value)
        }
    },
    modules: {
        auth,
        home,
        chat,
        cart
    }
});