<template>
  <div class="login-container">
    <RegLeftBar/>
    <div class="form-section">
      <div class="login-form">
        <h1 class="title">Log in to Giftxyz</h1>
        <div class="form-input">
          <base-input
              v-model="form.login"
              :input-type="'text'"
              :input-name="'login'"
              :label-name="'Username or Email Address'"
              :label-class="'mb-12'"
              :blue="true"
          />
        </div>
        <div class="form-input password">
          <base-input
              v-model="form.password"
              :input-type="'password'"
              :input-name="'password'"
              :label-name="'Password'"
              :label-class="'mb-12'"
              :input-link="'Forgot password?'"
              :input-link-name="'forget-password'"
              :blue="true"
          />
        </div>
        <div class="checkbox">
          <input type="checkbox" id="checkbox" v-model="form.remember">
          <label for="checkbox">Remember Me</label>
        </div>

        <base-button
            class="primary register-btn"
            @click.prevent="login"
        >
          Log in
        </base-button>
        <div class="register-link">
          Not a member?
          <router-link class="link" :to="{name: 'register'}"> Register now.</router-link>
        </div>
        <button class="primary twitter" @click="twitterLogin">
          <TwitterIcon/>
          <span> Sign in With Twitter</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import './LoginPage.scss'
import RegLeftBar from '@/router/components/RegLeftBar/RegLeftBar.vue';
import BaseInput from "../../UI/BaseInput";
import BaseButton from "../../UI/BaseButton";
import TwitterIcon from '../../../assets/svg/TwitterIcon.vue';
import {useRouter} from "vue-router";

// eslint-disable-next-line no-unused-vars
const router = useRouter()

import {useForm} from 'vee-validate';
import {object, string} from 'yup';

useForm({
  validationSchema: object({
    login: string().required(),
    password: string().required().min(8),
  }),
})
</script>

<script>
import {mapActions} from "vuex";
import preloader from "@/mixins/preloader";

export default {
  name: "LoginPage",
  mixins: [preloader],
  data() {
    return {
      form: {
        login: null,
        password: null,
        remember: false
      }
    }
  },
  mounted() {
    this.setLoading(false);

    const token = this.$route.query.token;
    if (token) {
      localStorage.setItem('access_token', token);
      window.location.href = '/dashboard';
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/login',
      getCurrency: "getCurrency"
    }),
    login() {
      this.setLoading(true);
      this.axios.get(`${process.env.VUE_APP_LIVE_URL}/sanctum/csrf-cookie`).then(() => {
        this.axios.post('login', {
          login: this.form.login,
          password: this.form.password,
          remember: this.form.remember
        }).then((res) => {
          const token = res.data.data.token
          localStorage.setItem('access_token', token)
          this.signIn()
          this.toast.info(res.data.message)
          this.form.login = null
          this.form.password = null
          this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          this.getCurrency()
          this.$router.push('/dashboard')
        }).catch((error) => {
          this.toast.error(error.response.data.message)
          this.setLoading(false);
        })
      })
    },
    twitterLogin() {
      window.location.href = 'https://api.giftxyz.com/twitterAuth'
    }
  },
}

</script>