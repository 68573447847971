import { createApp } from "vue";
import Vue3Toasity, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VueObserveVisibility from "vue3-observe-visibility";
import MainLayout from "./router/layouts/MainLayout";
import router from "./router/routes";
import Echo from 'laravel-echo';
import './router/layouts/main.css'
import 'aos/dist/aos.css'
import store from "@/store/store";
import vClickOutside from "click-outside-vue3"

import Axios from "@/packages/axios";
import helper from "@/mixins/helper";

const app = createApp(MainLayout, {
    mixins: [helper]
})

app
    .use(router)
    .use(store)
    .use(Vue3Lottie)
    .use(vClickOutside)
    .use(
        Vue3Toasity,
        {
            autoClose: 2000,
            icon: false,
            multiple: true,
            theme: 'colored',
        },
    )
    .use(VueObserveVisibility)
    .mount('#app')

app.config.globalProperties.axios = Axios
app.config.globalProperties.moment = require('moment')
app.config.globalProperties.toast = toast

window.Pusher = require('pusher-js');


window.Echo = new Echo({
    authEndpoint: `${process.env.VUE_APP_LIVE_URL_API}broadcasting/auth`,
    broadcaster: 'pusher',
    key: process.env.VUE_APP_ABLY_PUBLIC_KEY,
    wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
    authHost: process.env.VUE_APP_LIVE_URL,
    wsPort: 80,
    wssPort: 443,
    forceTLS: true, // (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypt: true,
    disableStats: true,
    auth: {
        headers: {
            'authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    },
});