<template>
  <base-modal
    class="chooseGiftType"
    @close="[dialog = false, $emit('close')]"
    :padding="'14px 13px 17px 13px'"
  >
    <template v-slot:body>
      <div
        class="d-flex flex-column align-items-center modal-body-main choose-create-popup"
      >
        <h3>Choose Gift Type</h3>

        <div
          class="chooseGiftType__gifts create-grid-main d-flex flex-row justify-content-space justify-between align-items-center"
        >
          <div class="create-card text-center">
            <div class="icon">
              <img src="@assets/images/buy/coffee.svg" alt="Icon1" />
            </div>
            <h4>Coffee</h4>
          </div>
          <div class="create-card text-center">
            <div class="icon">
              <img src="@assets/images/buy/cloth.svg" alt="Icon2" />
            </div>
            <h4>Clothes</h4>
          </div>
          <div class="create-card text-center">
            <div class="icon">
              <img src="@assets/images/buy/hair.svg" alt="Icon3" />
            </div>
            <h4>Hair</h4>
          </div>
        </div>

        <BuyInput :price="true" />
        <span class="message">Message (optional)</span>
        <textarea></textarea>
        <button class="btn primary py-2">Buy</button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../UI/BaseModalBuy";
import BuyInput from "@/router/UI/buy/BuyInput.vue";

export default {
  name: "ChooseToCreate",
  components: {
    "base-modal": BaseModal,
    BuyInput,
  },
  data() {
    return {
      dialog: !!this.value,
      openModal: false,
    };
  },
  props: {
    value: {
      required: false,
    },
    receiver: {
      required: false,
    },
  },
  watch: {
    value() {
      this.dialog = !!this.value;
      if (this.dialog === false) {
        this.requestAmount = null;
      }
    },
    dialog(n) {
      if (n) {
        document.querySelector("html").classList.add("overflow-hidden");
      } else {
        document.querySelector("html").classList.remove("overflow-hidden");
      }
      this.$emit("input", this.dialog);
    },
  },
  methods: {
    cancel(boolean) {
      this.dialog = false;
      this.$emit("result", boolean);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0b254e;
}

h3 {
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0b254e;
}

.buttons {
  display: flex;
  padding: 40px;
  gap: 10px;
  width: 100%;
  button {
    width: 100%;
  }
}

.btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 100%;
  max-width: unset;
}

.message {
  width: 100%;
  text-align: left;
  font-size: 13px;
  line-height: 24px;
  color: #0b254e;
}

textarea {
  background: #eeeeee;
  border-radius: 12px;
  height: 76px;
  width: 100%;
  margin-bottom: 13px;
  padding: 8px;
}

.chooseGiftType__gifts{
  justify-content: space-between;
}

h4{
  font-weight: 700;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

text-align: center;

/* BLACK */

color: #0B254E;

}
</style>
