<template>
  <div class="restricted_gifts_container">
    <div class="th-container">
      <h2>Restricted Gifts/Wishes</h2>

      <h4>Items that are neither gifts nor tips:</h4>
      <ul>
        <li>Selling any services or goods on your wishlist is prohibited.</li>
        <li>Gift item names or descriptions that promise a good or service in exchange for the gift are
          prohibited.
        </li>
        <li>Wishlist item names that don't make sense in a sentence about gifting or tipping are prohibited.
        </li>
        <li>Items should fit in a sentence about gifting or tipping such as: "Kenny bought a ____ for Jordan."
        </li>
      </ul>

      <h4>Good Item Names:</h4>
      <ul>
        <li>Rent: Kenny paid for Jordan’s rent. ✅</li>
        <li>Funds for self care: Kenny funded Jordan’s self care. ✅</li>
        <li>Nike: Kenny bought Nikes for Jordan. ✅</li>
        <li>Treat me to a spa day because I deserve it:</li>
        <li>Kenny treated Jordan to a spa day because she deserved it. ✅</li>
      </ul>

      <h4>Bad Item Names:</h4>
      <ul>
        <li>Worship: Jordan paid for Kenny's worship. ❌ unclear how this is a gift</li>
        <li>Debt Contract: Jordan bought a debt contract for Kenny. ❌ unclear how this is a gift</li>
        <li>💦💦💦: Jordan funded 💦💦💦 it for Kenny. ❌ unclear how this is a gift</li>
      </ul>

      <h4>Demeaning words</h4>
      <p>Demeaning words like “loser” are not allowed in gift titles</p>

      <h4>Adult words</h4>
      <p>Adult words like "cuck" are not allowed in gift titles. Please keep wishlist names vanilla.</p>

      <h4>References to outside payment methods</h4>
      <p>Do not include outside payment methods in wishlist items such as Amazon wishlist link, bitcoin QR code,
        or cashapp handle.</p>

      <h4>Nudity or Implied Nudity:</h4>
      <ul>
        <li>Gifts, or profile images, that have nudity or implied nudity in the image are prohibited.</li>
        <li>Implied nudity means a naked person without nipples or genitalia exposed in the photograph. For
          example, a naked person whose genitals are covered by pixels or emojis is not allowed.
        </li>
        <li>Wearing lingerie, a robe, or a towel is allowed.</li>
        <li>No bodily fluids should be visible.</li>
      </ul>

      <h4>Nudity or Implied Nudity:</h4>
      <ul>
        <li>Weed/drug related funds<br>
          Can I list "herbs"? Yes.
        </li>
        <li>Weapons<br>
          Can I list "Cosplay funds" or "Funds for military antiques collection"? Yes.
        </li>
        <li>Sex toys<br>
          Can I list "body massaging tools"? Yes.
        </li>
        <li>Items with the word “tax”, “fee”, "session", "deposit", "game", "unblock"<br>
          Why not? These imply a service might be exchanged. In certain contexts
          these words are ok, like "video games".
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import preloader from "@/mixins/preloader";
export default {
  name: "RestrictedGifts",
  mixins: [preloader],
  mounted() {
    this.setLoading(false);
  },
}
</script>

<style lang="scss" scoped>
.restricted_gifts_container {
  padding: 76px 0;

  h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    color: #0B254E;
    text-align: center;
    margin: 53px auto 31px;
  }

  h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #0B254E;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0B254E;
    opacity: 0.5;
  }

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0B254E;
    opacity: 0.5;
    margin-bottom: 15px;
  }
}
</style>