<template>
  <div class="register-container">
    <RegLeftBar/>
    <div class="form-section">
      <div class="register-form">
        <p class="small-title">Already a member?
          <router-link :to="{name: 'login'}" class="link">Log in now.</router-link>
        </p>
        <button class="primary twitter" @click="twitterLogin">
          <TwitterIcon/>
          <span> Sign in With Twitter</span>
        </button>
        <h1 class="title">Register to Giftxyz</h1>
        <div class="form-input">
          <base-input
              v-model="form.email"
              :input-type="'text'"
              :input-name="'email'"
              :label-name="'Email Address'"
              :label-class="'mb-12'"
              :blue="true"
          />
        </div>
        <div class="form-input">
          <base-input
              v-model="form.password"
              :input-type="'password'"
              :input-name="'password'"
              :label-name="'Password'"
              :label-class="'mb-12'"
              :blue="true"
          />
        </div>
        <p class="buttons-label">Account type</p>
        <div class="buttons-main role">
          <base-button
              v-for="button in roleButtons"
              :key="button.id"
              class="role-button"
              :class="form.role_id === button.id ? 'primary' : 'secondary'"
              @click.prevent="form.role_id = button.id"
          >
            {{ button.name }}
          </base-button>
        </div>

        <p class="buttons-label">Display Currency</p>
        <div class="buttons-main role">
          <base-select
              :options="currencies"
              :visible-key="'code'"
              :selected="currency"
              class="select"
              @selected="selectCurrency"
          />
        </div>
        <base-input
            v-model="form.role_id"
            :input-type="'hidden'"
            :input-name="'role'"
        />

        <!--        <p class="buttons-label">Gender</p>-->
        <!--        <div class="buttons-main gender">-->
        <!--          <base-button-->
        <!--              v-for="button in genderButtons"-->
        <!--              :key="button.id"-->
        <!--              class="role-button"-->
        <!--              :class="form.gender_id === button.id ? 'primary' : 'secondary'"-->
        <!--              @click.prevent="form.gender_id = button.id"-->
        <!--          >-->
        <!--            {{ button.name }}-->
        <!--          </base-button>-->
        <!--        </div>-->

        <!--        <base-input-->
        <!--            v-model="form.gender_id"-->
        <!--            :input-type="'hidden'"-->
        <!--            :input-name="'gender'"-->
        <!--        />-->

        <div class="form-input">
          <base-input
              v-model="form.username"
              :input-type="'text'"
              :input-name="'username'"
              :label-name="'Username (handle)'"
              :label-class="'mb-12'"
              :blue="true"
          />
        </div>
        <base-button
            class="primary register-button"
            @click.prevent="register"
        >
          Register
        </base-button>
        <p class="register-form-text">By clicking Register, you agree to our <a href="/files/terms_of_service.pdf"
                                                                                target="_blank"
                                                                                class="link">Terms and Conditions</a>,
          <a href="/files/cookie_policy.pdf" target="_blank" class="link">Cookie Policy,</a> and <a
              href="/files/privacy-policy.pdf" target="_blank" class="link">Privacy Policy</a>.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import './RegisterPage.scss'
import RegLeftBar from '@/router/components/RegLeftBar/RegLeftBar.vue';
import BaseInput from "../../UI/BaseInput";
import BaseButton from "../../UI/BaseButton";
import {useForm} from 'vee-validate';
import {object, string} from 'yup';
import {useRouter} from "vue-router";
import BaseSelect from "@/router/UI/BaseSelect.vue";
import TwitterIcon from '../../../assets/svg/TwitterIcon.vue';

// eslint-disable-next-line no-unused-vars
const router = useRouter()

useForm({
  validationSchema: object({
    email: string().email().required(),
    login: string().required(),
    password: string().required().min(8),
    role: string().required(),
    gender: string().required(),
    username: string().max(16).required(),
  }),
})
</script>

<script>
import currencyHelper from "@/mixins/currencyHelper";
import preloader from "@/mixins/preloader";

export default {
  name: "RegisterPage",
  mixins: [currencyHelper, preloader],
  data() {
    return {
      form: {
        email: null,
        password: null,
        c_password: null,
        gender_id: null,
        role_id: null,
        username: null,
        name: null,
        status: 0
      },
      roleButtons: [],
      genderButtons: [],
      validated: false,
      currencies: [],
    }
  },
  mounted() {
    this.form.location_id = this.currencyId
    this.form.selected_currency_id = this.currencyId
    this.axios.get('currencies')
        .then((res) => {
          this.currencies = res.data.currencies;
        })
    this.axios.get('get-register-data')
        .then((res) => {
          this.roleButtons = res.data.roles;
          this.genderButtons = res.data.genders;
        }).finally(() => {
      this.setLoading(false);
    })
  },
  methods: {
    selectCurrency(currency) {
      this.form.location_id = currency.id
      this.form.selected_currency_id = currency.id
    },
    register() {
      this.setLoading(true);
      this.form.c_password = this.form.password
      this.form.name = this.form.username
      this.axios.get(`${process.env.VUE_APP_LIVE_URL}/sanctum/csrf-cookie`).then(() => {
        this.axios.post('register', this.form).then(() => {
          this.$router.push({name: 'email-verify'})
        }).catch((error) => {
          this.toast.error(error.response?.data.message)
        }).finally(() => {
          this.setLoading(false);
        })
      })
    },
    twitterLogin() {
      window.location.href = 'https://api.giftxyz.com/twitterAuth'
    }
  },
}

</script>