<template>
    <div class="small_input">
        <input :type="type" :id="id" :name="id" :placeholder="placeholder" @input="$emit('changeText', event.target.value)">
    </div>
</template>

<script>
export default {
    name: "SmallInput",
    props: {
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        id: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>
.small_input {
    width: 100%;

    input {
        width: 100%;
        padding-left: 15px;
        border: 1px solid rgba(11, 37, 78, 0.5);
        border-radius: 12px;
        font-weight: 400;
        font-size: 13px;
        line-height: 32px;
        color: #0B254E;
    }
}
</style>
