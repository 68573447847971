<template>
  <div class="nothing_to_see">
    <p>Nothing to see here</p>
    <img src="@/assets/images/nothing_to_see.svg" alt="Nothing to see">
  </div>
</template>

<script>
export default {
    name: "NothingToSee",
}
</script>

<style lang="scss" scoped>
.nothing_to_see {
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  p {
    font-weight: 600;
    font-size: 31.185px;
    line-height: 44px;
    color: #E6E6E6;
    margin: 20px auto 70px;

    @media (max-width: 768px) {
      font-size: 20.25px;
      line-height: 28px;
      margin-bottom: 45px;
    }
  }
}
</style>