<template>
    <ul class="reward_list">
        <li v-for="item in items" :key="item">
            <RewardListIcon/>
            {{ item }}
        </li>
    </ul>
</template>

<script>
import RewardListIcon from "@/router/components/icons/RewardListIcon.vue";

export default {
    name: "RewardList",
    components: {RewardListIcon},
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.reward_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  li {
    font-weight: 700;
    font-size: 20.9088px;
    line-height: 31px;
    color: #0B254E;
  }
}
</style>