<template>
  <base-modal
      v-if="dialog"
      @close="dialog = false"
      :width="'480px'"
      :height="'664px'"
  >
    <template v-slot:body>
      <div class="d-flex flex-column align-items-center gap-12 modal-body-main">
        <div class="modal-body-image">
          <img v-if="user.avatar" :src="user.avatar" alt="#">
          <img v-else src="@assets/images/user-default.png" alt="#">
        </div>

        <div class="modal-body-small-title">
          <span class="line"></span>
          <p>Pay with card</p>
          <span class="line"></span>
        </div>

        <div class="send-tip-card p-0-26 d-flex flex-column gap-12">
          <div class="input-box">
            <base-input
                v-model="card.holder"
                :input-class="'w-100'"
                :input-type="'text'"
                :input-name="'holder'"
                :label-name="'Card information'"
                :placeholder="'e.g. John Doe'"
                v-validate.persist="'required|min:3|max:100'"
            />
            <div class="tooltip" v-if="errors.first('holder')">
              <span class="tooltip-text">{{ errors.first('holder') }}</span>
            </div>
          </div>

          <div class="input-box">
            <base-input
                v-model="card.number"
                :input-class="'w-100'"
                :input-type="'text'"
                :input-name="'number'"
                :placeholder="'e.g. 1234 5678 8789 9345'"
                v-validate.persist="{required: true, max: 5000}"
            />

            <div class="tooltip" v-if="errors.first('number')">
              <span class="tooltip-text">{{ errors.first('number') }}</span>
            </div>
          </div>

          <div class="send-tip-card-two d-flex gap-12">
            <div class="input-box">
              <base-input
                  v-model="card.expirationDate"
                  :input-type="'text'"
                  :input-class="'w-100'"
                  :input-name="'expiration-date'"
                  :placeholder="'01/25'"
                  v-validate.persist="'required|date_format:mm/yy|date_between:'+('0' + (new Date().getMonth()+1)).slice(-2)+'/'+new Date().getFullYear().toString().slice(-2)+','+('0' + (new Date().getMonth()+1)).slice(-2)+'/40'"
              />
              <div class="tooltip" v-if="errors.first('expiration-date')">
                <span class="tooltip-text">{{ errors.first('expiration-date') }}</span>
              </div>
            </div>

            <div class="input-box">
              <base-input
                  v-model="card.cvv"
                  :input-type="'text'"
                  :input-class="'w-100'"
                  :input-name="'cvv'"
                  :placeholder="'123'"
                  v-validate.persist="'required|numeric'"
              />
              <div class="tooltip" v-if="errors.first('cvv')">
                <span class="tooltip-text">{{ errors.first('cvv') }}</span>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-body-title d-flex flex-column align-items-center">
          <p>Price:
            <span class="tip-blue" v-if="buyObj.amount">
               {{ formatAmount(conversationAmount(buyObj, buyObj.amount), currency.code) }}
            </span>
          </p>
          <p>10% Fee: <span class="tip-blue" v-if="buyObj.fee_amount">
             {{ formatAmount(conversationAmount(buyObj, buyObj.fee_amount), currency.code) }}</span></p>
        </div>

        <div class="send-tip-pay-button">
          <base-button
              class="dark pay-tip"
              :class="{'loader': payLoading}"
              @click="payProduct"
              :loading="payLoading"
          >
            Pay ({{ formatAmount(conversationAmount(buyObj, buyObj.total), currency.code) }})
          </base-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>

import BaseButton from "../UI/BaseButton";
import BaseInput from "../UI/BaseInput";
import BaseModal from "../UI/BaseModal";
import {mapGetters} from "vuex";
import currencyHelper from "../../mixins/currencyHelper";

export default {
  name: "PayWithCard",
  components: {
    'base-modal': BaseModal,
    'base-button': BaseButton,
    'base-input': BaseInput,
  },
  data() {
    return {
      dialog: !!this.value,
      policyChecked: false,
      payLoading: false,
      card: {
        holder: null,
        number: null,
        expirationDate: null,
        cvv: null
      }
    }
  },
  props: {
    value: {
      required: false
    },
    buyObj: {
      required: false
    }
  },
  watch: {
    value() {
      this.dialog = !!this.value;
    },
    dialog(n) {
      if(n){
        document.querySelector('html').classList.add('overflow-hidden')
      }else {
        document.querySelector('html').classList.remove('overflow-hidden')
      }
      this.$emit("input", this.dialog);
    },
  },
  computed: {
    ...mapGetters({user: 'auth/user', currency: "currency"}),
  },
  mixins: [currencyHelper],
  methods: {
    payProduct() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          let params = {
            order_id: this.buyObj.id,
            holder: this.card.holder,
            number: this.card.number,
            expiration_date: this.card.expirationDate,
            cvv: this.card.cvv,
          };
          this.payLoading = true;
          this.axios.post('order/sent', params)
              .then((res) => {
                this.card.holder = null;
                this.card.number = null;
                this.card.expirationDate = null;
                this.card.cvv = null;
                this.$validator.reset();
                this.toast.info(res.data.message);
                this.dialog = false;

              }).catch((res) => {
            this.toast.error(res.response.data.message);
            this.dialog = false;
          }).finally(() => {
            this.payLoading = false;
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>