<template>
    <div class="membershipCreate">
        <BaseModalBuy @close="$emit('close')" padding="21px 12px">
            <template v-slot:body>
                <form>
                    <h3>Choose Cycle Length</h3>
                    <div class="cycle">
                        <span v-for="item in types" :key="item" class="cycle__item secondary"
                              :class="{active: type === item}" @click="type = item">{{ item }}</span>
                    </div>
                    <InputLabel label="Price" v-model="price" :price="true"/>
                    <InputLabel label="Name" v-model="name"/>
                    <RewardInput v-model="reward" label="Reward"/>
                    <button type="button" class="btn primary" @click="createMembership">Create</button>
                </form>
            </template>
        </BaseModalBuy>
    </div>
</template>

<script>
import BaseModalBuy from "@/router/UI/BaseModalBuy.vue";
import RewardInput from "./RewardInput.vue";
import {mapActions} from "vuex";
import InputLabel from "@/router/components/form/InputLabel.vue";

export default {
    name: "MembershipCreate",
    components: {
        InputLabel,
        BaseModalBuy,
        RewardInput
    },
    data() {
        return {
            types: ['daily', 'weekly', 'monthly'],
            type: 'daily',
            name: '',
            price: '',
            reward: '',
        }
    },
    methods: {
        ...mapActions({
            updateUser: "auth/login",
        }),
        createMembership() {
            this.axios.post('memberships/create',
                {
                    type: this.type,
                    name: this.name,
                    price: this.price,
                    reward: this.reward,
                },
            ).then(() => {
                this.$emit('close');
                this.$toastr.s('Membership successfully create')
                this.updateUser()
            }).catch((res) => {
                this.toast.error(res.response.data.message);
            }).finally(() => {
                this.dialog = false;
            })
        }
    },
};
</script>

<style lang="scss" scoped>
form {
  width: 100%;
}

h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 17px;
  color: #0b254e;
  margin-top: -4px;
}

.cycle {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.cycle__item {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00aff0;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: block;
  padding: 12px 24px;
  border: 1px solid #00aff0;
  border-radius: 8px;
  margin: 0 10px;
  cursor: pointer;
  transition: all .3s;

  &.active {
    background: #00aff0;
    color: #FFFFFF;
  }
}

.btn {
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
}
</style>
