<template>
    <div class="search_input">
        <label>
            <SearchIcon/>
            <input type="text" placeholder="Search..." @input="$emit('update:modelValue', $event.target.value)">
        </label>
    </div>
</template>

<script>
import SearchIcon from "@/router/components/icons/SearchIcon.vue";

export default {
    name: "SearchInput",
    components: {SearchIcon},
    props: {
        modelValue: {
            type: String,
            required: false,
            default: null
        }
    },
}
</script>

<style lang="scss" scoped>
.search_input {
  width: 100%;

  label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    background: #F3F3F3;
    border-radius: 10px;
    padding: 0 15px;

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-weight: 700;
      font-size: 12px;
      line-height: 29px;
      color: #D9E1E4;
      padding-left: 15px;
    }

    input::placeholder {
      color: #D9E1E4;
    }
  }
}
</style>