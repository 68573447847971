import axios from "../../packages/axios";

export default {
 namespaced: true,
 state: {
  authenticated: false,
  user: {},
  onlineUsers: [],
 },
 getters: {
  onlineUsers: (state) => state.onlineUsers,
  authenticated(state) {
   return state.authenticated;
  },
  user(state) {
   return state.user;
  },
 },
 mutations: {
  SET_AUTHENTICATED(state, value) {
   state.authenticated = value;
  },
  SET_USER(state, value) {
   state.user = value;
  },
  SET_ONLINE_USERS(state, value) {
   state.onlineUsers = value;
  },
 },
 actions: {
  login({ commit }) {
   return axios
    .get("user")
    .then((res) => {
     commit("SET_USER", res.data.user);
     commit("SET_AUTHENTICATED", true);
    })
    .catch(() => {
     commit("SET_USER", {});
     commit("SET_AUTHENTICATED", false);
    });
  },
  logout({ commit }) {
   commit("SET_USER", {});
   commit("SET_AUTHENTICATED", false);
  },
  setOnlineUsers({ commit }, data) {
   commit("SET_ONLINE_USERS", data);
  },
 },
};
