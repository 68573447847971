<template>
  <div>
    <MainHeader :class="[{hide: !showHeader},{profile : hideHeader}]" v-if="show" key="header"/>
    <template
        v-if="$route.meta.middleware === 'auth' && $store.getters['auth/user'].id"
    >
      <router-view v-if="show" key="page-view"/>
    </template>
    <template v-else-if="$route.meta.middleware !== 'auth'">
      <router-view v-if="show" key="page-view"/>
    </template>

    <MainFooter :class="[{hide: !showHeader || hideHeader}]" v-if="show" key="footer"/>
    <BuyGiftPurchase key="modals"/>

    <AddToCart
        v-if="(cartItems.length && $route.path !== '/checkout') || (authenticated && user.cart.length && $route.path !== '/checkout')"
        :key="cartItems"/>

    <DefaultPreloader :class="{active: loading}"/>
  </div>
</template>

<script setup>
import MainHeader from "./MainHeader/MainHeader.vue";
import MainFooter from "./MainFooter/MainFooter.vue";
import BuyGiftPurchase from "../Modals/BuyGiftPurchase.vue";
import {onMounted, ref} from "vue";
import AddToCart from "@/router/components/AddToCart.vue";
import DefaultPreloader from "@/router/components/DefaultPreloader.vue";

const show = ref(false);

onMounted(() => {
  show.value = true;
});
</script>
<script>
const showHeader = ref(true);
const hideHeader = ref(false);

import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      cartItems: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      onlineUsers: "auth/onlineUsers",
      authenticated: 'auth/authenticated',
      loading: 'loading',
      cartStatus: 'cart/updateCart'
    }),
  },
  mounted: async function () {
    await this.getCurrency();
    await this.checkCart();

    hideHeader.value = this.$route.name === 'profile' || this.$route.name === 'user-profile' || this.$route.name === 'checkout' || this.$route.name === 'success' || this.$route.name === 'error' || this.$route.name === 'stripe-success'

    if (
        this.$route.path == "/twitter" ||
        this.$route.path == "/login" ||
        this.$route.path == "/register" ||
        this.$route.path == "/forgot-password" ||
        this.$route.path == "/reset-password"
    ) {
      showHeader.value = false;
      document.body.classList.remove("dark");
    } else {
      showHeader.value = true;
      if (localStorage.getItem("access_token")) {
        await this.signIn();
        await this.getDefaultCurrency(this.user?.location_id)
        document.body.classList.add("user-login");
        document.body.classList.add("chat");
        this.isOnline();
      }
    }
  },
  watch: {
    $route: function () {
      hideHeader.value = this.$route.name === 'profile' || this.$route.name === 'user-profile' || this.$route.name === 'checkout' || this.$route.name === 'success' || this.$route.name === 'error' || this.$route.name === 'stripe-success'

      if (
          this.$route.path == "/twitter" ||
          this.$route.path == "/login" ||
          this.$route.path == "/register" ||
          this.$route.path == "/forgot-password" ||
          this.$route.path == "/reset-password"
      ) {
        showHeader.value = false;
        document.body.classList.remove("dark");
      } else {
        showHeader.value = true;
        if (localStorage.getItem("access_token")) {
          this.signIn();
        }
      }
      this.checkCart()
    },
    cartStatus: function () {
      this.checkCart()
    },
  },
  methods: {
    ...mapActions({
      signIn: "auth/login",
      setOnlineUsers: "auth/setOnlineUsers",
      getCurrency: "getCurrency",
      getDefaultCurrency: "getDefaultCurrency",
    }),
    isOnline() {
      if (!this.user) {
        return;
      }
      window.Echo.join(`is_online`)
          .here((users) => {
            this.setOnlineUsers(users);
          })
          .joining((user) => {
            if (!this.onlineUsers.find((i) => i.id === user.id)) {
              this.setOnlineUsers([...this.onlineUsers, user]);
            }
          })
          .leaving((user) => {
            const ind = this.onlineUsers.findIndex((i) => i.id === user.id);

            if (ind > -1) {
              let users = [...this.onlineUsers];
              users.splice(ind, 1);
              this.setOnlineUsers(users);
            }
          })
          .listen(".user-chat-" + this.user.id, () => {
          });
    },
    checkCart() {
      let localCart = JSON.parse(localStorage.getItem("cart") || "[]");
      this.cartItems = localCart || [];
    }
  },
};
</script>

<style>
@import "./main.css";
</style>
