<template>
  <section v-click-outside="hide" class="dropdown-wrapper" :class="isVisible ? 'opened' : 'closed'">
    <div @click="isVisible = !isVisible" class="selected-item">
      <span v-if="selectedItem">{{ visibleKey ? selectedItem[visibleKey] : selectedItem }}</span>
      <span v-else>{{ text }}</span>
      <span v-if="selectIcon" :class="isVisible ? 'opened' : ''" class="icon-arrow-up select-drop-icon"></span>
    </div>
    <div :class="isVisible ? 'opened' : 'closed'" class="dropdown-popover">
<!--      <base-input-->
<!--          class="select-input"-->
<!--          v-if="searchOn"-->
<!--          :input-type="'search'"-->
<!--          v-model="search"-->
<!--      />-->
      <div class="options">
        <ul v-if="options">
          <template v-for="(option, index) in filteredData">
            <li v-if="noShowSelected && option !== selectedItem" @click="selectItem(option)"
                :class="selectedItem[visibleKey] === option[visibleKey] ? 'selected' : ''"
                :key="option"
            >
              {{ visibleKey ? option[visibleKey] : option }}
            </li>
            <li v-else-if="!noShowSelected && option !== selectedItem" @click="selectItem(option)"
                :class="visibleKey ? selectedItem?.[visibleKey] === option[visibleKey] : selectedItem === option ? 'selected' : ''"
                :key="index"
            >
              {{ visibleKey ? option[visibleKey] : option }}
            </li>
          </template>
          <li v-if="filteredData.length === 0">
            No results found
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
// import BaseInput from "../UI/BaseInput"
import vClickOutside from 'click-outside-vue3'

export default {
  name: "BaseSelect",
  components: {
    // 'base-input': BaseInput,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      search: null,
      selectedItem: null,
      isVisible: false
    }
  },
  props: {
    searchOn: {
      required: false
    },
    options: {
      required: true
    },
    visibleKey: {
      required: false
    },
    text: {
      required: false
    },
    selected: {
      required: false
    },
    selectIcon: {
      required: false
    },
    noShowSelected: {
      required: false,
      default: false
    }
  },

  watch: {
    selected(n) {
      if (n) {
        this.selectedItem = n
      }
    }
  },

  computed: {
    filteredData() {
      if (!this.search) {
        return this.options
      }
      const query = this.search.toLowerCase();

      return this.options.filter((option) => {
        return Object.values(option).some(word => String(word).toLowerCase().includes(query))
      })
    }
  },
  methods: {
    selectItem(option) {
      this.selectedItem = option;
      this.isVisible = false;
      this.$emit('selected', option)
    },
    hide() {
      this.isVisible = false;
    }
  },
  mounted() {
    if (this.selected) {
      this.selectedItem = this.selected
    }
  }
};
</script>

<style lang="scss" scoped>

.dropdown-wrapper {
  max-width: 270px;
  position: relative;
  width: 100%;
  height: 54px;
  background-color: #00AFF0;
  border-radius: 7px;
  cursor: pointer;
  z-index: 9;

  .selected-item {
    height: 100%;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;

    .select-drop-icon {
      transform: rotate(180deg);
      transition: all 0.5s ease;

      &.opened {
        transform: rotate(0deg);
      }
    }

    span {
      font-weight: 500;
      font-size: 21.6px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }

  .dropdown-popover {
    position: absolute;
    border: 1px solid #00AFF0;
    top: 46px;
    left: 0;
    right: 0;
    background-color: white;
    max-width: 100%;
    visibility: hidden;
    transition: all 0.3s linear;
    max-height: 0;
    overflow: hidden;

    &.opened {
      visibility: visible;
      max-height: 285px;
    }

    .select-input {
      input {
        font-size: 16px;
        padding-left: 8px;
        height: 40px;
        width: 100%;
        border-radius: 8px;
        background: #F3F3F3;
        font-weight: 400;
        line-height: 24px;
        border: none;
        outline: none;
        color: rgba(158, 173, 193, 1);
        margin-bottom: 20px;
      }
    }


    .options {
      width: 100%;

      ul {
        list-style: none;
        text-align: left;
        padding-left: 0;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;

        li {
          width: 100%;
          text-align: center;
          border-bottom: 1px solid #00AFF0;
          padding: 10px;
          cursor: pointer;
          font-weight: 500;
          font-size: 21.6px;
          line-height: 22px;
          color: #00AFF0;
          transition: all 0.3s ease;

          &.selected {
            background-color: #f1f1f1;
          }

          &:hover {
            background: #00aff0;
            color: white;
            opacity: .8;
          }
        }
      }
    }
  }
}
</style>