<template>
  <div class="create_modal">
    <div class="create_modal_container">
      <div v-if="createItem !== 'success'" class="back" @click="back">
        <BackIcon/>
      </div>
      <router-link :to="{name: 'home'}" class="logo">
        <img src="@/assets/images/patrion.png" alt="" width="134" >
      </router-link>
      <div class="progress_bar">
        <div class="progress"
             :class="[{type: createItem === 'selectType'},{success: createItem === 'success'},{create: createItem === 'wishlist' || createItem === 'membership' }]"></div>
      </div>

      <template v-if="!createItem">
        <div class="heading">Choose to Create</div>

        <div class="buttons">
          <button class="secondary" @click="createItem = 'wishlist'">Wishlist</button>
          <button class="secondary" @click="createItem = 'membership'">Membership</button>
        </div>
      </template>

      <template v-if="createItem === 'selectType'">
        <div class="buttons">
          <button class="primary" @click="createItem = 'wishlist'">Custom</button>
        </div>
        <div class="pre_made_block">
          <div class="or">Or</div>
          <p class="text">Pre-Made <span>*Recommended*</span></p>
          <p class="small_text">You can edit the price & name later</p>
        </div>
      </template>

      <template v-if="createItem === 'wishlist'">
        <div class="heading_block">
          <div v-if="editWish" class="delete" @click="deleteWish(wish.id)">Delete</div>
          <div class="heading">
            {{ editWish ? 'Edit Wish' : 'Create Wish' }}
          </div>
        </div>

        <div class="inputs">
          <InputDashboard type="number" v-model="wish.price" placeholder="Price" :price="true"/>
          <InputDashboard type="text" v-model="wish.name" placeholder="Gift Name"/>
        </div>

        <img v-if="wishPreviewImage" :src="wishPreviewImage" alt="Image" class="wish_image">
        <GiftIcon v-else/>

        <p class="upload">Upload Photo</p>
        <label class="choose">
          Choose Image
          <input @change="previewImage" ref="file_input" type="file" accept="image/*"/>
        </label>

        <button v-if="editWish" class="primary" @click="updateWish">Update</button>
        <button v-else class="primary" @click="createWish">Next</button>

        <p class="agree">By clicking agree, you’ve read our <a href="/restricted-gifts" target="_blank">Restricted
          Gifts</a> list, and understand that breaking this agreement will lead into a
          permanent account ban</p>

        <CheckboxRounded v-model="agree" label="I agree" class="center_checkbox"/>
      </template>

      <template v-if="createItem === 'membership'">
        <div class="heading">Create Membership</div>

        <div class="inputs">
          <InputDashboard type="number" v-model="membership.price" placeholder="Price" :price="true"/>
          <InputDashboard type="text" v-model="membership.name" placeholder="Membership Name"/>
        </div>

        <div class="gray_heading">Choose Cycle Length</div>

        <RadioButtons v-model="membership.type" :options="options"/>

        <div class="gray_heading">Membership Rewards</div>

        <RewardList :items="reward_list" class="list"/>

        <button class="primary" @click="createMembership">Next</button>

      </template>

      <template v-if="createItem === 'success'">
        <div class="success_section">
          <SuccessTextIcon class="success_text"/>
          <LottieAnimation path="animations/success.json" :loop="false"/>
          <button class="primary" @click="$emit('close')">Finish</button>
        </div>
      </template>
    </div>
    <div v-if="createItem === 'selectType'" class="pre_made_gifts">
      <swiper
          class="pre_made_gifts_swiper"
          :modules="modules"
          :slides-per-view="3"
          navigation
          :breakpoints="{
                        '320': {
                            slidesPerView: 1.7,
                            spaceBetween: 38
                        },
                        '576': {
                            slidesPerView: 2.5,
                            spaceBetween: 38
                        },
                        '768': {
                            slidesPerView: 3,
                            spaceBetween: 38
                        },
                        '992': {
                            slidesPerView: 5,
                            spaceBetween: 38
                        },
                        '1300': {
                            slidesPerView: 6,
                            spaceBetween: 38
                        },
                    }">
        <swiper-slide v-for="pre_made_gift in pre_made_gifts" :key="pre_made_gift.name">
          <div class="pre_made_gift">
            <div class="image">
              <img :src="pre_made_gift.image" :alt="pre_made_gift.name">
            </div>
            <div class="bottom">
              <div class="info">
                <div class="name">{{ pre_made_gift.name }}</div>
                <div class="price">{{ pre_made_gift.price }}</div>
              </div>
              <button class="create" @click="createPreMadeGift(pre_made_gift)"></button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import SuccessTextIcon from "@/router/components/icons/SuccessTextIcon.vue";
import InputDashboard from "@/router/components/form/InputDashboard.vue";
import GiftIcon from "@/router/components/icons/GiftIcon.vue";
import CheckboxRounded from "@/router/components/form/CheckboxRounded.vue";
import RadioButtons from "@/router/components/form/RadioButtons.vue";
import RewardList from "@/router/components/form/RewardList.vue";
import {mapActions} from "vuex";
import BackIcon from "@/router/components/icons/BackIcon.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Scrollbar} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import currencyHelper from "@/mixins/currencyHelper";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import preloader from "@/mixins/preloader";

export default {
  name: "CreateModal",
  components: {
    LottieAnimation,
    BackIcon,
    Swiper,
    SwiperSlide,
    RewardList,
    RadioButtons,
    CheckboxRounded,
    GiftIcon,
    InputDashboard,
    SuccessTextIcon
  },
  mixins: [currencyHelper, preloader],
  setup() {
    return {
      modules: [Scrollbar, Navigation],
    }
  },
  props: {
    editWish: {
      required: false,
    },
  },
  data() {
    return {
      createItem: 'selectType',
      wishPreviewImage: null,
      wish: {
        price: null,
        name: null,
        image: null,
        default_image: null,
      },
      agree: false,
      membership: {
        price: null,
        name: null,
        type: 'weekly',
      },
      options: [
        {
          label: 'Weekly',
          value: 'weekly'
        },
        {
          label: 'Monthly',
          value: 'monthly'
        },
        {
          label: 'Yearly',
          value: 'yearly'
        },
      ],
      reward_list: [
        'Support me',
        'Members Access',
      ],
      pre_made_gifts: [
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/coffee.svg',
          price: this.formatAmount(this.preMadePrice(10), this.$store.state.currency.code),
          name: 'Coffee',
          default_image: '/images/gifts/coffee.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/shopping.svg',
          price: this.formatAmount(this.preMadePrice(50), this.$store.state.currency.code),
          name: 'Shopping',
          default_image: '/images/gifts/shopping.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/concert.svg',
          price: this.formatAmount(this.preMadePrice(75), this.$store.state.currency.code),
          name: 'Concert',
          default_image: '/images/gifts/concert.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/vacation.svg',
          price: this.formatAmount(this.preMadePrice(250), this.$store.state.currency.code),
          name: 'Vacation',
          default_image: '/images/gifts/vacation.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/groceries.svg',
          price: this.formatAmount(this.preMadePrice(100), this.$store.state.currency.code),
          name: 'Groceries',
          default_image: '/images/gifts/groceries.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/clothes.svg',
          price: this.formatAmount(this.preMadePrice(50), this.$store.state.currency.code),
          name: 'Clothes',
          default_image: '/images/gifts/clothes.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/haircut.svg',
          price: this.formatAmount(this.preMadePrice(50), this.$store.state.currency.code),
          name: 'Haircut',
          default_image: '/images/gifts/haircut.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/christmas.svg',
          price: this.formatAmount(this.preMadePrice(50), this.$store.state.currency.code),
          name: 'Christmas',
          default_image: '/images/gifts/christmas.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/birthday.svg',
          price: this.formatAmount(this.preMadePrice(50), this.$store.state.currency.code),
          name: 'Birthday',
          default_image: '/images/gifts/birthday.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/breakfast.svg',
          price: this.formatAmount(this.preMadePrice(40), this.$store.state.currency.code),
          name: 'Breakfast',
          default_image: '/images/gifts/breakfast.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/drinks.svg',
          price: this.formatAmount(this.preMadePrice(40), this.$store.state.currency.code),
          name: 'Drinks',
          default_image: '/images/gifts/drinks.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/phone.svg',
          price: this.formatAmount(this.preMadePrice(500), this.$store.state.currency.code),
          name: 'Phone',
          default_image: '/images/gifts/phone.svg'
        },
        {
          image: process.env.VUE_APP_LIVE_URL + '/images/gifts/gym.svg',
          price: this.formatAmount(this.preMadePrice(35), this.$store.state.currency.code),
          name: 'Gym M.S',
          default_image: '/images/gifts/gym.svg'
        },
      ]
    }
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
    }),
    back() {
      if (this.editWish) {
        this.$emit('close')
        return
      }

      if (this.createItem === 'selectType') {
        this.$emit('close')
      }

      if (this.createItem === 'wishlist') {
        this.createItem = 'selectType'
      }
    },
    previewImage() {
      const [file] = this.$refs.file_input.files;
      if (file) {
        this.wish.default_image = null;
        this.wishPreviewImage = URL.createObjectURL(file);
        this.wish.image = file;
      }
    },
    createWish() {
      if(this.convertToUsd(Number(this.wish.price)) < 10){
        this.toast.error('Minimum price is $10');
        return;
      }
      if (!this.agree) {
        this.toast.error('You must agree to the terms and conditions');
        return
      }
      this.setLoading(true)
      this.wish.currency_id = this.currencyId
      this.axios.post('wishlists/create', this.wish, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
      ).then((res) => {
        if (res.data.fail) {
          this.toast.error(res.data.message);
          this.$emit('close')
        } else {
          this.createItem = 'success'
          this.wish = {
            price: null,
            name: null,
            image: null,
            default_image: null,
          }
          this.updateUser()
        }
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      }).finally(() => {
        this.setLoading(false)
      })
    },
    updateWish() {
      if(this.convertToUsd(Number(this.wish.price)) < 10){
        this.toast.error('Minimum price is $10');
        return;
      }
      if (!this.agree) {
        this.toast.error('You must agree to the terms and conditions');
        return
      }
      this.setLoading(true)
      this.axios.post('wishlists/update', this.wish, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
      ).then((res) => {
        if (res.data.fail) {
          this.toast.error(res.data.message);
          this.$emit('close')
        } else {
          this.toast.info('Wish updated successfully')
          this.wish = {
            price: null,
            name: null,
            image: null,
            default_image: null,
          }
          this.updateUser()
        }
        this.$emit('close')
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      }).finally(() => {
        this.setLoading(false)
      })
    },
    deleteWish(id) {
      this.setLoading(true)
      this.axios.post('wishlists/delete', {id}).then(() => {
        this.updateUser()
        this.toast.info('Wish deleted successfully')
        this.$emit('close')
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      }).finally(() => {
        this.setLoading(false)
      })
    },
    createMembership() {
      this.axios.post('memberships/create',
          {
            type: this.membership.type,
            name: this.membership.name,
            price: this.membership.price,
          },
      ).then(() => {
        this.createItem = 'success'
        this.membership = {
          price: null,
          name: null,
          type: 'weekly',
        }
        this.updateUser()
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    },
    createPreMadeGift(gift) {

      gift.price = gift.price.replace(/[^0-9.-]+/g, "")
      if(this.convertToUsd(Number(gift.price)) < 10){
        this.toast.error('Minimum price is $10');
        return;
      }
      this.wish = gift
      this.wishPreviewImage = gift.image
      this.createItem = 'wishlist'
    }
  },
  created() {
    if (this.editWish) {
      this.createItem = 'wishlist'
      this.wish = this.editWish
      if (this.wish.image) {
        this.wishPreviewImage = process.env.VUE_APP_LIVE_URL + this.wish.image
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
  overflow: auto;
  padding: 30px 0;
  @media (max-width: 768px) {
    padding: 30px 30px 60px;
  }

  .create_modal_container {
    margin: 0 auto;
    position: relative;
    max-width: 450px;
    width: 100%;
    text-align: center;
    padding-top: 6px;
    @media (max-width: 768px) {
      padding: 0;
    }

    .back {
      position: absolute;
      top: 0;
      left: -73px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: #252525;
      border-radius: 50%;
      cursor: pointer;

      @media (max-width: 768px) {
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
      }
    }

    .logo {
      margin-bottom: 33px;
      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      img {
        @media (max-width: 768px) {
          width: 92px;
        }
      }
    }

    .progress_bar {
      position: relative;
      width: 100%;
      height: 4px;
      background-color: #cceffc;
      @media (max-width: 768px) {
        margin-bottom: 12px;
      }

      .progress {
        position: absolute;
        left: 0;
        top: 0;
        width: 33%;
        height: 4px;
        background-color: #00aff0;
        transition: width 0.3s ease-in-out;

        &.type {
          width: 33%;
        }

        &.create {
          width: 66%;
        }

        &.success {
          width: 100%;
        }
      }
    }

    .heading_block {
      position: relative;

      .delete {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-weight: 600;
        font-size: 16px;
        line-height: 52px;
        color: #F00000;
        cursor: pointer;
      }
    }

    .heading {
      font-weight: 600;
      font-size: 33px;
      line-height: 54px;
      color: #0B254E;
      margin-top: 17px;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 36px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 22px;
      margin-top: 23px;

      button {
        width: 100%;
      }
    }

    .pre_made_block {
      text-align: center;

      .or {
        font-weight: 700;
        font-size: 24px;
        line-height: 54px;
        color: #0B254E;
        margin-top: 14px;
      }

      .text {
        font-weight: 700;
        font-size: 24px;
        line-height: 54px;
        color: #0B254E;

        span {
          font-size: 15px;
          line-height: 54px;
          color: #00AFF0;
        }
      }

      .small_text {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        color: #0B254E;
        opacity: 0.5;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin: 18px auto;
    }

    .success_section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .success_text {
        margin: 23px auto 0;
        @media (max-width: 768px) {
          width: 293px;
        }
      }

      button {
        width: 100%;
      }
    }

    .wish_image {
      width: 222px;
      height: 222px;
      object-fit: cover;
      border-radius: 10px;
    }

    .upload {
      font-weight: 500;
      font-size: 17.29px;
      line-height: 21px;
      text-align: center;
      color: #0B254E;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    .choose {
      display: inline-block;
      font-weight: 500;
      font-size: 17.29px;
      line-height: 21px;
      text-align: center;
      color: #00AFF0;
      cursor: pointer;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 16px;
      }

      input {
        display: none;
      }
    }

    .primary {
      width: 100%;
      height: 66px;
      font-weight: 600;
      font-size: 26.6px;
      line-height: 32px;
      color: #FCFCFC;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        height: 50px;
      }
    }

    .agree {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #0B254E;
      max-width: 342px;
      margin: 12px auto;

      a {
        color: #00AFF0;
      }
    }

    .center_checkbox {
      display: flex;
      justify-content: center;
    }

    .gray_heading {
      font-weight: 500;
      font-size: 16.9884px;
      line-height: 21px;
      color: rgba(11, 37, 78, 0.5);
      margin: 17px auto;
    }

    .list {
      margin-bottom: 170px;
    }
  }

  .pre_made_gifts {
    padding: 30px 0 30px 64px;
    @media (max-width: 768px) {
      padding: 30px 0;
      margin-right: -30px;
    }

    .pre_made_gifts_swiper {
      padding-right: 60px;
    }

    .pre_made_gift {
      width: 100%;

      .image {
        background: #F7F7F7;
        border-radius: 12px;

        img {
          border-radius: 12px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .name {
            font-weight: 700;
            font-size: 20px;
            line-height: 36px;
            color: #0B254E;
          }

          .price {
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
            color: #00AFF0;
          }
        }

        .create {
          position: relative;
          width: 45px;
          height: 45px;
          background-color: #00AFF0;
          border-radius: 8px;
          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 4px;
            height: 24px;
            background-color: #fff;
            border-radius: 10px;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 4px;
            background-color: #fff;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>

<style>
.pre_made_gifts .swiper-button-prev {
  display: none;
}

.pre_made_gifts .swiper-button-next {
  top: 0;
  height: 100%;
  width: 59px;
  background-color: #fff;
  right: 0;
}

.pre_made_gifts .swiper-button-next:after {
  content: "";
  background-image: url("@/assets/images/create_modal_arrow.svg");
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 768px) {
  .pre_made_gifts .swiper-button-next {
    display: none;
  }
}
</style>