<template>
  <div class="contact-container">
    <div class="contact-container-main">
      <div class="form-section">
        <div class="contact-form">
          <div class="contact-form-title">
            <h2>Contact Us</h2>
          </div>
          <div class="contact-form-input">
            <base-input
                :input-type="'text'"
                :input-name="'name'"
                :placeholder="'Full Name'"
                v-model="form.name"
            />
          </div>
          <div class="contact-form-input">
            <base-input
                :input-type="'text'"
                :input-name="'email'"
                :placeholder="'Email Address'"
                v-model="form.email"
            />
          </div>
          <div class="contact-form-textarea">
            <base-textarea
                :placeholder="'Message'"
                :area-name="'message'"
                v-model="form.message"
            />
          </div>
          <div class="contact-form-button">
            <base-button
                @click="sendMessage"
                class="primary"
            >
              Submit
            </base-button>
          </div>
          <div class="contact-form-text">
            <p>
              Please give us 48-72 Hours to respond
              to your request, thank you.
            </p>
          </div>
        </div>
      </div>
      <div class="image-section">
        <div class="anim">
          <LottieAnimation
              v-if="$store.state.siteMode === 'light'"
              path="animations/json-1.json"
          />
          <LottieAnimation
              v-else
              path="animations/dark-json-1.json"
          />
        </div>
      </div>
    </div>
    <IndexLeaderboard/>
  </div>
</template>

<script>
import BaseInput from "../../UI/BaseInput";
import BaseTextArea from "../../UI/BaseTextArea";
import BaseButton from "../../UI/BaseButton";
import preloader from "@/mixins/preloader";

export default {
  name: "ContactPage",
  mixins: [preloader],
  components: {
    'base-input': BaseInput,
    'base-textarea': BaseTextArea,
    'base-button': BaseButton
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        message: null,
      }
    }
  },
  mounted() {
    this.setLoading(false)
  },
  methods: {
    clearForm() {
      this.form.name = null
      this.form.email = null
      this.form.message = null
    },
    sendMessage() {
      this.axios({
        url: 'contact',
        method: "POST",
        data: this.form,
      }).then((res) => {
        this.toast.info(res.data.message);
        this.clearForm();
      }).catch((error) => {
        this.toast.error(error.response.data.message);
      });
    },
  },
}
</script>

<script setup>
import './ContactPage.scss'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import IndexLeaderboard from '../PageIndex/IndexLeaderboard/IndexLeaderboard.vue';
</script>
