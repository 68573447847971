<template>
  <div class="checkout_product">
    <div class="checkout_product_image">
      <div class="remove" @click="remove">-</div>
      <img :src="image" :alt="item.product ? item.product.name : 'Custom Gift'">
    </div>
    <div class="checkout_product_info">
      <div class="checkout_product_name">{{ item.product ? item.product.name : 'Custom Gift' }}</div>
      <div class="checkout_product_price">{{ formatAmount(item.product ? convertPrice(item.product, parseFloat(item.product.price)): item.amount, currency.code) }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "CheckoutProduct",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mixins: [currencyHelper],
  computed: {
    image() {
      return this.item.product?.image ? process.env.VUE_APP_LIVE_URL + this.item.product.image : require('@/assets/images/wish-default.svg');
    }
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
    }),
    remove() {
      if (this.item.id) {
        this.axios.post('cart/remove', {id: this.item.id})
            .then((res) => {
              this.updateUser();
              this.toast.info(res.data.message);
            }).catch((res) => {
          this.toast.error(res.response.data.message);
        })
      } else {
        let cart = JSON.parse(localStorage.getItem('cart') || '[]');
        if (this.item.surprise_id) {
          cart.splice(cart.findIndex((item) => item.surprise_id === this.item.surprise_id), 1);
        } else {
          cart.splice(cart.findIndex((item) => item.id === this.item.product.id), 1);
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        this.toast.info('Wish removed from cart successfully');
        this.$emit('updateCart');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout_product {
  display: flex;
  gap: 22px;

  .checkout_product_image {
    position: relative;

    .remove {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 25px;
      height: 25px;
      background: #0B254E;
      border-radius: 50%;
      cursor: pointer;
      z-index: 9999;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 2px;
        background: #fff;
        border-radius: 8px;
      }
    }

    img {
      width: 138px;
      height: 129px;
      object-fit: cover;
    }
  }

  .checkout_product_info {
    .checkout_product_name {
      font-weight: 500;
      font-size: 25.41px;
      line-height: 35px;
      color: #0B254E;
      @media (max-width: 768px) {
        font-size: 18.6763px;
        line-height: 26px;
      }
    }

    .checkout_product_price {
      font-weight: 700;
      font-size: 27.1684px;
      line-height: 26px;
      color: #0B254E;
      margin-top: 6px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 19px;
      }
    }
  }
}
</style>