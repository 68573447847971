<template>
  <div class="forget-pass-container">
    <RegLeftBar/>
    <div class="form-section">
      <div class="forget-pass-form">
        <h1 class="title">Forgot password</h1>
        <div class="form-input">
          <base-input
              @input="typeEmail"
              :input-type="'text'"
              :input-name="'email'"
              :label-name="'Email address'"
              :label-class="'mb-12'"
          />
        </div>
        <base-button
            class="primary"
            @click.prevent="sendEmail"
        >
          Continue
        </base-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import './ForgetPassword.scss'
import RegLeftBar from '@/router/components/RegLeftBar/RegLeftBar.vue';
import {useForm} from 'vee-validate';
import {object, string} from 'yup';

useForm({
  validationSchema: object({
    email: string().email().required()
  }),
})
</script>

<script>
import BaseInput from "../../UI/BaseInput";
import BaseButton from "../../UI/BaseButton";
import preloader from "@/mixins/preloader";

export default {
  name: 'ForgotPassword',
  mixins: [preloader],
  components: {
    'base-input': BaseInput,
    'base-button': BaseButton
  },
  data() {
    return {
      email: null
    }
  },
  mounted() {
    this.setLoading(false);
  },
  methods: {
    typeEmail(e) {
      this.email = e.target.value
    },
    sendEmail() {
      this.axios.post('forgot-password', {
        email: this.email
      }).then((res) => {
        this.toast.info(res.data.message)
        this.email = null
        this.$router.push({name: 'email-verify'})
      }).catch((error) => {
        this.toast.error(error.response.data)
      });
    }
  }
}
</script>