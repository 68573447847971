import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            twitterUser: 'auth/user',
            checkTwitterAuth: 'auth/authenticated',
        }),
    },
    methods: {
        ...mapActions({getTwitter: 'auth/login'}),
    },
    created() {
        this.getTwitter().then(() => {
            if (this.checkTwitterAuth && (!this.twitterUser.role_id || !this.twitterUser.location_id || !this.twitterUser.selected_currency_id)) {
                this.$router.push({name: 'twitter'})
            }
        })
    },
}