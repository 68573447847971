<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M19 12H5" stroke="#0B254E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 19L5 12L12 5" stroke="#0B254E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

</template>

<script setup></script>