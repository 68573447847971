<template>
  <div>
    <base-modal
        v-show="dialog"
        @close="dialog = false"
        :width="'480px'"
        :height="'348px'"
    >
      <template v-slot:body>
        <div class="d-flex flex-column align-items-center gap-12 modal-body-main">
          <div class="modal-body-image">
            <img v-if="receiver.avatar" :src="receiver.avatar" alt="#">
            <img v-else src="@assets/images/user-default.png" alt="#">
          </div>
          <div class="modal-body-title">
            <p>
              Choose Payment Method
            </p>
          </div>
          <div class="send-tip-payments-method">
            <base-button
                class="primary"
            >
              <span class="icon-tip"></span> Wallet
            </base-button>
            <base-button
                class="dark"
            >
              <span class="icon-apple"></span> Pay
            </base-button>
            <base-button
                class="gray"
                @click="openModalAction('card')"
            >
              <span class="icon-card"></span>
              Credit Card
            </base-button>
          </div>

        </div>
      </template>
    </base-modal>

    <pay-with-card
        v-if="openModal === 'card'"
        v-model="openModal"
        :receiver="receiver"
        :item="item"
        @payed="$emit('rating', $event)"
    />
  </div>

</template>

<script>

import BaseButton from "../UI/BaseButton";
import BaseModal from "../UI/BaseModal";
import PayWithCard from "../Modals/PayWithCard";

export default {
  name: "ChoosePaymentMethodModal",
  components: {
    'base-modal': BaseModal,
    'base-button': BaseButton,
    'pay-with-card': PayWithCard
  },
  data() {
    return {
      dialog: !!this.value,
      openModal: false
    }
  },
  props: {
    value: {
      required: false
    },
    receiver: {
      required: false
    },
    item: {
      required: false
    }
  },
  watch: {
    value() {
      this.dialog = !!this.value;
    },
    dialog(n) {
      if(n){
        document.querySelector('html').classList.add('overflow-hidden')
      }else {
        document.querySelector('html').classList.remove('overflow-hidden')
      }
      this.$emit("input", this.dialog);
    },
  },
  computed: {
    tip() {
      return this.item?.object || {};
    }
  },
  methods: {
    openModalAction(type) {
      // this.dialog = false;
      this.openModal = type;
    },
  }
}
</script>