<template>
    <base-modal
            v-if="dialog"
            @close="close"
            :width="'480px'"
            :height="'264px'"
    >
        <template v-slot:body>
            <div @mouseover="rate($event, 0)" class="d-flex flex-column align-items-center modal-body-main">
                <div class="modal-body-image">
                    <template v-if="membership">
                        <img class="avatar" :src="membership.creator.avatar ? membership.creator.avatar : '@assets/images/user-default.png'" alt="Avatar"/>
                    </template>
                    <template v-else>
                        <img v-if="receiver?.avatar" :src="receiver?.avatar" alt="Avatar">
                        <img v-if="user?.avatar" :src="user?.avatar" alt="Avatar">
                        <img v-else src="@assets/images/user-default.png" alt="Avatar">
                    </template>
                </div>
                <div class="modal-body-title">
                    <p v-if="gift">
                        Custom Gift Sent!
                    </p>
                    <p v-else>
                        Tip Sent!
                    </p>
                </div>

                <div class="modal-body-desc">
                    <p v-if="!changeRate">
                        What would you rate this user?
                    </p>
                    <p v-else>
                        You’ve already rated this user {{ stars.count }} stars
                    </p>
                </div>

                <div class="send-modal-user-rate">
            <span :class="{'tip-blue-before': stars.count >= 1}" @mouseover="rate($event,1)"
                  @click="rate($event, 1, stars.fixed = true)" class="icon-star mr-4"></span>
                    <span :class="{'tip-blue-before': stars.count >= 2}" @mouseover="rate($event,2)"
                          @click="rate($event, 2, stars.fixed = true)" class="icon-star mr-4"></span>
                    <span :class="{'tip-blue-before': stars.count >= 3}" @mouseover="rate($event,3)"
                          @click="rate($event, 3, stars.fixed = true)" class="icon-star mr-4"></span>
                    <span :class="{'tip-blue-before': stars.count >= 4}" @mouseover="rate($event,4)"
                          @click="rate($event, 4, stars.fixed = true)" class="icon-star mr-4"></span>
                    <span :class="{'tip-blue-before': stars.count >= 5}" @mouseover="rate($event,5)"
                          @click="rate($event, 5, stars.fixed = true)" class="icon-star"></span>
                </div>

                <div class="modal-body-tip">
                    <p @click="sendTipStepFive" v-if="stars.count > 0 && stars.fixed === true && changeRate === false">
                        Done
                    </p>
                    <p @click="changeTipStepFive"
                       v-else-if="stars.count > 0 && stars.fixed === true  && changeRate === true">
                        Change Rating
                    </p>
                    <p @click="changedTipStepFive"
                       v-else-if="stars.count > 0 && stars.fixed === true  && changeRate === 'changed'">
                        Done
                    </p>
                </div>

            </div>
        </template>
    </base-modal>
</template>


<script>

import BaseModal from "../UI/BaseModal";
import {mapGetters} from "vuex";
import currencyHelper from "../../mixins/currencyHelper";

export default {
    name: "RatingModal",
    components: {
        'base-modal': BaseModal,
    },
    data() {
        return {
            dialog: !!this.value,
            policyChecked: false,
            payLoading: false,
            rate_id: null,

            changeRate: false,
            card: {
                holder: null,
                number: null,
                expirationDate: null,
                cvv: null
            },
            stars: {
                count: null,
                fixed: false
            },
        }
    },
    props: {
        value: {
            required: false
        },
        user: {
            type: Object,
            required: false
        },
        receiver: {
            required: false
        },
        tip: {
            required: false
        },
        membership: {
            required: false
        },
        gift: {
            required: false
        },
    },
    watch: {
        value() {
            this.dialog = !!this.value;
        },
        dialog(n) {
            if (n) {
                document.querySelector('html').classList.add('overflow-hidden')
            } else {
                document.querySelector('html').classList.remove('overflow-hidden')
            }
            this.$emit("input", this.dialog);
        },
    },
    computed: {
        ...mapGetters({user: 'auth/user', currency: "currency"})
    },
    mixins: [currencyHelper],
    methods: {
        sendTipStepFive() {
            this.changeRate = true
        },
        changeTipStepFive() {
            this.stars.fixed = false
            this.changeRate = 'changed'
        },
        close() {
            this.$emit('close');
            // this.axios.post('rate/create',
            //     {
            //       user_id: this.receiver.user_id || this.receiver.id,
            //       rate: this.stars.count || 0,
            //       type: 'tip',
            //       type_id: this.tip.id,
            //       chat_id: this.receiver.id
            //     }
            // ).then((res) => {
            //   this.rate_id = res.data.rate_id
            //   this.stars.count = null;
            //   this.stars.fixed = false;
            //   this.$emit('ratingEnd', true);
            // }).finally(() => {
            //   this.dialog = false;
            // })
        },
        rate(e, number) {
            if (number !== 0 && this.stars.fixed === true && !e.target.classList.contains('icon-star')) {
                this.stars.count = number
                e.preventDefault();
            } else {
                if (number === 0 && !e.target.classList.contains('send-modal-user-rate') && !e.target.classList.contains('icon-star') && this.stars.fixed === false) {
                    this.stars.count = 0
                } else if (number !== 0 && this.stars.fixed === false) {
                    this.stars.count = number
                }
            }
        },
        changedTipStepFive() {
            this.axios.post('rate/create',
                {
                    rate_id: this.rate_id || this.tip.id,
                    rate: this.stars.count,
                    user_id: this.receiver.user_id,
                    chat_id: this.receiver.id,
                    type_id: this.tip.id,
                    type: 'tip',
                }
            ).then(() => {
                this.stars.count = null;
                this.stars.fixed = false
                this.$emit('ratingEnd', true);
            }).finally(() => {
                this.dialog = false;
            })
        }
    }
}
</script>

<style scoped>

</style>