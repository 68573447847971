<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" :style="cssProps">
                    <div class="modal-header">
                        <slot name="header">
                            <div class="modal-close">
                                <img @click="$emit('close')" src="@assets/images/modal-close-icon.png" alt="Close"/>
                            </div>
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "BaseModal",
    props: {
        width: {
            required: false,
        },
        height: {
            required: false,
        },
        padding: {
            required: false,
        },
    },
    computed: {
        cssProps() {
            return {
                "--modal-width": this.width,
                "--modal-height": this.height,
                "--modal-padding": this.padding,
            };
        },
    },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  border-radius: 12px;
  width: var(--modal-width);
  height: var(--modal-height);
  margin: 0px auto;
  padding: var(--modal-padding);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: calc(100vh - 20px);
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 500px) {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 0;

  .modal-close {
    position: relative;
    z-index: 99;
    cursor: pointer;
      img {
          width: 20px;
          height: 20px;
      }
  }
}

.modal-body {
  transform: translateY(-16px);
  display: flex;
  justify-content: center;

  .modal-body-main {
    width: 100%;
  }

  .modal-body-image {
    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .modal-body-title {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #0b254e;
      margin: 10px 0;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .send-tip-policy-text {
    padding: 39px 39px 0;
    gap: 14px;

    .policy-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #0b254e;
    }

    .checkbox {
      input {
        margin-top: 12px;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          top: 0;
          left: 0;
          background: #f3f3f3;
        }

        &:checked {
          &:before {
            background: #00aeef;
          }

          &:after {
            content: "";
            display: block;
            width: 7px;
            height: 14px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            top: 4px;
            left: 12px;
          }
        }
      }
    }
  }

  .send-tip-policy-long-text {
    padding: 0 39px 0 44px;

    ul {
      list-style-type: disc;
      gap: 15px;

      li {
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #0b254e;
        }
      }
    }
  }

  .modal-body-small-title {
    display: flex;
    align-items: center;
    gap: 13px;

    .line {
      border: 1px solid rgba(11, 37, 78, 0.5);
      width: 160px;
      height: 0;
      @media (max-width: 500px) {
        border: none;
        width: 0;
      }
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #0b254e;
      opacity: 0.5;
    }
  }

  .send-tip-card {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #0b254e;
    }

    input {
      &.w-100 {
        width: 100%;
      }

      height: 48px;
      background: #f3f3f3;
      border-radius: 8px;
      border: none;
      padding: 24px;
    }

    @media (max-width: 500px) {
      width: calc(100% - 40px);
    }
  }

  .send-tip-payments-method {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 0 64px;

    button {
      font-size: 20px;
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }

  .modal-body-desc {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0b254e;
    }
  }

  .modal-body-input {
    width: 100%;
    padding: 10px 64px 0;

    input {
      height: 48px;
      width: 100%;
      border-radius: 8px;
      font-style: normal;
      background: #f3f3f3;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      border: none;
      outline: none;
      color: rgba(158, 173, 193, 1);
      padding-left: 20px;
    }

    .input-end-icon {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-body-tip {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #00aff0;
    cursor: pointer;
  }
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 500px) {
  .send-tip-card-two {
    input {
      width: 100%;
    }
  }
}
</style>
