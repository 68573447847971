<template>
    <div class="giftPurchase">
        <BaseModalBuy class="avatar-ext" @close="$emit('close')" :padding="'20px'">
            <template v-slot:avatar>
                <img class="avatar" :src="user.avatar ? user.avatar : '@/assets/images/buy/avatar.png'" alt="Avatar"/>
            </template>
            <template v-slot:body>
                <div class="giftPurchase_wrapper">
                    <span class="username">@{{ user.username }}</span>
                    <div class="charge_text">
                        You’ll be charged
                        <span>{{ currencyCode + parseFloat(gift.price) }}</span>
                    </div>
                    <div class="gift_details">
                        <span>Custom Gift</span>
                        <span>{{ currencyCode + parseFloat(gift.price) }}</span>
                    </div>
                    <div class="suggested_use" v-if="gift.description">{{ gift.description }}</div>
                    <SurpriseGiftIcon/>
                    <div class="gift_form">
                        <div class="gift_form_top">
                            <SmallInput placeholder="From" id="gift_from" v-model="giftForm.from"/>
                            <SmallInput placeholder="Email" id="gift_email" :required="true" v-model="giftForm.email"/>
                        </div>
                        <SmallInput placeholder="Message" id="gift_message" v-model="giftForm.message"/>
                    </div>
                    <Checkbox :modelValue="hide" @update:modelValue="hide = $event" :label="hideNameLabel" :description="hideNameDescription" id="hideNameCheckbox" :bold="true"/>
                    <Checkbox :modelValue="agree" @update:modelValue="agree = $event" :smallLabel="agreeLabel" :list="agreeList" id="agreeCheckbox"/>
                    <BuyForm @purchase="$emit('gift-purchased')"/>
                </div>
            </template>
        </BaseModalBuy>
    </div>
</template>

<script>
import BaseModalBuy from "@/router/UI/BaseModalBuy.vue";
import BuyForm from "@/router/Modals/BuyGift/BuyForm.vue";
import currencyHelper from "@/mixins/currencyHelper";
import SurpriseGiftIcon from "@/router/components/icons/SurpriseGiftIcon.vue";
import Checkbox from "@/router/components/form/Checkbox.vue";
import SmallInput from "@/router/components/form/SmallInput.vue";

export default {
    name: "SurpriseGiftPurchase",
    props: {
        user: {
            type: Object,
            required: false,
            default: () => ({
                name: "@Username",
                avatar: require("@/assets/images/buy/avatar.png"),
            }),
        },
        gift: {
            type: Object,
            required: false,
            default: () => ({
                price: 0,
            }),
        },
    },
    components: {
        SmallInput,
        Checkbox,
        SurpriseGiftIcon,
        BaseModalBuy,
        BuyForm,
    },
    mixins: [currencyHelper],
    data() {
        return {
            giftForm: {
                from: "",
                email: "",
                message: "",
            },
            hide: false,
            agree: false,
            hideNameLabel: 'Hide Name',
            hideNameDescription: 'If checked, your gifter will not be able to publish your message and pseudonym you provided above to their wishlist. Regardless of whether you check this or not, your email and personal information will always be private.',
            agreeLabel: 'I agree to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/policy" target="_blank">Privacy Policy</a> and the following statements:',
            agreeList: [
                'I am making a <b>non-refundable</b> cash gift donation.',
                '<b>I expect no product or service in return</b> from the gift recipient.',
                'This payment is a donation intended for the gift recipient.',
                'I have taken the necessary steps to confirm the account owner is authentic and I understand that <b>giftxyz will not be held responsible for any issues arising from a catfishing situation.</b>',
                'I understand that by violating these terms I may be subject to legal action or can fall a victim of scams.',
                'I understand that by checking the box above and then clicking "PURCHASE", I will have created a <b>legally binding e-signature</b> to this agreement.'
            ]
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
  position: absolute;
  top: -100px;
  min-width: 148px;
  height: 148px;
  border-radius: 50%;
}

.giftPurchase_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 59px;

  .charge_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-align: center;
    color: #0B254E;

    span {
      font-weight: bold;
    }
  }

  .gift_details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: rgb(238 238 238 / 50%);
    border-radius: 12px;
    padding: 0 13px 0 21px;
    margin-top: 10px;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      color: #0B254E;
    }
  }

  .suggested_use {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    color: #0B254E;
    opacity: 0.5;
  }

    .gift_form {
        display: flex;
        flex-direction: column;
        gap: 17px;
        width: 100%;
        max-width: 321px;
        margin-bottom: 28px;

        .gift_form_top {
            display: flex;
            gap: 9px;
        }
    }
}

.username {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #0b254e;
}

.price {
  font-size: 13px;
  line-height: 40px;
  text-align: center;
  color: #0b254e;
}
</style>