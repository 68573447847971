<template>
  <div class="register-container">
    <RegLeftBar/>
    <div class="form-section">
      <div class="register-form">
        <p class="small-title">Already a member?
          <router-link :to="{name: 'login'}" class="link">Log in now.</router-link>
        </p>
        <h1 class="title">Sign up via <span>Twitter</span></h1>
        <p class="buttons-label">Display Currency</p>
        <div class="buttons-main role currencies">
          <base-select
              :options="currencies"
              :visible-key="'code'"
              :selected="currency"
              class="select"
              @selected="selectCurrency"
          />
        </div>
        <p class="buttons-label">Account type</p>
        <div class="buttons-main role">
          <base-button
              v-for="button in roleButtons"
              :key="button.id"
              class="role-button"
              :class="form.role_id === button.id ? 'primary' : 'secondary'"
              @click.prevent="form.role_id = button.id"
          >
            {{ button.name }}
          </base-button>
        </div>
        <base-input
            v-model="form.role_id"
            :input-type="'hidden'"
            :input-name="'role'"
        />

        <base-button class="primary twitter_button" @click="register">Register</base-button>
        <p class="register-form-text">By clicking Register, you agree to our <a href="/files/terms_of_service.pdf"
                                                                                target="_blank"
                                                                                class="link">Terms and Conditions</a>,
          <a href="/files/cookie_policy.pdf" target="_blank" class="link">Cookie Policy,</a> and <a
              href="/files/privacy-policy.pdf" target="_blank" class="link">Privacy Policy</a>.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import './RegisterPage/RegisterPage.scss'
import RegLeftBar from '@/router/components/RegLeftBar/RegLeftBar.vue';
import BaseButton from "@/router/UI/BaseButton";
import BaseSelect from "@/router/UI/BaseSelect.vue";
import BaseInput from "@/router/UI/BaseInput.vue";
</script>

<script>
import currencyHelper from "@/mixins/currencyHelper";
import preloader from "@/mixins/preloader";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RegisterPage",
  mixins: [currencyHelper, preloader],
  data() {
    return {
      form: {
        role_id: null,
      },
      roleButtons: [],
      currencies: [],
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/user',
    }),
  },
  created() {
    this.getUser().then(() => {
      if (this.authUser.role_id && this.authUser.location_id && this.authUser.selected_currency_id) {
        this.$router.push({name: 'dashboard'})
      }
    })
  },
  mounted() {
    this.form.location_id = this.currencyId
    this.form.selected_currency_id = this.currencyId
    this.axios.get('currencies')
        .then((res) => {
          this.currencies = res.data.currencies;
        })
    this.axios.get('get-register-data')
        .then((res) => {
          this.roleButtons = res.data.roles;
        }).finally(() => {
      this.setLoading(false);
    })
  },
  methods: {
    ...mapActions({getUser: 'auth/login'}),
    selectCurrency(currency) {
      this.form.location_id = currency.id
      this.form.selected_currency_id = currency.id
    },
    register() {
      if (!this.form.role_id) {
        this.toast.error('Please select account type')
        return
      }

      this.setLoading(true);
      this.axios.post('twitter-register', this.form).then(() => {
        this.$router.push({name: 'dashboard'})
      }).catch((error) => {
        this.toast.error(error.response?.data.message)
      }).finally(() => {
        this.setLoading(false);
      })
    }
  },
}

</script>

<style lang="scss" scoped>
.register-container {
  .form-section {
    .register-form {
      .title {
        margin: 24px auto;

        span {
          color: #00AFF0;
        }
      }

      .twitter_button {
        max-width: 270px;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 17px;
      }
    }
  }
}
</style>
<style>
.currencies .dropdown-wrapper {
  height: 48px;
}
</style>