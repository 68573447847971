export default {
    namespaced: true,
    state: {
        updateCart: false,
    },
    getters: {
        updateCart(state) {
            return state.updateCart;
        },
    },
    mutations: {
        SET_UPDATE_CART(state, value) {
            state.updateCart = value;
        },
    },
    actions: {
        updateCartStatus({commit}, data) {
            commit("SET_UPDATE_CART", data);
        },
    },
};
