<template>
  <div :class="{active: notification}" class="order_modal_wrapper">
    <div class="order_modal_overlay" @click="$emit('close')">
      <LottieAnimation v-if="notification && notification.opened === 0" class="new_gift_animation" path="animations/new_gift.json" :loop="false"/>
    </div>
    <div class="order_modal" :style="`transition: bottom ${transition}s ease-in-out;`">
      <div class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="order_modal_top">
        <div class="order_modal_left">
          <div class="image_block">
            <div v-if="notification?.from_user?.is_online" class="is_online"></div>
            <img class="avatar"
                 :src="notification?.from_user?.avatar ? notification?.from_user.avatar : require('@assets/images/user-default.png')"
                 alt="avatar"/>
          </div>
          <div>
            <p class="username">{{ notification?.from_user ? '@' + notification?.from_user?.username : 'Guest' }}</p>
            <p v-if="notification?.from_user?.is_online" class="online">Online</p>
          </div>
        </div>
        <div class="order_modal_right">
          <p class="gifts_sent">Gifts Sent: {{ parseFloat(notification?.order?.products?.length) }}</p>
          <p class="total">Total: {{ orderTotal }}</p>
        </div>
      </div>
      <div class="order_modal_bottom">
        <div class="products_and_message_block">
          <ul class="products">
            <li v-for="product in notification?.order?.products" :key="product.id" class="product">
              <div class="product_left">
                <img class="product_image"
                     :src="productImage(product)"
                     alt="Product"/>
                <div class="product_info">
                  <div class="product_name_block">
                    <p class="product_name">{{ product.product_id ? product.product.name : 'Custom Gift' }}</p>
                    <p class="product_date">{{ moment(notification.created_at).fromNow() }}</p>
                  </div>
                  <p class="product_message">{{ notification?.order?.message }}</p>
                </div>
              </div>
              <div class="product_right">
                <p class="product_price">
                  +{{ formatAmount(
                    product.product_id ? convertPrice(product.product, parseFloat(product.product.price)) : authUser.id === notification?.order?.creator_id ? currencyToCurrency(parseFloat(product.amount), notification?.order?.currency, authUser.location.code) : product.amount,
                    authUser.id === notification?.order?.creator_id ? authUser.location.code : notification?.order?.currency?.code
                ) }}</p>
              </div>
            </li>
<!--            <li class="product">-->
<!--              <div class="product_left">-->
<!--                <svg class="product_image" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M8.09941 16.1998H5.39941" stroke="#00AFF0" stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M8.09941 26.9999H5.39941" stroke="#00AFF0" stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M7.20059 21.6002H3.60059" stroke="#00AFF0" stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M9.55089 32.3997C14.0288 37.406 21.1316 39.1299 27.4062 36.7332C33.6808 34.3365 37.8256 28.3163 37.8256 21.5995C37.8256 14.8828 33.6807 8.86266 27.406 6.46599C21.1314 4.06933 14.0286 5.79326 9.55078 10.7997" stroke="#00AFF0" stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9857 14.5541C20.2889 13.9396 20.9147 13.5505 21.5999 13.5505C22.2851 13.5505 22.9109 13.9396 23.2141 14.5541L24.1299 16.4106C24.392 16.942 24.8989 17.3103 25.4852 17.3955L27.5339 17.6933C28.2117 17.7919 28.7748 18.2667 28.9866 18.9181C29.1983 19.5695 29.022 20.2847 28.5317 20.763L27.0487 22.2095C26.6247 22.623 26.4313 23.2186 26.5315 23.8023L26.8814 25.8422C26.9971 26.5174 26.7196 27.1998 26.1654 27.6025C25.6112 28.0052 24.8764 28.0585 24.27 27.7398L22.4372 26.7765C21.913 26.501 21.2867 26.501 20.7625 26.7765L18.9297 27.7398C18.3233 28.0585 17.5885 28.0052 17.0343 27.6025C16.4801 27.1998 16.2026 26.5174 16.3183 25.8422L16.6682 23.8023C16.7684 23.2186 16.575 22.623 16.151 22.2095L14.6681 20.763C14.1778 20.2847 14.0015 19.5695 14.2132 18.9181C14.425 18.2667 14.9881 17.7919 15.666 17.6933L17.7146 17.3955C18.301 17.3103 18.8078 16.942 19.0699 16.4106L19.9857 14.5541Z" stroke="#00AFF0" stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->

<!--                <div class="product_info">-->
<!--                  <div class="product_name_block">-->
<!--                    <p class="product_reward">3% Gift Rewards</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="product_right">-->
<!--                <p class="product_price">+{{ rewardTotal }}</p>-->
<!--              </div>-->
<!--            </li>-->
          </ul>

          <div v-if="notification?.order?.creator_message || justSent" class="message">
            <div class="message_top">
              <p class="message_name">{{ authUser.id === notification?.order?.creator_id ? 'You' : '@'+notification?.order?.creator.username }}</p>
              <p class="message_date">{{ justSent ? moment().fromNow() : moment(notification.order.updated_at).fromNow() }}</p>
            </div>
            <p class="message_text">{{ justSent ? justSent : notification?.order?.creator_message }}</p>
          </div>
        </div>

        <div v-if="!notification?.order?.creator_message && authUser.id != notification?.order?.buyer_id && !justSent" class="reply_block">
          <input type="text" placeholder="Type Reply Here ..." @input="typeMessage" ref="message">
          <div class="send_message" @click="sendMessage">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M1.03235 0.456367C0.943324 0.491032 0.840947 0.538694 0.805338 0.564692C0.689609 0.660017 0.600586 0.906996 0.600586 1.12798C0.600586 1.30996 2.47452 9.60324 2.52348 9.64657C2.53683 9.65957 11.6172 11.1934 12.721 11.3711C13.0326 11.4231 13.2863 11.4751 13.2863 11.4881C13.2863 11.5011 13.0326 11.5531 12.721 11.6051C11.6127 11.7827 2.53683 13.3166 2.52348 13.3296C2.47452 13.3729 0.600586 21.6662 0.600586 21.8482C0.600586 22.3595 0.992286 22.6671 1.48636 22.5371C1.71337 22.4765 22.3978 12.1597 22.5358 12.034C22.7005 11.891 22.7673 11.7307 22.7673 11.4881C22.7673 11.2454 22.7005 11.0851 22.5358 10.9421C22.4067 10.8251 1.72672 0.499697 1.49972 0.439035C1.29051 0.382708 1.24155 0.387039 1.03235 0.456367Z"
                  :fill="creator_message?.trim().length ? '#00AFF0' : '#0B254E'" :fill-opacity="creator_message?.trim().length ? 1 : 0.2"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
</script>

<script>
import moment from "moment/moment";
import {mapGetters} from "vuex";
import currencyHelper from "@/mixins/currencyHelper";
import preloader from "@/mixins/preloader";

export default {
  name: "OrderModal",
  mixins: [currencyHelper, preloader],
  data() {
    return {
      creator_message: '',
      justSent: false,
      transition: .3
    }
  },
  watch: {
    notification: {
      handler() {
        this.transition = this.notification?.opened === 0 ? 1.5 : .3
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/user',
    }),
    moment() {
      return moment
    },
    orderTotal() {
      if(!this.notification){
        return 0
      }
      const finalTotal = (this.notification?.order?.total - this.notification?.order?.fee) // + ( ( this.notification?.order?.total * 0.9 ) * 0.04 )
      return this.formatAmount( this.convertPrice(this.notification?.order, finalTotal), this.currency.code);
    },
    // rewardTotal() {
    //   if(!this.notification){
    //     return 0
    //   }
    //   const reward = ( this.notification?.order?.total * 0.9 ) * 0.04
    //   return this.formatAmount( this.convertPrice(this.notification?.order, reward), this.currency.code);
    // },
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  methods: {
    sendMessage() {
      if (this.creator_message && this.creator_message.trim().length === 0) {
        this.toast.error('Please enter message')
      } else {
        this.setLoading(true)
        this.axios.post('user/reply', {
          order_id: this.notification.order_id,
          creator_message: this.creator_message
        }).then(() => {
          this.justSent = this.creator_message
          this.creator_message = null
          this.$refs.message.value = ''
        }).catch(err => {
          this.toast.error(err.response.data.message)
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },
    productImage(product){
      return product.product?.image ? process.env.VUE_APP_LIVE_URL + product.product.image : require('@/assets/images/wish-default.svg')
    },
    typeMessage(e) {
      this.creator_message = e.target.value
    },
  }
}
</script>

<style lang="scss" scoped>
.order_modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;

  .order_modal_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .new_gift_animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .order_modal {
    position: absolute;
    left: 50%;
    bottom: -100%;
    transform: translate(-50%, 50%);
    width: 100%;
    max-width: 546px;
    background: #fff;
    border-radius: 14px;
    z-index: 9999;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform: none;
      bottom: -100%;
      left: 0;
    }

    &:before {
      content: '';
      display: none;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
      width: 89px;
      height: 2px;
      background: rgba(11, 37, 78, 0.2);
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    .close {
      position: absolute;
      right: 12px;
      top: 9px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .order_modal_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 15px;
      @media screen and (max-width: 768px) {
        padding: 17px 14px 16px 9px;
      }

      .order_modal_left {
        display: flex;
        align-items: center;
        gap: 18px;

        .image_block {
          display: flex;

          .avatar {
            max-width: 70px;
            max-height: 70px;
            min-width: 70px;
            min-height: 70px;
            border-radius: 50%;
            object-fit: cover;
            @media screen and (max-width: 768px) {
              width: 50px;
            }
          }
        }

        .username {
          font-weight: 700;
          font-size: 19.44px;
          line-height: 30px;
          color: #0B254E;

          @media screen and (max-width: 768px) {
            font-size: 14.05px;
            line-height: 24px;
          }
        }

        .online {
          font-weight: 500;
          font-size: 14.05px;
          line-height: 28px;
          color: rgba(11, 37, 78, 0.5);
          @media screen and (max-width: 768px) {
            font-size: 10.16px;
            line-height: 16px;
          }
        }
      }

      .order_modal_right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .gifts_sent {
          font-weight: 700;
          font-size: 14.05px;
          line-height: 24px;
          color: #0B254E;
          @media screen and (max-width: 768px) {
            font-size: 10.16px;
            line-height: 18px;
          }
        }

        .total {
          font-weight: 500;
          font-size: 14.05px;
          line-height: 24px;
          color: #0B254E;
          opacity: 0.5;
          @media screen and (max-width: 768px) {
            font-size: 9.52px;
            line-height: 18px;
          }
        }
      }
    }

    .order_modal_bottom {
      padding: 36px 15px 52px;
      background: #F9F9F9;
      border-radius: 14px;
      min-height: 540px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        padding: 26px 9px 37px;
        min-height: 360px;
      }

      .products_and_message_block {
        display: flex;
        flex-direction: column;
        gap: 34px;

        .products {
          list-style: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .product {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            border-radius: 13px;
            padding-right: 30px;
            min-height: 93px;
            @media screen and (max-width: 768px) {
              min-height: 67px;
              padding-right: 23px;
            }

            .product_left {
              display: flex;
              align-items: center;
              gap: 21px;
              @media screen and (max-width: 768px) {
                gap: 9px;
              }

              .product_image {
                border-radius: 13px;
                width: 99px;
                @media screen and (max-width: 768px) {
                  width: 72px;
                }
              }

              .product_info {

                .product_name_block {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  @media screen and (max-width: 768px) {
                    gap: 3px;
                  }

                  .product_reward {
                    color: #00AFF0;
                    font-size: 18.22px;
                    line-height: 28px;
                    font-weight: 700;
                    @media screen and (max-width: 768px) {
                      font-size: 13.17px;
                      line-height: 25.312px;
                    }
                  }

                  .product_name {
                    font-weight: 700;
                    font-size: 18.22px;
                    line-height: 28px;
                    color: #0B254E;
                    @media screen and (max-width: 768px) {
                      font-size: 13.17px;
                      line-height: 22px;
                    }
                  }

                  .product_date {
                    font-weight: 700;
                    font-size: 13.17px;
                    line-height: 18px;
                    color: #0B254E;
                    opacity: 0.3;
                    @media screen and (max-width: 768px) {
                      font-size: 9.52px;
                      line-height: 14px;
                    }
                  }
                }

                .product_message {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 28px;
                  color: #0B254E;
                  opacity: 0.8;
                  @media screen and (max-width: 768px) {
                    font-size: 10.84px;
                    line-height: 20px;
                  }
                }
              }
            }

            .product_right {
              .product_price {
                font-weight: 700;
                font-size: 18.22px;
                line-height: 35px;
                color: #00AFF0;
                @media screen and (max-width: 768px) {
                  font-size: 13.17px;
                }
              }
            }
          }
        }

        .message {
          width: 100%;
          min-height: 67px;
          background: #00AFF0;
          border-radius: 9.04px;
          padding: 10px 20px;

          .message_top {
            display: flex;
            align-items: center;
            gap: 10px;

            .message_name {
              font-weight: 700;
              font-size: 13.17px;
              line-height: 25px;
              color: #FFFFFF;
            }

            .message_date {
              font-weight: 700;
              font-size: 9.52px;
              line-height: 32px;
              color: #FFFFFF;
              opacity: 0.5;
            }
          }

          .message_text {
            font-weight: 500;
            font-size: 10.84px;
            line-height: 20px;
            color: #FFFFFF;
          }
        }
      }

      .reply_block {
        position: relative;
        background: #FFFFFF;
        border-radius: 13px;

        input {
          width: 100%;
          height: 83px;
          font-weight: 500;
          font-size: 14.05px;
          line-height: 35px;
          color: #0B254E;
          opacity: 0.5;
          border: none;
          padding-left: 36px;
          @media screen and (max-width: 768px) {
            height: 59px;
            font-size: 9.52px;
            line-height: 25px;
            padding-left: 26px;
          }
        }

        .send_message {
          position: absolute;
          right: 23px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          cursor: pointer;

          svg {
            @media screen and (max-width: 768px) {
              width: 17px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

.order_modal_wrapper.active {
  visibility: visible;

  .order_modal_overlay {
    opacity: 1;
  }


  .order_modal {
    bottom: 50%;
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
    }
  }
}
</style>