<template>
  <div class="gift_item" v-click-outside="closeDropdown">
    <div class="gift_image">
      <img :src="productImage" :alt="item.name">
    </div>
    <div class="gift_item_right" ref="productContentWidth">
      <div class="actions" @click="dropdown = !dropdown">
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M8.09999 14.4C8.09999 14.6387 8.00517 14.8676 7.83638 15.0364C7.6676 15.2052 7.43868 15.3 7.19999 15.3C6.96129 15.3 6.73237 15.2052 6.56359 15.0364C6.39481 14.8676 6.29999 14.6387 6.29999 14.4C6.29999 14.1613 6.39481 13.9324 6.56359 13.7636C6.73237 13.5948 6.96129 13.5 7.19999 13.5C7.43868 13.5 7.6676 13.5948 7.83638 13.7636C8.00517 13.9324 8.09999 14.1613 8.09999 14.4ZM15.3 14.4C15.3 14.6387 15.2052 14.8676 15.0364 15.0364C14.8676 15.2052 14.6387 15.3 14.4 15.3C14.1613 15.3 13.9324 15.2052 13.7636 15.0364C13.5948 14.8676 13.5 14.6387 13.5 14.4C13.5 14.1613 13.5948 13.9324 13.7636 13.7636C13.9324 13.5948 14.1613 13.5 14.4 13.5C14.6387 13.5 14.8676 13.5948 15.0364 13.7636C15.2052 13.9324 15.3 14.1613 15.3 14.4ZM22.5 14.4C22.5 14.6387 22.4052 14.8676 22.2364 15.0364C22.0676 15.2052 21.8387 15.3 21.6 15.3C21.3613 15.3 21.1324 15.2052 20.9636 15.0364C20.7948 14.8676 20.7 14.6387 20.7 14.4C20.7 14.1613 20.7948 13.9324 20.9636 13.7636C21.1324 13.5948 21.3613 13.5 21.6 13.5C21.8387 13.5 22.0676 13.5948 22.2364 13.7636C22.4052 13.9324 22.5 14.1613 22.5 14.4Z" stroke="#0B254E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>
      </div>
      <ul v-if="dropdown" class="dropdown">
        <li @click="$emit('edit', item)">
          <span>Edit Gift</span>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6568 5.01303L17.2033 3.4657C17.5256 3.14333 17.9629 2.96222 18.4188 2.96222C18.8747 2.96222 19.3119 3.14333 19.6343 3.4657C19.9566 3.78807 20.1377 4.22529 20.1377 4.6812C20.1377 5.1371 19.9566 5.57433 19.6343 5.8967L6.46268 19.0683C5.97806 19.5526 5.38043 19.9086 4.72376 20.1041L2.26251 20.8374L2.99585 18.3762C3.19135 17.7195 3.54734 17.1219 4.03168 16.6373L15.6578 5.01303H15.6568ZM15.6568 5.01303L18.075 7.4312"
                stroke="black" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </li>
        <li @click="copyProductLink(item.id)">
          <span>Copy Link</span>
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3635 0.0463772C12.7486 0.144215 12.1091 0.406281 11.6059 0.76269C11.3194 0.968848 8.0174 4.19051 7.90209 4.37919C7.81124 4.52595 7.82521 4.63078 7.94052 4.74958C8.03487 4.84043 8.06631 4.85091 8.28296 4.85091C8.79311 4.85091 9.42906 4.96622 9.9462 5.15141C10.376 5.30516 10.2502 5.38902 11.6479 3.99833C12.3292 3.32045 12.9547 2.72294 13.0351 2.67053C13.713 2.25122 14.618 2.33508 15.2085 2.87319C15.8339 3.44974 15.9562 4.45607 15.481 5.16889C15.3238 5.40649 11.3718 9.35145 11.1622 9.48074C10.5227 9.87558 9.69112 9.83016 9.10759 9.36892C8.48212 8.87973 8.08728 8.79587 7.57363 9.04047C7.31855 9.16626 7.13685 9.35145 7.01456 9.61352C6.9307 9.79871 6.92371 9.85112 6.93768 10.1202C6.95865 10.4976 7.05998 10.7212 7.34651 10.9937C7.88811 11.5179 8.60792 11.8917 9.38014 12.0525C9.69462 12.1154 9.81342 12.1224 10.2851 12.1049C11.253 12.0699 11.8296 11.8952 12.5564 11.4095C12.9303 11.1615 17.2246 6.86707 17.4587 6.50717C18.3043 5.21082 18.4057 3.66288 17.7383 2.32111C17.1338 1.09813 15.9632 0.249041 14.618 0.0428829C14.2196 -0.0165186 13.7619 -0.0130243 13.3635 0.0463772Z" fill="black"/>
            <path d="M7.51121 5.81174C6.95213 5.91656 6.41752 6.14019 5.88989 6.48263C5.62084 6.65384 1.39983 10.8574 1.12379 11.2243C0.875703 11.5562 0.568213 12.1712 0.466881 12.5381C0.190838 13.5409 0.257228 14.4983 0.666051 15.4208C1.0574 16.2979 1.87854 17.119 2.75559 17.5104C3.87024 18.0065 5.09321 18.003 6.21835 17.4964C6.80887 17.2308 7.01153 17.0561 8.88443 15.1797C10.6245 13.4361 10.6455 13.4151 10.6211 13.2998C10.5826 13.0867 10.4883 13.0413 10.02 13.0168C9.48543 12.9888 9.08011 12.9155 8.61188 12.7652C8.40572 12.6988 8.19956 12.6429 8.15414 12.6429C8.09124 12.6429 7.74881 12.9609 6.81236 13.8973C6.05412 14.6486 5.48456 15.1867 5.38323 15.2461C5.1037 15.4068 4.81717 15.4802 4.46775 15.4837C3.96109 15.4872 3.55926 15.323 3.20634 14.9701C2.85343 14.6171 2.69269 14.2258 2.69269 13.7087C2.6892 13.3313 2.78005 13.0308 2.97922 12.7233C3.15742 12.4542 7.13733 8.4918 7.35047 8.37299C7.43783 8.32408 7.61603 8.2507 7.74532 8.20527C7.94798 8.13888 8.03883 8.1284 8.33933 8.14238C8.85648 8.16684 9.01372 8.23323 9.62171 8.70145C9.88028 8.90062 10.0445 8.95653 10.3904 8.95653C10.8796 8.95303 11.3024 8.66651 11.4736 8.21925C11.5785 7.93622 11.561 7.56234 11.4247 7.28629C11.1696 6.76216 10.1808 6.11224 9.28277 5.87463C8.84599 5.76282 7.95497 5.73137 7.51121 5.81174Z" fill="black"/>
          </svg>
        </li>
        <li @click="deleteProduct(item.id)">
          <span>Delete</span>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6116 8.95L13.2945 17.2M8.90548 17.2L8.58831 8.95M17.7256 6.0075C18.0391 6.05517 18.3508 6.10559 18.6625 6.15967M17.7256 6.0075L16.7466 18.7336C16.7067 19.2517 16.4726 19.7357 16.0912 20.0886C15.7099 20.4416 15.2093 20.6377 14.6896 20.6375H7.51031C6.99065 20.6377 6.49008 20.4416 6.10871 20.0886C5.72733 19.7357 5.49327 19.2517 5.45331 18.7336L4.47431 6.0075M17.7256 6.0075C16.6677 5.84756 15.6043 5.72617 14.5375 5.64359M4.47431 6.0075C4.16081 6.05425 3.84914 6.10467 3.53748 6.15875M4.47431 6.0075C5.53226 5.84756 6.59569 5.72618 7.66248 5.64359M14.5375 5.64359V4.80392C14.5375 3.72225 13.7033 2.82025 12.6216 2.78634C11.6075 2.75392 10.5925 2.75392 9.57831 2.78634C8.49664 2.82025 7.66248 3.72317 7.66248 4.80392V5.64359M14.5375 5.64359C12.2492 5.46674 9.95073 5.46674 7.66248 5.64359" stroke="#FF0000" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </li>
      </ul>
      <div class="gift_info">
        <p class="name" :style="`width:${nameWidth}px`">{{ item.name }}</p>
        <div class="price" :style="`width:${nameWidth}px`">{{ formatAmount(convertPrice(item, item.price), currency.code) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import currencyHelper from "../../mixins/currencyHelper";
import vClickOutside from 'click-outside-vue3'

export default {
  name: "GiftItem",
  mixins: [currencyHelper],
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dropdown: false,
      nameWidth: 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.nameWidth = this.$refs.productContentWidth.offsetWidth
    }, 0)
  },
  computed: {
    ...mapGetters({user: 'auth/user', currency: "currency"}),
    productImage() {
      return this.item.image ? process.env.VUE_APP_LIVE_URL + this.item.image : require('../../assets/images/wish-default.svg')
    }
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
    }),
    deleteProduct(id) {
      this.axios.post('wishlists/delete', {id}).then(() => {
        this.updateUser()
        this.toast.info('Wish deleted successfully')
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    },
    closeDropdown() {
      this.dropdown = false
    },
    copyProductLink(id){
      const el = document.createElement('textarea');
      el.value = window.location.origin + '/' + this.user.username + '?product=' + id;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.closeDropdown();
      this.toast.info('Wishlist URL copied to clipboard');
    }
  }
}
</script>

<style lang="scss" scoped>
.gift_item {
  display: flex;
  flex-basis: calc(50% - 22px);
  max-width: calc(50% - 22px);
  gap: 27px;
  position: relative;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    max-width: 100%;
    gap: 19px;
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    @media screen and (max-width: 768px) {
      opacity: 1;
    }
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }

  .dropdown {
    position: absolute;
    top: 33px;
    right: 0;
    background: rgba(242, 242, 242, 0.5);
    backdrop-filter: blur(3.8016px);
    border-radius: 15px;
    min-width: 190px;
    z-index: 99;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 12px 11px 21px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(242, 242, 242, 0.8);

        span {
          font-weight: bold;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(11, 37, 78, 0.1);
      }

      &:last-child {
        span {
          color: #FF0000;
        }
      }

      span {
        font-weight: 500;
        font-size: 17.6px;
        color: #000000;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .gift_image {
    min-width: 174px;
    max-width: 174px;
    height: 163px;
    border-radius: 13.2px;
    @media screen and (max-width: 768px) {
      min-width: 122px;
      height: 114px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 13.2px;
    }
  }

  .gift_item_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .gift_info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .price {
        font-weight: 700;
        font-size: 34.32px;
        line-height: 33px;
        color: #00AFF0;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 768px) {
          font-size: 21px;
          line-height: 23px;
        }
      }

      .name {
        font-weight: 700;
        font-size: 28.6px;
        line-height: 44px;
        color: #2F2E41;
        @media screen and (max-width: 768px) {
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          line-height: 31px;
          margin-bottom: 2px;
        }
      }
    }

    .primary {
      font-weight: 700;
      font-size: 19.3794px;
      line-height: 29px;
      padding: 0;
      max-width: 175px;
      width: 100%;
      height: 55px;
      border-radius: 10px;
      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 154px;
        width: 100%;
        height: 44px;
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}
</style>