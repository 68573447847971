<template>
  <div class="filter_by_date">
      <div v-for="item in items" :key="item" class="filter_item" :class="{active: selected === item}" @click="$emit('change-filter', item)">{{ item }}</div>
  </div>
</template>

<script>
export default {
    name: "FilterDate",
    props: {
        items: {
            type: Array,
            default: () => ['30D', '1Y', 'All']
        },
        selected: {
            type: String,
            default: '30D'
        }
    }
}
</script>

<style lang="scss" scoped>
.filter_by_date {
  max-width: 293px;
  display: flex;
  gap: 14px;
  width: 100%;
  margin-top: 36px;
  @media (max-width: 768px) {
    max-width: 100%;
    gap: 18px;
    margin-top: 23px;
  }
  .filter_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border: 3px solid transparent;
    border-radius: 25px;
    font-weight: 700;
    font-size: 19.4594px;
    line-height: 55px;
    color: #0B254E;
    opacity: 0.5;
    cursor: pointer;
    transition: all .3s;
    @media (max-width: 768px) {
      font-size: 13px;
      height: 40px;
    }

    &.active{
      opacity: 1;
      border: 3px solid #00AFF0;
      color: #00AFF0;
    }
  }
}
</style>