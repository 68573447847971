<template>
    <div>
        <label
        >
            {{ label }}
        </label>
        <textarea
                :rows="rows"
                :cols="cols"
                :placeholder="placeholder"
                @keydown="$emit('update:modelValue', $event.target.value)"
        ></textarea>
    </div>
</template>

<script>
export default {
    name: 'BaseTextArea',
    components: {},
    props: {
        modelValue: {
            type: String,
            required: false
        },
        label: {
            default: null
        },
        rows: {
            default: 4
        },
        cols: {
            default: 0
        },
        placeholder: {
            default: null
        },
        areaName: {
            required: false
        }
    },
}
</script>
