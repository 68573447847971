<template>
  <div class="checkout_input">
    <label :for="id" v-html="label"></label>
    <input :type="type" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue">
  </div>
</template>

<script>
export default {
  name: "CheckoutInput",
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout_input {
  margin-bottom: 5px;

  label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 53px;
    color: #0B254E;
    @media (max-width: 768px) {
      font-size: 12.8px;
      line-height: 42px;
    }

  }

  input {
    font-size: 16px;
    color: #0B254E;
    padding-left: 20px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    border: none;
    width: 100%;
    height: 68px;
    @media (max-width: 768px) {
      height: 54px;
    }
  }
}
</style>

<style>
.checkout_input label span {
  opacity: 0.5;
  margin-left: 8px;
}
</style>