<template class="d-flex">
  <button
      :disabled="loading || disabled"
  >
    <slot></slot>
    <loader-icon
        v-if="loading"
        :color="'black'"
    />
  </button>
</template>

<script>

import LoadingIcon from '../../assets/svg/LoadingIcon';

export default {
  name: "BaseButton",
  components:{
    'loader-icon': LoadingIcon
  },
  props: {
    loading: {
      required: false
    },
    disabled: {
      required: false
    }
  }
}
</script>


