<template>
  <svg width="395" height="296" viewBox="0 0 395 296" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_990_1437)">
      <path
          d="M311.527 189.933C265.168 189.933 228.854 181.536 228.854 170.817C228.854 164.39 241.721 158.656 264.157 155.085C264.237 155.072 264.319 155.074 264.398 155.093C264.477 155.112 264.552 155.146 264.618 155.193C264.683 155.241 264.739 155.301 264.782 155.37C264.825 155.439 264.853 155.516 264.866 155.596C264.879 155.677 264.875 155.759 264.856 155.838C264.837 155.917 264.803 155.991 264.755 156.057C264.707 156.122 264.647 156.178 264.577 156.22C264.508 156.262 264.431 156.29 264.351 156.303C243.216 159.667 230.087 165.228 230.087 170.817C230.087 180.511 267.382 188.7 311.527 188.7C355.673 188.7 392.967 180.511 392.967 170.817C392.967 165.205 379.755 159.63 358.485 156.268C358.324 156.242 358.18 156.153 358.084 156.021C357.988 155.889 357.948 155.724 357.974 155.563C357.999 155.401 358.088 155.257 358.22 155.161C358.352 155.064 358.517 155.024 358.678 155.05C381.254 158.618 394.201 164.365 394.201 170.817C394.201 181.536 357.887 189.933 311.527 189.933Z"
          fill="#CCCCCC"/>
      <path
          d="M270.51 193.534C274.704 200.713 280.711 206.665 287.93 210.795C295.149 214.925 303.326 217.087 311.644 217.067C319.962 217.046 328.128 214.842 335.326 210.676C342.524 206.509 348.501 200.527 352.659 193.327C325.301 194.9 297.876 194.969 270.51 193.534Z"
          fill="#E6E6E6"/>
      <path
          d="M357.207 180.143C359.229 173.056 359.573 165.597 358.211 158.354C356.85 151.112 353.821 144.286 349.364 138.416C344.907 132.546 339.144 127.794 332.532 124.535C325.92 121.276 318.639 119.601 311.267 119.64C303.894 119.68 296.632 121.435 290.056 124.765C283.479 128.095 277.768 132.909 273.374 138.827C268.981 144.744 266.026 151.603 264.743 158.859C263.46 166.116 263.885 173.571 265.983 180.635C296.285 184.415 326.948 184.249 357.207 180.143Z"
          fill="#E6E6E6"/>
      <path
          d="M74.3027 5.55003C74.3025 4.54664 74.0301 3.56208 73.5145 2.70112C72.9989 1.84016 72.2595 1.13501 71.3748 0.6607C70.4902 0.186385 69.4934 -0.039344 68.4906 0.00752366C67.4879 0.0543913 66.5165 0.372104 65.68 0.926859C64.8435 1.48161 64.1731 2.25266 63.7402 3.15795C63.3072 4.06324 63.1279 5.0689 63.2214 6.06795C63.3148 7.06699 63.6774 8.02203 64.2707 8.83145C64.864 9.64087 65.6657 10.2744 66.5906 10.6646V39.4667H70.9094V10.6646C71.9154 10.2399 72.7739 9.52828 73.3774 8.61859C73.981 7.70891 74.3028 6.64157 74.3027 5.55003Z"
          fill="#CCCCCC"/>
      <path
          d="M105.46 143.683H105.458C103.798 143.682 102.156 143.345 100.629 142.695C99.1016 142.044 97.7214 141.093 96.5705 139.897C95.4195 138.702 94.5214 137.287 93.93 135.736C93.3385 134.186 93.0656 132.533 93.1278 130.875C93.3751 124.336 99.039 119.017 105.754 119.017H139.653L167.548 106.674C168.296 106.344 169.144 106.324 169.907 106.619C170.67 106.913 171.285 107.498 171.617 108.245L179.11 125.162C179.44 125.91 179.46 126.758 179.165 127.52C178.87 128.283 178.285 128.897 177.538 129.229L150.063 141.386C147.342 142.594 144.402 143.233 141.425 143.264L105.46 143.683Z"
          fill="#CCCCCC"/>
      <path
          d="M23.2887 208.645C23.1279 208.645 22.9674 208.631 22.8089 208.604L4.56506 205.492C4.19999 205.43 3.85121 205.295 3.53898 205.096C3.22675 204.897 2.95754 204.637 2.74717 204.333C2.53681 204.028 2.38956 203.684 2.3141 203.322C2.23864 202.96 2.23649 202.586 2.30777 202.223L16.5879 129.425C17.1256 126.281 18.8906 123.48 21.4948 121.637C24.099 119.794 27.3289 119.061 30.474 119.598C33.6192 120.136 36.4219 121.9 38.2656 124.503C40.1093 127.106 40.843 130.334 40.3053 133.478L26.0006 206.402C25.8807 207.033 25.5442 207.603 25.049 208.012C24.5539 208.421 23.9313 208.645 23.2887 208.645Z"
          fill="#CCCCCC"/>
      <path
          d="M13.5303 224.467C19.6636 224.467 24.6357 219.497 24.6357 213.367C24.6357 207.236 19.6636 202.267 13.5303 202.267C7.39689 202.267 2.4248 207.236 2.4248 213.367C2.4248 219.497 7.39689 224.467 13.5303 224.467Z"
          fill="#E6E6E6"/>
      <path
          d="M67.4053 118.201C90.2351 118.201 108.742 99.7029 108.742 76.8843C108.742 54.0657 90.2351 35.5676 67.4053 35.5676C44.5756 35.5676 26.0684 54.0657 26.0684 76.8843C26.0684 99.7029 44.5756 118.201 67.4053 118.201Z"
          fill="#E6E6E6"/>
      <path
          d="M67.4053 118.201C90.2351 118.201 108.742 99.7029 108.742 76.8843C108.742 54.0657 90.2351 35.5676 67.4053 35.5676C44.5756 35.5676 26.0684 54.0657 26.0684 76.8843C26.0684 99.7029 44.5756 118.201 67.4053 118.201Z"
          fill="#E6E6E6"/>
      <path
          d="M67.4059 108.334C84.7838 108.334 98.8714 94.2537 98.8714 76.8843C98.8714 59.515 84.7838 45.4343 67.4059 45.4343C50.028 45.4343 35.9404 59.515 35.9404 76.8843C35.9404 94.2537 50.028 108.334 67.4059 108.334Z"
          fill="#00AFF0"/>
      <path
          d="M71.0842 90.0866C69.0446 90.03 66.5061 89.9592 64.5505 88.5313C63.9682 88.0887 63.4897 87.5242 63.1487 86.8773C62.8076 86.2304 62.6122 85.5168 62.5762 84.7865C62.5408 84.2675 62.626 83.7473 62.8251 83.2666C63.0242 82.786 63.3318 82.3578 63.7239 82.0157C64.7453 81.153 66.2369 80.9507 67.8442 81.4228L66.1789 69.2587L67.4014 69.0913L69.3592 83.3918L68.3383 82.9236C67.1547 82.381 65.5301 82.1049 64.5205 82.9576C64.2726 83.1806 64.0794 83.4576 63.9561 83.7673C63.8328 84.077 63.7825 84.4109 63.8093 84.7432C63.8392 85.2867 63.986 85.8175 64.2395 86.2993C64.493 86.7811 64.8473 87.2027 65.2785 87.5353C66.8003 88.6464 68.8237 88.7897 71.1186 88.8539L71.0842 90.0866Z"
          fill="#2F2E41"/>
      <path d="M60.1331 70.3076H53.4873V71.541H60.1331V70.3076Z" fill="#2F2E41"/>
      <path d="M81.1087 70.3076H74.4629V71.541H81.1087V70.3076Z" fill="#2F2E41"/>
      <path opacity="0.2"
            d="M46.229 77.0832C46.2296 69.5681 48.9218 62.3016 53.8184 56.5987C58.715 50.8959 65.4925 47.1336 72.9241 45.9928C68.4338 45.2346 63.8324 45.4597 59.4376 46.6527C55.0429 47.8456 50.9596 49.9779 47.4699 52.9023C43.9802 55.8267 41.1672 59.4734 39.2254 63.5905C37.2835 67.7077 36.259 72.1971 36.2227 76.7486C36.1863 81.3002 37.139 85.8054 39.0149 89.953C40.8907 94.1006 43.6451 97.7917 47.0876 100.771C50.5302 103.751 54.5789 105.948 58.954 107.211C63.3291 108.474 67.9264 108.773 72.4283 108.086C65.1018 106.842 58.4521 103.047 53.6569 97.3729C48.8617 91.6984 46.2304 84.5109 46.229 77.0832Z"
            fill="black"/>
      <path
          d="M98.8707 208.742H35.9398C33.2408 208.739 30.6533 207.666 28.7448 205.758C26.8363 203.851 25.7628 201.264 25.7598 198.567V127.65C25.7628 124.952 26.8363 122.366 28.7448 120.459C30.6533 118.551 33.2408 117.478 35.9398 117.475H45.5028V120.25C45.5049 122.13 46.2531 123.933 47.5833 125.262C48.9134 126.592 50.7169 127.34 52.598 127.342H82.2125C84.0936 127.34 85.8971 126.592 87.2272 125.262C88.5574 123.933 89.3055 122.13 89.3077 120.25V117.475H98.8707C101.57 117.478 104.157 118.551 106.066 120.459C107.974 122.366 109.048 124.952 109.051 127.65V198.567C109.048 201.264 107.974 203.851 106.066 205.758C104.157 207.666 101.57 208.739 98.8707 208.742Z"
          fill="#E6E6E6"/>
      <path
          d="M107.315 276.883H82.6308V248.4L50.3936 198.995L71.0648 185.52L103.161 234.71C105.459 238.23 106.717 242.326 106.788 246.528L107.315 276.883Z"
          fill="#E6E6E6"/>
      <path d="M63.8118 295.692H39.75V223.542H63.8118V295.692Z" fill="#E6E6E6"/>
      <path
          d="M93.9345 230.325H40.8751C39.4847 230.324 38.1517 229.771 37.1686 228.788C36.1854 227.805 35.6324 226.473 35.6309 225.083V205.35C35.6324 203.96 36.1854 202.628 37.1686 201.645C38.1517 200.663 39.4847 200.11 40.8751 200.108H93.9345C95.3249 200.11 96.6579 200.663 97.641 201.645C98.6242 202.628 99.1772 203.96 99.1788 205.35V225.083C99.1772 226.473 98.6242 227.805 97.641 228.788C96.6579 229.771 95.3249 230.324 93.9345 230.325Z"
          fill="#E6E6E6"/>
      <path
          d="M111.627 288.292H82.9375V269.175H111.627C114.163 269.175 116.595 270.182 118.389 271.975C120.182 273.767 121.19 276.198 121.19 278.733C121.19 281.268 120.182 283.7 118.389 285.492C116.595 287.285 114.163 288.292 111.627 288.292Z"
          fill="#CCCCCC"/>
      <path
          d="M68.4391 295.692H39.75V276.575H68.4391C70.9754 276.575 73.4078 277.582 75.2012 279.375C76.9946 281.167 78.0021 283.598 78.0021 286.133C78.0021 288.668 76.9946 291.1 75.2012 292.892C73.4078 294.685 70.9754 295.692 68.4391 295.692Z"
          fill="#CCCCCC"/>
      <path
          d="M97.4375 148.925H74.6096C74.0369 148.925 73.4876 148.698 73.0827 148.293C72.6777 147.888 72.4502 147.339 72.4502 146.767C72.4502 146.194 72.6777 145.645 73.0827 145.241C73.4876 144.836 74.0369 144.608 74.6096 144.608H97.4375C98.0102 144.608 98.5594 144.836 98.9644 145.241C99.3693 145.645 99.5969 146.194 99.5969 146.767C99.5969 147.339 99.3693 147.888 98.9644 148.293C98.5594 148.698 98.0102 148.925 97.4375 148.925Z"
          fill="white"/>
      <path
          d="M97.4375 156.633H74.6096C74.0369 156.633 73.4876 156.406 73.0827 156.001C72.6777 155.596 72.4502 155.047 72.4502 154.475C72.4502 153.903 72.6777 153.354 73.0827 152.949C73.4876 152.544 74.0369 152.317 74.6096 152.317H97.4375C98.0102 152.317 98.5594 152.544 98.9644 152.949C99.3693 153.354 99.5969 153.903 99.5969 154.475C99.5969 155.047 99.3693 155.596 98.9644 156.001C98.5594 156.406 98.0102 156.633 97.4375 156.633Z"
          fill="white"/>
      <path
          d="M136.333 296H1.21658C1.05295 296 0.896029 295.935 0.780325 295.819C0.66462 295.704 0.599609 295.547 0.599609 295.383C0.599609 295.22 0.66462 295.063 0.780325 294.947C0.896029 294.832 1.05295 294.767 1.21658 294.767H136.333C136.497 294.767 136.654 294.832 136.769 294.947C136.885 295.063 136.95 295.22 136.95 295.383C136.95 295.547 136.885 295.704 136.769 295.819C136.654 295.935 136.497 296 136.333 296Z"
          fill="#3F3D56"/>
      <path
          d="M180.728 124.567C186.861 124.567 191.833 119.597 191.833 113.467C191.833 107.336 186.861 102.367 180.728 102.367C174.594 102.367 169.622 107.336 169.622 113.467C169.622 119.597 174.594 124.567 180.728 124.567Z"
          fill="#E6E6E6"/>
      <path
          d="M184.121 158.175C183.139 158.174 182.198 157.784 181.504 157.09C180.81 156.396 180.42 155.456 180.419 154.475V21.275C180.419 20.2937 180.809 19.3525 181.503 18.6587C182.197 17.9648 183.139 17.575 184.121 17.575C185.103 17.575 186.044 17.9648 186.738 18.6587C187.433 19.3525 187.823 20.2937 187.823 21.275V154.475C187.821 155.456 187.431 156.396 186.737 157.09C186.043 157.784 185.102 158.174 184.121 158.175Z"
          fill="#3F3D56"/>
      <path
          d="M296.508 18.7097C293.257 15.8669 282.09 7.01774 267.683 5.40208C256.905 4.19958 244.313 7.04242 231.868 19.4868C221.886 29.4644 212.471 34.1943 203.883 33.5529C195.115 32.8931 189.285 26.6647 186.589 23.0079C186.4 22.7465 186.132 22.5527 185.824 22.455C185.516 22.3573 185.185 22.3609 184.88 22.4652C184.568 22.5631 184.296 22.7586 184.104 23.0229C183.912 23.2871 183.809 23.6062 183.813 23.9329V69.9733C183.813 70.2651 183.896 70.5508 184.053 70.7974C184.209 71.044 184.431 71.2414 184.695 71.3669C188.057 72.9579 200.168 78.2428 212.36 78.2428C219.51 78.2428 226.698 76.4236 232.152 70.9661C232.208 70.9106 232.257 70.8612 232.312 70.8119C241.974 61.4139 263.272 63.3441 278.814 66.4151C285.242 67.6854 290.684 69.1469 293.911 70.0842C294.324 70.2035 294.759 70.2251 295.182 70.1473C295.605 70.0696 296.004 69.8946 296.347 69.6362C296.691 69.3778 296.969 69.043 297.161 68.6585C297.353 68.2739 297.453 67.85 297.452 67.4202V20.8002C297.452 20.4048 297.368 20.0138 297.206 19.6533C297.043 19.2928 296.805 18.9712 296.508 18.7097Z"
          fill="#00AFF0"/>
      <path
          d="M321.396 149.85C324.463 149.85 326.949 147.365 326.949 144.3C326.949 141.235 324.463 138.75 321.396 138.75C318.33 138.75 315.844 141.235 315.844 144.3C315.844 147.365 318.33 149.85 321.396 149.85Z"
          fill="white"/>
      <path
          d="M311.524 210.9C314.591 210.9 317.077 208.415 317.077 205.35C317.077 202.285 314.591 199.8 311.524 199.8C308.458 199.8 305.972 202.285 305.972 205.35C305.972 208.415 308.458 210.9 311.524 210.9Z"
          fill="white"/>
      <path
          d="M293.632 172.667C298.744 172.667 302.887 168.525 302.887 163.417C302.887 158.308 298.744 154.167 293.632 154.167C288.521 154.167 284.378 158.308 284.378 163.417C284.378 168.525 288.521 172.667 293.632 172.667Z"
          fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_990_1437">
        <rect width="393.6" height="296" fill="white" transform="translate(0.599609)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "SuccessIcon"
}
</script>

<style lang="scss" scoped>
svg {
  @media (max-width: 768px) {
    width: 246px;
  }
}
</style>