<template>
  <div class="th-container library-container">
    <div class="library-tabs">
      <div @click="openTab($event,'libraryOpen', 'massSendOpen')" :class="{open: libraryOpen}" class="library-tab">
        <p>Library <span class="end-parameter">4</span></p>
      </div>
      <div @click="openTab($event,'massSendOpen', 'libraryOpen')" :class="{open: massSendOpen}" class="library-tab">
        <p>Mass Send </p>
      </div>
    </div>

    <div v-if="libraryOpen" class="library-tabs-step-two desktop">
      <div class="library-step-two-left-block">
        <div @click="openTab($event,'purchasesOpen', 'uploadsOpen')" :class="{open: purchasesOpen}" class="library-tab">
          <p>Purchases</p>
        </div>
        <div @click="openTab($event,'uploadsOpen', 'purchasesOpen')" :class="{open: uploadsOpen}" class="library-tab">
          <p>Uploads</p>
        </div>
        <div class="search-block">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
          >
            <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.5 17.5L13.875 13.875"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <base-input
              :input-type="'text'"
              :placeholder="'Search content'"
          />
        </div>
      </div>
      <div class="library-step-two-right-block">
        <div class="library-filter-block position-relative">
          <p class="library-filter-block-text">SORT BY</p>
          <div class="filter-dropdown">
            <base-select
                :options="filterOptions"
                :selected="selectedFilter"
                :text="selectedFilter"
                :no-show-seleceted="true"
                class="select"
                @selected="optionSelected($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="libraryOpen" class="library-tabs-step-two mobile">
      <div class="library-step-two-left-block">
        <div @click="openTab($event,'purchasesOpen', 'uploadsOpen')" :class="{open: purchasesOpen}" class="library-tab">
          <p>Purchases</p>
        </div>
        <div @click="openTab($event,'uploadsOpen', 'purchasesOpen')" :class="{open: uploadsOpen}" class="library-tab">
          <p>Uploads</p>
        </div>

      </div>
      <div class="library-step-two-right-block">
        <div class="search-block">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
          >
            <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.5 17.5L13.875 13.875"
                stroke="#D9E1E4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <base-input
              :input-type="'text'"
              :placeholder="'Search content'"
          />
        </div>
        <div class="library-filter-block position-relative">
          <p class="library-filter-block-text">SORT BY</p>
          <div class="filter-dropdown">
            <base-select
                :options="filterOptions"
                :selected="selectedFilter"
                :text="selectedFilter"
                :no-show-seleceted="true"
                class="select"
                @selected="optionSelected($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="purchasesOpen && libraryOpen" class="products-main purchases">
      <div class="products-block" :key="index" v-for="(product, index) in products">
        <div class="products-block-main">
          <p>{{ product.name }}</p>
          <div class="products-block-buttons">
            <base-button
                class="primary mb-8"
            >
              Download
            </base-button>
            <base-button
                class="secondary w-100"
            >
              View
            </base-button>
          </div>
        </div>

      </div>
    </div>

    <div v-if="uploadsOpen && libraryOpen" class="products-main">
      <div class="products-block" :key="index" v-for="(product, index) in products">
        <div class="products-block-main">
          <p>{{ product.name }}</p>
          <base-button
              class="primary"
          >
            $1100 Sold
          </base-button>
        </div>

      </div>
    </div>

    <div v-if="massSendOpen">
      <div class="choose-products-to-send">
        <div class="title">
          <p>Choose product(s) to send</p>
        </div>

        <library-slider
            class="desktop"
            v-if="products.length"
            :data="products"
            :sliders-to-show="librarySliderShow"
        />

        <library-slider
            class="mobile"
            v-if="products.length"
            :data="products"
            :sliders-to-show="1"
        />

        <div class="slider-footer">
          <div class="library-step-two-right-block">
            <div class="library-filter-block position-relative">
              <p class="library-filter-block-text">SORT BY</p>
              <div class="filter-dropdown">
                <base-select
                    :options="filterOptions"
                    :selected="selectedFilter"
                    :text="selectedFilter"
                    :no-show-seleceted="true"
                    class="select"
                    @selected="optionSelected($event)"
                />
              </div>
            </div>
          </div>

          <div class="search-block">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
              <path
                  d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                  stroke="#D9E1E4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
              <path
                  d="M17.5 17.5L13.875 13.875"
                  stroke="#D9E1E4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
            <base-input
                :input-type="'text'"
                :placeholder="'Search content'"
            />
          </div>

          <div class="count-block">
            <p>1/5</p>
          </div>

        </div>

      </div>

      <div class="choose-favorite-users">
        <send-to-favorites-slider
            class="desktop"
            :sliders-to-show="7"
        />
        <send-to-favorites-slider
            class="mobile"
            :sliders-to-show="3"
        />
      </div>

    </div>

  </div>
</template>

<script>

import './LibraryPage.scss'
import LibrarySlider from './LibrarySlider/LibrarySlider'
import BaseInput from '../../UI/BaseInput.vue'
import BaseSelect from '../../UI/BaseSelect.vue'
import BaseButton from '../../UI/BaseButton.vue'
import SendToFavoritesSlider from './SendToFavoritesSlider/SendToFavoritesSlider';


export default {
  name: "LibraryPage",
  data() {
    return {
      filterOptions: ['NEWEST', 'OLDEST', 'BEST SELLING'],
      products: [
        {
          name: 'Product One'
        },
        {
          name: 'Product Two'
        },
        {
          name: 'Product Three'
        },
        {
          name: 'Product Four'
        },
        {
          name: 'Product Four'
        },
        {
          name: 'Product Four'
        },
        {
          name: 'Product Four'
        },
      ],
      selectedFilter: 'NEWEST',
      massSendOpen: false,
      libraryOpen: true,
      purchasesOpen: true,
      uploadsOpen: false,
      librarySliderShow: 3
    }
  },
  components: {
    'base-select': BaseSelect,
    'base-input': BaseInput,
    'base-button': BaseButton,
    'library-slider': LibrarySlider,
    'send-to-favorites-slider': SendToFavoritesSlider,
  },
  methods: {
    optionSelected(e) {
      this.selectedFilter = e;
    },
    openTab(e, openTab, closeTab) {
      if (!e.target.classList.contains('open')) {
        this[openTab] = true;
        this[closeTab] = false;
      }
    }
  },

}
</script>