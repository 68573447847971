<template>
    <div class="wishPurchase">
        <BaseModalBuy class="avatar-ext" @close="$emit('close')" :padding="'20px'">
            <template v-slot:avatar>
                <img class="avatar" :src="user.avatar" alt=""/>
            </template>
            <template v-slot:body>
                <div class="wishPurchase__wrapper">
                    <span class="username">{{ user.name }}</span>
                    <BuyGiftInfo :product="product"/>
                    <BuyForm/>
                </div>
            </template>
        </BaseModalBuy>
    </div>
</template>

<script>
import BaseModalBuy from "@/router/UI/BaseModalBuy.vue";
import BuyGiftInfo from "./BuyGiftInfo.vue";
import BuyForm from "./BuyForm.vue";

export default {
    name: "wishPurchase",
    props: {
        user: {
            type: Object,
            required: false,
            default: () => ({
                name: "@Username",
                avatar: require("@/assets/images/buy/avatar.png"),
            }),
        },
        product: {
            type: Object,
            default: () => ({
                name: "Product Name",
                price: "123",
            }),
        },
    },
    components: {
        BaseModalBuy,
        BuyGiftInfo,
        BuyForm,
    },
};
</script>

<style lang="scss" scoped>
.wishPurchase__image {
  z-index: 5;
  width: 100%;
  position: absolute;
  top: -100px;
}

.avatar {
  position: absolute;
  top: -100px;
  width: 148px;
  height: 148px;
  border-radius: 50%;
}

.wishPurchase__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 65px;
}

.wishPurchase__image {
  margin-bottom: 7px;
}

.username {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  /* identical to box height, or 167% */

  text-align: center;

  /* BLACK */

  color: #0b254e;
}

.price {
  font-size: 13px;
  line-height: 40px;
  /* identical to box height, or 308% */

  text-align: center;

  /* BLACK */

  color: #0b254e;
}
</style>
