<template>
    <div class="rounded_checkbox">
        <label>
            <span class="round" :class="{active: modelValue}"></span>
            <input type="checkbox" :value="modelValue" @change="$emit('update:modelValue', !modelValue)"
                   :checked="modelValue">
            <span class="label">{{ label }}</span>
        </label>

    </div>
</template>

<script>
export default {
    name: "CheckboxRounded",
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
    }
}
</script>

<style lang="scss" scoped>
label {
  display: flex;
  align-items: center;
  gap: 9px;

  .round {
    display: inline-block;
    min-width: 23px;
    min-height: 23px;
    background: #F3F3F3;
    border-radius: 12px;
    transition: all .3s ease;
      @media (max-width: 480px) {
          min-width: 19px;
          min-height: 19px;
      }

    &.active {
      background: #00AFF0;
    }
  }

  input {
    display: none;
  }

  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #0B254E;
      @media (max-width: 480px) {
          font-size: 10px;
          line-height: 14px;
      }

  }
}
</style>