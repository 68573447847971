<template>
  <base-modal
      @close="$emit('close', true)"
      :width="'480px'"
      :height="'280px'"
  >
    <template v-slot:body>
      <div class="d-flex flex-column align-items-center gap-30 modal-body-main">
        <div class="title">
          <p>Delete Account</p>
        </div>

        <div class="text">
          <p>
            Are you sure you want to delete your account?
          </p>
        </div>

        <div class="buttons">
          <base-button
              class="secondary"
              @click="confirmResult(false)"
          >
              Cancel
          </base-button>

          <base-button
              class="danger"
              @click="confirmResult(true)"
          >
              Delete
          </base-button>
        </div>

      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../UI/BaseModal";
import BaseButton from "../UI/BaseButton";

export default {
  name: "ConfirmModal",
  components: {
    'base-modal': BaseModal,
    'base-button': BaseButton,
  },
  props: {
    value: {
      required: false
    },
    receiver: {
      required: false
    },
  },
  methods: {
    confirmResult(boolean) {
      this.$emit('result', boolean)
      this.$emit('close', true)
    }
  }
}
</script>

<style scoped lang="scss">

.title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0B254E;
}

.text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0B254E;
}

.buttons{
  display: flex;
  padding: 40px;
  gap: 10px;
  width: 100%;
  button{
    width: 100%;
  }
}

</style>