<template>
  <div class="restricted_gifts_container">
    <div class="th-container">
      <h2>Privacy Policy</h2>

      <p>Welcome to Giftxyz and our website at www.Giftxyz.com . For your current and any further visits to our website
        and use of our services, we would like to inform you about data collection, processing and use when visiting and
        using our website and service.</p>

      <p>This Privacy Policy describes our privacy practices in plain language, keeping legal and technical jargon to a
        minimum, to make sure you understand the information provided. However, to achieve this objective we would like
        to explain you the following two concepts.</p>

      <p>What is Personal Data?</p>
      <p>"Personal Data" means any information relating to an identified or identifiable natural person (hereinafter
        "data subject"); an identifiable natural person is one who can be identified, directly or indirectly, in
        particular by reference to an identifier such as a name, an identification number, location data, an online
        identifier (e.g. cookie or an IP Address) or to one or more factors specific to the physical, physiological,
        genetic, mental, economic, cultural or social identity of that natural person.</p>

      <p>What is Processing?</p>
      <p>"Processing" means any operation or set of operations which is performed upon Personal Data, whether or not by
        automatic means. The term is broad and covers virtually any handling of data.</p>

      <p>In principle, we will only use your Personal Data in accordance with the New York Privacy Act (“NYPA”), the
        General Data Protection Regulation (“GDPR”), and only as described in this Privacy Policy.</p>

      <p>The Person responsible (“Data Controller”)</p>
      <p>The person responsible for the processing of Personal Data is:</p>

      <p>Giftxyz</p>
      <p>414 S Service Road, Patchogue 11772</p>
      <p>New York, USA.</p>

      <p>If you have any questions about the processing of your Personal Data, please contact us.</p>

      <p>Purpose and legal basis of processing</p>
      <p>In accordance with the NYPA and the GDPR we need to have both a purpose and a legal basis to process Personal
        Data. The purposes are:</p>

      <ul>
        <li>the provision of the website and services and their functions and contents,</li>
        <li>responding to contact requests and communicating with users,</li>
        <li>providing our services, and</li>
        <li>security measures.</li>
      </ul>

      <p>Of course, we can only do that if we have at least one of the following legal bases or in other words lawful
        reasons to do so. Unless specifically described below, we typically link the above purposes to one of the
        following:</p>

      <ul>
        <li>Consent: the individual has given clear consent to process Personal Data for a specific purpose.</li>
        <li>Contract: the processing is necessary for a contract or because you have asked us to take specific steps
          before entering into a contract.
        </li>
        <li>Legal obligation: the processing is necessary for us to comply with the law (not including contractual
          obligations).
        </li>
        <li>Legitimate interests: the processing is necessary for our legitimate interests or the legitimate interests
          of a third party, unless there is a good reason to protect your Personal Data which overrides those legitimate
          interests.
        </li>
      </ul>

      <p>General Principles</p>

      <p>a) Security</p>
      <p>Our website uses SSL or TLS encryption to ensure the security of data processing and to protect the transmission
      of confidential content, such as orders, login data or contact requests that you send to us.</p>

      <p>You can recognise an encrypted connection if the address line of your browser contains a "https://" instead of a
      "http://" and also has a lock symbol. If SSL or TLS encryption is activated, the data you transmit to us cannot be
      read by third parties.</p>

      <p>We have also implemented numerous security measures (“technical and organizational measures”) to ensure the most
      complete protection of Personal Data processed through this website.</p>

      <p>Nevertheless, internet-based data transmissions can always have security gaps, so that absolute protection cannot
      be guaranteed. And databases or data sets that include Personal Data may be breached inadvertently or through
      wrongful intrusion.</p>

      <p>Upon becoming aware of a data breach, we will notify all affected individuals whose Personal Data may have been
      compromised, and the notice will be accompanied by a description of the action being taken to reconcile any damage
      as a result of the data breach. Notices will be provided as expeditiously as possible after which the breach was
      discovered.</p>

      <p>b) Retention and Storage
      We will retain your Personal Data as necessary in connection with the purposes described in this Privacy Policy,
      and in accordance with New York`s Statutory Retention Periods for up to 3 years.</p>

      <p>c) Minors</p>
      <p>Persons under the age of 16 should not transmit any Personal Data to us without the consent of their parents or
      legal guardians. We do not request Personal Data from minors and children and do not knowingly collect such data
      or pass it on to third parties.</p>

      <p>d) Automated decision-making</p>
      <p>Automated decision-making including profiling does not take place.</p>

      <p>e) Do Not Sell</p>
      <p>We do not sell your Personal Data.</p>

      <p>f) Special Category Data</p>
      <p>Unless specifically required and consent is obtained, for a particular service, we do not process Special Category
      Data.</p>

      <p>g) Social Media</p>
      <p>We are present on social media on the basis of our legitimate interest. If you contact us via social media
      platforms, we and the relevant social media platform are jointly responsible for the processing of your data and
      enter into a so-called joint controller agreement. The legal basis for the use of the relevant social media
      platform is our legitimate interest, your consent or, in the case of a (pre) contractual relationship with us, the
      initiation of a contractual service, if any.</p>

      <p>h) International Transfer</p>
      <p>In the course of our website operation, we process data. We usually do not transfer Personal Data to countries
      outside the USA. However, if we do, we ensure that processing of your Personal Data is governed by Processing
      Agreements that include Standard Contractual Clauses to ensure a high level of data protection.</p>

      <p>i) Sharing and Disclosure</p>
      <p>We will not disclose or otherwise distribute your Personal Data to third parties unless this is a) necessary for
      the performance of our services for example with our web host (see below), b) you have consented to the disclosure</p>
      <p>c) or if we are legally obliged to do so e.g., by court order or if this is necessary to support criminal or legal
      investigations or other legal investigations or proceedings at home or abroad or to fulfil our legitimate
      interests.</p>

      <p>Processing of Personal Data by us</p>
      <p>a) Log files</p>
      <p>Each time a user accesses our website and each time a file is retrieved, data about this process is temporarily
      processed in a log file. In detail, the following data is stored for each access/retrieval: a) Date and time of
      the retrieval (time stamp), as well as the IP address of the accessing device or server,</p>
      <p>b) request details and destination address (protocol version, HTTP method, referrer, User Agent string),</p>
      <p>c) name of the retrieved file and transferred data volume (requested URL incl. query string, size in bytes) and d)
      Message as to whether the retrieval was successful (HTTP status code).</p>

      <p>On the basis of our legitimate interest in a secure website, we store this data to protect against attacks for up
      to 7 days beyond the time of your visit. This data is analyzed and required for legal and criminal prosecution in
      the event of attacks on communications technology. The data is deleted as soon as it is no longer required for the
      performance of tasks.</p>

      <p>b) Cookies</p>
      <p>We use so-called cookies on our web site. Cookies are small text files that are stored on your respective device
      (PC, smartphone, tablet, etc.) and saved by your browser. The legal basis for the use of cookies is your consent
      as well as our legitimate interest.</p>

      <p>c) Hosting</p>
      <p>To provide our website, we use the services of ChemiCloud (CChosting, Inc.) who processes all data to be processed
      in connection with the operation of this website on our behalf. The legal basis is our legitimate interest.</p>

      <p>d) Contacting us</p>
      <p>Personal Data is processed depending on the contact method. In addition to your name and e-mail address, IP
      address or telephone number, we usually collect the context of your message which may also include certain
      Personal Data. The Personal Data collected when contacting us is processed for the purpose of dealing with your
      request and the legal basis is your consent. The use of your IP address takes place exclusively in the context of
      law enforcement and security measures in compliance with our legal requirements.</p>

      <p>e) Data processing in the context of providing our services</p>
      <p>The protection of your data is particularly important to us in the performance of our services. We therefore only
      want to process as much Personal Data (for example, your name, address, e-mail address or telephone number) as is
      absolutely necessary. Nevertheless, we rely on the processing of certain Personal Data, to fulfil our contractual
      obligations to you or to carry out pre-contractual measures.</p>

      <p>f) Account Registration</p>
      <p>If you register, we will request mandatory and, where applicable, non-mandatory data in accordance with our
      registration form (Full Name, Email Address, Account Type, Gender, Username and chosen Password). The entry of
      your data is encrypted so that third parties cannot read your data when it is entered. The basis for this storage
      is our legitimate interest in communicating with registered users and, in the case of contracts, also the storage
      of contract data.</p>

      <p>g) When using our services</p>

      <p>We process the data of our registered users in order to be able to provide our contractual services as well as to
      ensure the security of our services and to be able to develop it further. This includes in particular our support,
      correspondence with you, invoicing, fulfilment of our accounting and tax obligations. Accordingly, the data is
      processed on the basis of fulfilling our contractual obligations as well as to fulfil our legal obligations.</p>

      <p>The legal basis for the data processing is the fulfillment of our contractual obligations and, in individual
      cases, the fulfillment of our legal obligations as well as your consent.</p>

      <p>Some of the data you choose to provide may be considered non-Personal Data and/or “special” or “sensitive” in
      certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. By
      choosing to provide this data, you consent to our processing of that data.</p>

      <p>Where any Personal Data relates to a third party, you represent and warrant that the Personal Data is up-to-date,
      complete, and accurate and that you have obtained the third party’s prior consent for our collection, use and
      disclosure of their Personal Data for the Purposes. You agree that you shall promptly provide us with written
      evidence of such consent upon demand by us.</p>

      <p>Unless otherwise specified the purposes of processing are contractual performance and service, contact requests
      and communication, office and organizational procedures, administration, and response to requests, visit action
      evaluation. The legal basis for the data processing is the fulfilment of our contractual obligations and, in
      individual cases, the fulfilment of our legal obligations as well as your Consent.</p>

      <p>You may withdraw your consent and request us to stop using and/or disclosing your personal and special category
      data by submitting your request to us in writing.</p>

      <p>Please note when using our services, you become the data controller and we become the data processor in accordance
      with the NYPA and GDPR. Where we process your Personal Data as a data intermediary on behalf of you, we will
      process the Personal Data involved in accordance with your instructions and shall use it only for the purposes
      agreed between you and us, for further information please refer to our Data Processing Addendum.</p>

      <p>h) Payment Processor</p>
      <p>When you make or receive payment or connect your Stripe account, your payment related data will be processed via
      the payment service provider Stripe. Payment data will solely be processed through Stripe, and we have no access
      to any Payment Data you may submit. The legal basis for the provision of a payment system is the establishment and
      implementation of the user contract for the use of the service.</p>

      <p>i) Administration, financial accounting, office organization, contact management</p>
      <p>We process data in the context of administrative tasks as well as organization of our business, and compliance
      with legal obligations, such as archiving. In this regard, we process the same data that we process in the course
      of providing our contractual services. The processing bases are our legal obligations and our legitimate interest.</p>

      <p>j) Service Notifications</p>
      <p>By using our services, you are giving your consent to receiving notifications and messages per email. Those
      typically include general, profile and content information in relation to your use of our Services. You can of
      course opt out from receiving notifications by following the unsubscribe instructions at the bottom of every
      notification e-mail sent by us. The legal bases are to provide you with our services and your consent as well as
      our legitimate interest.</p>

      <p>Marketing</p>

      <p>Insofar as you have also given us your consent to process your Personal Data for marketing and advertising
      purposes, we are entitled to contact you for these purposes via the communication channels you have given your
      consent to. Our Marketing generally takes the form of e-mail but may also include other less traditional or
      emerging channels. Every directly addressed marketing sent by us or on our behalf will include a means by which
      you may unsubscribe or opt out.</p>

      <p>Third-party services and content</p>
      <p>We use content or service offers of third-party providers on the basis of our legitimate interests in order to
      integrate their content and services. This always requires that the third-party providers of this content are
      aware of your IP address, as without the IP address they would not be able to send the content to your browser. We
      use the following providers and would like to ask you to review their privacy policies and which contain further
      information on the processing of Personal Data and so-called opt-out measures, if any:</p>

      <ul>
        <li>Analytics and Tracking: Google Analytics by Google LLC</li>
        <li>Fonts: Google Font API by Google and Font Awesome by Fonticons Inc.</li>
      </ul>

      <p>Your Rights and Privileges</p>
      <p>a) Privacy rights</p>
      <p>Under the NYPA, you have the following rights:</p>
      <ul>
        <li>Right to notice</li>
        <li>Right to opt-in consent</li>
        <li>Right to access, correct data</li>
        <li>Right to delete</li>
      </ul>

      <ul>
        <li>Right to information</li>
        <li>Right to rectification</li>
        <li>Right to object to processing</li>
        <li>Right to deletion</li>
        <li>Right to data portability</li>
        <li>Right of objection</li>
        <li>Right to withdraw consent</li>
        <li>Right to complain to a supervisory authority</li>
        <li>Right not to be subject to a decision based solely on automated processing.</li>
      </ul>

      <p>Under the GDPR, you have the following rights:</p>

      <p>If you have any questions about the nature of the Personal Data we hold about you, or if you wish to request
        the erasure or rectification of Personal Data we hold about you, or to exercise any of your other rights as a
        data subject, please contact us.</p>

      <p>b) Updating your information</p>
      <p>If you believe that the information, we hold about you is inaccurate or that we are no longer entitled to use it
      and want to request its rectification, deletion, or object to its processing, please do so by contacting us.</p>

      <p>c) Withdrawing your consent</p>
      <p>You can revoke consents you have given at any time by contacting us. The legality of the data processing carried
      out until the revocation remains unaffected by the revocation.</p>

      <p>d) Access Request</p>
      <p>In the event that you wish to make a Data Subject Access Request, you may inform us in writing of the same. We
      will respond to requests regarding access and correction as soon as reasonably possible. Should we not be able to
      respond to your request within thirty (30) days after receiving your request,</p>

      <p>we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your
      request. If we are unable to provide you with any Personal Data or to make a correction requested by you, we shall
      generally inform you of the reasons why we are unable to do so (except where we are not required to do so under
      the respective legal regulations mentioned above).</p>

      <p>e) Complaint to a supervisory authority</p>
      <p>If the GDPR applies to you, you have the right to complain about our processing of Personal Data to a supervisory
      authority responsible for data protection.</p>

      <p>Changes and Questions</p>
      <p>We may update this Privacy Policy from time to time. If we make changes to this Privacy Policy or materially
        change our use of your Personal Data, we will revise the Privacy Policy accordingly and also change the
        effective date at the end of this section. We encourage you to periodically review this Privacy Policy to be
        informed of how we use and protect your Personal Data. If you have any questions about the processing of your
        Personal Data, please contact us.</p>

      <p>Effective Date</p>
      <p>Tuesday, January 31, 2023</p>

    </div>
  </div>
</template>

<script>
import preloader from "@/mixins/preloader";

export default {
  name: "RestrictedGifts",
  mixins: [preloader],
  mounted() {
    this.setLoading(false);
  },
}
</script>

<style lang="scss" scoped>
.restricted_gifts_container {
  padding: 76px 0;

  h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    color: #0B254E;
    text-align: center;
    margin: 53px auto 31px;
  }

  h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #0B254E;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0B254E;
    opacity: 0.5;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0B254E;
    opacity: 0.5;
    margin-bottom: 15px;
    white-space: pre-wrap;
  }
}
</style>