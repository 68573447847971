<template>
  <div v-if="user.role_id === 1 && !user.stripe_account_is_verified" class="connect_stripe_popup">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Social, Media/stripe-big">
        <g id="Group">
          <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M10.7002 8.4C10.7002 7.6 11.3002 7.3 12.4002 7.3C13.7002 7.3 15.2002 7.6 16.6002 8.2C16.9002 8.3 17.3002 8.1 17.3002 7.7V4.3C17.3002 4.1 17.2002 3.9 17.0002 3.8C15.5002 3.2 14.0002 3 12.4002 3C8.4002 3 5.7002 5.1 5.7002 8.6C5.7002 14.1 12.8002 13.2 12.8002 15.6C12.8002 16.6 12.4002 16.8 11.3002 16.8C9.9002 16.8 8.1002 16.3 6.6002 15.6C6.3002 15.4 6.0002 15.6 6.0002 16V19.6C6.0002 19.8 6.1002 20 6.3002 20.1C8.0002 20.8 9.8002 21.1 11.4002 21.1C15.5002 21.1 18.3002 19.1 18.3002 15.5C18.2002 9.5 10.7002 10.6 10.7002 8.4Z" stroke="#0B254E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </g>
    </svg>
    <h3>Set up stripe to receive funds</h3>
    <button @click="connectStripe">
      Connect Stripe
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrows, Diagrams/Arrow">
          <g id="Group">
            <path id="Path" d="M6.66675 10.6667L9.33341 8.00004L6.66675 5.33337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </g>
      </svg>
    </button>
    <div class="dont_show" @click="dontShow">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols/close-big" opacity="0.5">
          <g id="Group">
            <path id="Path" d="M3.11475 3.11462L9.88558 9.88546" stroke="#0B254E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Path_2" d="M3.11475 9.88546L9.88558 3.11462" stroke="#0B254E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </g>
      </svg>
      Don’t show this again
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import preloader from "@/mixins/preloader";

export default {
  name: "ConnectStripe",
  mixins: [preloader],
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    connectStripe() {
      this.setLoading(true)
      this.axios.post('/connect-stripe').then((response) => {
        if(response.data.success) {
          window.location.href = response.data.link
        } else {
          this.setLoading(false)
          this.toast.error(response.data.message)
        }
      }).catch((error) => {
        this.setLoading(false)
        this.toast.error(error.response.data.message)
      })
    },
    dontShow(){
      localStorage.setItem('dontShowConnectStripe', 'true')
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.connect_stripe_popup {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    padding: 16px 0 9px;
  }

  h3 {
    color: #0B254E;
    font-size: 16.27px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.64px;
    @media screen and (max-width: 768px) {
      font-size: 16.27px;
      line-height: 31.64px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7.657px;
    background: #00AFF0;
    width: 137px;
    height: 28px;
    padding: 11.485px 0;
    color: #FFF;
    font-size: 11px;
    font-weight: 700;
    line-height: 22.971px;
    margin: 8px auto 0;
  }

  .dont_show {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #0B254E;
    font-size: 10px;
    font-weight: 500;
    line-height: 31.64px;
    cursor: pointer;
    margin-top: 13px;
  }
}
</style>