<template>
    <div class="uploadWish">
        <BaseModalBuy
                class="uploadWish"
                @close="$emit('close')"
                :padding="'13px 14px'"
        >
            <template v-slot:body>
                <div class="container d-flex flex-column align-items-center modal-body-main">
                    <InputLabel label="Price" v-model="wish.price" :price="true"/>
                    <InputLabel label="Name" v-model="wish.name"/>
                    <TextareaLabel label="Description" v-model="wish.description"/>
                    <BuyFileInput
                            label="Upload Image"
                            :product-price="wish.price"
                            :product-name="wish.name"
                            @image="getImage"
                    />
                    <button @click="createWish" class="uploadWish__btn primary">Create</button>
                </div>
            </template>
        </BaseModalBuy>
    </div>
</template>

<script>
import BaseModalBuy from "../../UI/BaseModalBuy.vue";
import BuyFileInput from "../../UI/buy/BuyFileInput.vue";
import {mapActions} from "vuex";
import InputLabel from "@/router/components/form/InputLabel.vue";
import TextareaLabel from "@/router/components/form/TextareaLabel.vue";

export default {
    name: "UploadWish",
    components: {
        TextareaLabel,
        InputLabel,
        BaseModalBuy,
        BuyFileInput,
    },
    data() {
        return {
            wish: {
                name: "",
                price: "",
                description: "",
                image: null,
            }
        }
    },
    methods: {
        ...mapActions({
            updateUser: "auth/login",
        }),
        createWish() {
            this.axios.post('wishlists/create', this.wish, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            ).then(() => {
                this.$emit('close')
                // this.$emit('selected', 'complete')
                this.toast.info('Wish successfully created')
                this.updateUser()
            }).catch((res) => {
                this.toast.error(res.response.data.message);
            }).finally(() => {
                this.dialog = false;
            })
        },
        getImage(image) {
            this.wish.image = image
        }
    }
};
</script>

<style lang="scss" scoped>
label {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 21px;

  span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b254e;
    margin-bottom: 6px;
  }
}

input {
  background: #eeeeee;
  border-radius: 12px;
  min-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
}

.labelPrice {
  margin-bottom: 24px;

  input {
    padding-left: 50px;
  }

  span {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 43px;
      height: 45px;
      padding: 10px 12px 11px 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      background: url("../../../assets/images/$.svg") no-repeat center center;
      color: #0b254e;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 12px;
    }
  }
}

textarea {
  background: #eeeeee;
  border-radius: 12px;
  padding: 5px;
  height: 176px;
}

.container {
  padding: 23px 2px;
}

.file {
  span {
    text-align: center;
    margin-bottom: 13px;
  }

  input {
    display: none;
  }

  span.file__button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #00aff0;
    cursor: pointer;
    padding: 12px;
    border: 1px solid #00aff0;
    border-radius: 8px;
    display: block;
  }
}

.uploadWish__btn {
  width: 100%;
  margin-top: 15px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}
</style>
