<template>
  <div class="reset-pass-container">
    <RegLeftBar/>
    <div class="form-section">
      <div class="reset-pass-form">
        <h1 class="title">Reset password</h1>
        <div class="form-input">
          <base-input
              @input="typePassword"
              :input-type="'password'"
              :input-name="'password'"
              :label-name="'New password'"
              :label-class="'mb-12'"
          />
        </div>
        <base-button
            class="primary"
            @click.prevent="sendCode"
        >
          Continue
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "../../UI/BaseInput";
import BaseButton from "../../UI/BaseButton";
import preloader from "@/mixins/preloader";
import helper from "@/mixins/helper";

export default {
  name: 'ResetPassword',
  mixins: [helper, preloader],
  data() {
    return {
      email: null,
      token: null,
      password: null
    }
  },
  components: {
    'base-input': BaseInput,
    'base-button': BaseButton
  },
  mounted() {
    this.email = this.$route.query.email
    this.token = this.$route.query.token

    if (!this.email || !this.token) {
      this.$router.push({name: 'home'})
    }

    this.$router.replace({query: {}})
    this.setLoading(false)
  },
  methods: {
    typePassword(e) {
      this.password = e.target.value
    },
    sendCode() {
      this.axios.post('reset-password', {
        password: this.password,
        email: this.email,
        token: this.token
      }).then((res) => {
        this.toast.info(res.data.message);
        this.password = null
        this.$router.push({name: 'home'})
      }).catch((error) => {
        this.toast.info(error.response.data);
      });
    }
  }
}
</script>

<script setup>
import './ResetPassword.scss'
import RegLeftBar from '@/router/components/RegLeftBar/RegLeftBar.vue';

</script>