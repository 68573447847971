<template>
  <div class="notifications_item"
       :class="{active: ((notification.notify_id === 1 || notification.notify_id === 3) && notification.opened === 0) || (notification.notify_id === 2 && notification.status === 0)}">
    <div class="left">
      <router-link v-if="notification.from_user_id"
                   :to="{name: 'user-profile', params: {username: notification.from_user.username}}"
                   class="image_block">
        <div v-if="notification.status === 0" class="is_online"></div>
        <img v-if="notification.from_user?.avatar" class="avatar" :src="notification.from_user.avatar" alt="avatar"/>
        <img v-else class="avatar" src="@assets/images/user-default.png" alt="avatar"/>
      </router-link>
      <div v-else class="image_block">
        <div v-if="notification.status === 0" class="is_online"></div>
        <img v-if="notification.from_user?.avatar" class="avatar" :src="notification.from_user.avatar" alt="avatar"/>
        <img v-else class="avatar" src="@assets/images/user-default.png" alt="avatar"/>
      </div>
      <div>
        <div class="top">
          <router-link v-if="notification.from_user_id"
                       :to="{name: 'user-profile', params: {username: notification.from_user.username}}"
                       class="username">@{{ notification.from_user?.username }}
          </router-link>
          <span v-else class="username">Guest</span>
          <span class="date">{{ date }}</span>
        </div>
        <p class="purchased">{{ message }}</p>
      </div>
    </div>
    <div class="right">
      <div v-if="notification.notify_id === 1 || notification.notify_id === 3" class="open_order"
           @click="$emit('select', notification)">
        <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.4167 2L24 7.5M24 7.5L19.4167 13M24 7.5H2" stroke="#0B254E" stroke-width="3"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "NotificationItem",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    date() {
      return moment(this.notification.created_at).fromNow()
    },
    message() {
      let message
      if (this.notification.notify_id === 1) {
        message = 'New Granted Wish!'
      } else if (this.notification.notify_id === 2) {
        message = 'Started following you'
      } else if (this.notification.notify_id === 3) {
        message = 'You bought a gift'
      }
      return message
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications_item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(11, 37, 78, 0.2);
  border-radius: 12px;
  padding: 0 27px 0 74px;
  min-height: 88px;
  width: 90%;
  margin-left: auto;
  opacity: .5;
  @media (max-width: 768px) {
    min-height: 77px;
    padding: 0 18px 0 55px;
    border: none;
  }

  &.active {
    opacity: 1;
  }

  .left {
    display: flex;
    align-items: center;

    .image_block {
      position: absolute;
      left: -10%;
      top: -7px;
      width: 102px;
      height: 102px;
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        width: 77px;
        height: 77px;
        top: 0;
      }

      .is_online {
        position: absolute;
        top: 3px;
        left: 5px;
        width: 22.5px;
        height: 22.5px;
        background: #00AFF0;
        border-radius: 50%;
        border: 1.63638px solid #FFFFFF;
        @media (max-width: 768px) {
          top: 2px;
          left: 2px;
          width: 15px;
          height: 15px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        @media (max-width: 768px) {
          width: 77px;
          height: 77px;
        }
      }
    }

    .top {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        margin-bottom: 0;
      }

      .username {
        font-weight: 700;
        font-size: 18px;
        color: #0B254E;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .date {
        font-weight: 700;
        font-size: 13.068px;
        color: #0B254E;
        opacity: 0.3;
        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 22px;
        }
      }
    }

    .purchased {
      font-weight: 500;
      font-size: 15px;
      color: #0B254E;
      opacity: 0.8;
      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 20px;
      }
    }
  }

  .right {
    .open_order {
      cursor: pointer;

      svg {
        @media screen and (max-width: 768px) {
          width: 18px;
        }
      }
    }
  }
}
</style>