<template>
    <div class="checkbox" :class="{checkout: checkout}">
        <div class="head">
            <label :for="id" :class="{active: modelValue}">
                <input type="checkbox" :name="id" :id="id" :value="modelValue"
                       @change="$emit('update:modelValue', !modelValue)" :checked="modelValue">
            </label>
            <p v-if="smallLabel" v-html="smallLabel" class="small_label" :class="{bold: bold}"></p>
            <p v-else v-html="label" :class="{bold: bold}"></p>
        </div>
        <p v-if="description" class="description">{{ description }}</p>
        <ul v-if="list">
            <li v-for="item in list" :key="item" v-html="item"></li>
        </ul>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Checkbox",
    props: {
        modelValue: {
            type: Boolean,
            default: true,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        smallLabel: {
            type: String,
        },
        bold: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        list: {
            type: Array
        },
        checkout: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 19px;

  &.checkout {
    p {
      color: rgb(11 37 78 / 80%);
    }

    .description {
      color: rgb(11 37 78 / 50%);
    }
  }

  .head {
    display: flex;
    align-items: center;
    flex-basis: 100%;

    label {
      min-width: 30px;
      min-height: 30px;
      background: #F3F3F3;
      border-radius: 8px;
      margin-right: 7px;
      transition: all .3s ease;

      input {
        display: none;
      }

      &.active {
        background: #00AFF0;
      }
    }

    .small_label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 32px;
    color: #0B254E;

    &.bold {
      font-weight: 700;
    }
  }

  .description {
    margin: -5px 0 0 37px;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    color: #0B254E;
  }

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      font-weight: 400;
      font-size: 11px;
      line-height: 24px;
      color: #0B254E;
      margin-left: 37px;
    }
  }
}
</style>

<style>
.small_label a {
    color: #00aff0;
}

.checkout .small_label a {
    color: #0B254E;
    font-weight: bold;
}
</style>