<template>
    <div class="tabs-container main_tabs wishlist-products content-products" :class="{big: big}">
        <ul class="nav-tabs">
            <li
                    class="nav-item"
                    v-for="tab in tabs"
                    :key="tab"
                    @click="$emit('select',tab)"
                    :class="{ selected_item: tab === selected }"
            >
                <a class="nav-link color-dark" :class="{ tab__selected: tab === selected }">{{ tab }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TabsList",
    props: {
        tabs: {
            type: Array,
            required: true
        },
        selected: {
            type: String,
            required: true
        },
        big: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.main_tabs {
  margin-bottom: 20px;

  /* Dashboard */

  &.dashboard {
    .nav-tabs{
      gap: 20px;

      .nav-item {
        transition: all 0.3s linear;
        border-bottom: 3px solid transparent;
        padding-bottom: 0;

        &.selected_item {
          border-bottom: 3px solid #00AFF0;
        }

        .nav-link {
          font-weight: 700;
          font-size: 25.92px;
          line-height: 46px;
          color: #0B254E;
          opacity: 0.5;
          transition: all 0.3s linear;
          cursor: pointer;
          @media screen and (max-width: 768px) {
            font-size: 16.272px;
            line-height: 29px;
          }

          &.tab__selected {
            opacity: 1;
            color: #00AFF0;
            cursor: auto;
          }
        }
      }

    }
  }

  &.dashboard-tabs {
    .nav-tabs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 10px !important;

      @media screen and (min-width: 991px) {
        gap: 0;
      }

      .nav-item {
        margin-bottom: 10px;
        border: none !important;

        @media screen and (min-width: 991px) {
          margin-bottom: 0;
        }
      }

      .nav-link {
        padding: 4px 10px;
        font-size: 14px !important;
        font-weight: 700 !important;
        border-bottom: 0 !important;
        border-radius: 8px;
        cursor: pointer;

        @media screen and (min-width: 991px) {
          padding: 8px 16px;
        }

        &.tab__selected {
          background-color: #00aff0;
          color: #fff !important;
        }
      }
    }
  }

  .nav-tabs {
    display: flex;
    gap: 15px;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    .nav-item {
      transition: all 0.3s linear;
      border-bottom: 3px solid transparent;
      padding-bottom: 4px;

      &.selected_item {
        border-bottom: 3px solid #00AFF0;
      }

      .nav-link {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #0B254E;
        opacity: 0.5;
        transition: all 0.3s linear;
        cursor: pointer;
        @media screen and (max-width: 768px) {
          font-size: 16.272px;
          line-height: 29px;
        }

        &.tab__selected {
          opacity: 1;
          color: #00AFF0;
          cursor: auto;
        }
      }
    }
  }

  /* Big */
  &.big {
    .nav-item {
      border-bottom: 6px solid transparent;

      &.selected_item {
        border-bottom: 6px solid #00AFF0;
        @media (max-width: 768px) {
          border-bottom: 4px solid #00AFF0;
        }
      }

      .nav-link {
        font-size: 22px;
        line-height: 38px;
        @media (max-width: 768px) {
          font-size: 14.4px;
          line-height: 26px;
        }
      }
    }
  }
}
</style>