


export default {
    state: {
        path: process.env.VUE_APP_LIVE_URL_API
    },
    getters: {
        path: (state) => state.path,
    },
    mutations: {
    },
    actions: {
    }
}