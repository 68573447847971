<template>
  <div class="surprise_gift_wrapper">
    <div class="surprise_gift_overlay" @click="$emit('close')"></div>
    <div class="surprise_gift_modal">
      <div class="modal-close" @click="$emit('close')">
        <CloseBlack/>
      </div>
      <h3>Custom Gift</h3>
      <div class="inputs">
        <GiftInput v-model="gift.price" placeholder="Enter Amount" type="number" :price="true"/>
        <GiftInput v-model="gift.description" placeholder="Suggested Use (optional)" type="text"/>
      </div>
      <BaseButton type="button" class="dark_btn" @click="addToCart">Add To Cart</BaseButton>
    </div>
  </div>
</template>

<script setup>
import BaseButton from "@/router/UI/BaseButton.vue";
import GiftInput from "@/router/components/form/GiftInput.vue";
import CloseBlack from "@/router/components/icons/CloseBlack.vue";
</script>

<script>
import {mapActions, mapGetters} from "vuex";
import preloader from "@/mixins/preloader";
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "SurpriseGift",
  mixins: [preloader, currencyHelper],
  data() {
    return {
      gift: {
        price: null,
        description: null,
      }
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated'
    }),
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
      updateCartStatus: "cart/updateCartStatus",
    }),
    addToCart() {
      if(this.convertToUsd(Number(this.gift.price)) < 10){
        this.toast.error('Minimum amount is $10');
        return;
      }
      const surpriseGift = {
        amount: Number(this.gift.price),
        details: this.gift.description,
        creator_id: this.user.id,
      }
      if (this.authenticated) {
        this.setLoading(true);
        this.axios.post('cart/add', surpriseGift)
            .then((res) => {
              this.updateUser();
              this.toast.info(res.data.message);
              this.$emit('close');
              this.setLoading(false);
            }).catch((res) => {
          this.setLoading(false);
          this.toast.error(res.response.data.message);
        })
      } else {
        surpriseGift.surprise_id = +new Date();
        surpriseGift.creator_name = this.user.name
        localStorage.setItem('cart', JSON.stringify([...JSON.parse(localStorage.getItem('cart') || '[]'), surpriseGift]));
        this.updateCartStatus(+new Date());
        this.$emit('close');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.surprise_gift_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;

  .surprise_gift_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .surprise_gift_modal {
    position: absolute;
    left: 50%;
    bottom: -100%;
    transform: translate(-50%, 50%);
    width: 354px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 35px 23px 40px;
    text-align: center;
    background-image: url("@/assets/images/surprise_bg.png");
    background-size: cover;
    transition: bottom 0.3s ease-in-out;
    @media screen and (max-width: 480px) {
      padding: 28px 40px 34px;
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform: none;
      bottom: -100%;
      left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 89px;
      height: 2px;
      background-color: rgba(11, 37, 78, 0.2);
      display: none;
      @media screen and (max-width: 480px) {
        display: block;
      }
    }

    .modal-close {
      position: absolute;
      top: 11px;
      left: 14px;
      cursor: pointer;
      @media (max-width: 480px) {
        display: none;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 27px;
      line-height: 42px;
      color: #202429;
      margin-bottom: 38px;
      @media (max-width: 480px) {
        font-size: 24px;
        line-height: 25px;
        margin-bottom: 42px;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 53px;
      @media (max-width: 480px) {
        margin-bottom: 44px;
      }
    }

    .dark_btn {
      @media (max-width: 480px) {
        height: 53px;
      }
    }
  }
}

.surprise_gift_wrapper.active {
  visibility: visible;

  .surprise_gift_overlay {
    opacity: 1;
  }


  .surprise_gift_modal {
    bottom: 50%;
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
    }
  }
}
</style>