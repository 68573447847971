<template>
  <div class="th-container">
    <p>{{ message }}</p>
    <img src="@/assets/images/verify.png" alt="Verify">
    <router-link v-if="verified" to="/dashboard" class="primary">View Dashboard</router-link>
  </div>
</template>

<script>
import preloader from "@/mixins/preloader";

export default {
  name: "VerifyPage",
  mixins: [preloader],
  data() {
    return {
      verified: false,
      message: false,
    }
  },
  methods: {
    getPathFromUrl(url) {
      return url.split(/[?#]/)[0];
    }
  },
  mounted() {
    const queryString = window.location.search;
    const status = new URLSearchParams(queryString);
    const tokenIsValid = status.has('token_not_valid');
    const tokenIsExist = status.has('token_not_exist');
    let st = status.get('status');
    if (st == 1 && !tokenIsValid && !tokenIsExist) {
      this.verified = true
      this.message = 'You’ve successfully verified your account'
    } else if (tokenIsValid) {
      this.verified = false
      this.message = 'Oops! Token is no longer valid'
    } else if (tokenIsExist) {
      this.verified = false
      this.message = 'Oops! Token is no longer valid'
    } else {
      this.verified = false
      this.message = 'Oops! Token is no longer valid'
    }
    const url = this.getPathFromUrl(window.location.href);
    window.history.pushState({}, '', url);
    this.setLoading(false)
  }
}
</script>

<style lang="scss" scoped>

.th-container {
  min-height: calc(100vh - 152px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;

  @media screen and (max-width: 480px) {
    margin-top: 77px;
  }
}

img {
  padding-top: 61px;
  @media screen and (max-width: 480px) {
    padding-top: 65px;
  }
}

p {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #252525;
  text-align: center;
  margin: 57px auto 0;
  @media screen and (max-width: 480px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    margin-top: 75px;
  }
}

.primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 352px;
  height: 69px;
  left: 544px;
  top: 679px;
  box-shadow: 0 2.54994px 6.37486px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  margin: 82px auto 30px;

  @media screen and (max-width: 480px) {
    margin-top: 58px;
  }
}
</style>