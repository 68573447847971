<template>
    <div :class="focused && blue ? 'input-main--blue' : ''">
        <label class="input-main">
    <span class="input-header">
      <span class="input-label" :class="labelClass" v-if="labelName">{{ labelName }}</span>
    <router-link :to="{name: inputLinkName}" class="input-link" :class="inputLink" v-if="inputLink">
      {{ inputLink }}
    </router-link>
    </span>
            <span class="input-start-icon">
      <slot name="inputStartIcon">
      </slot>
    </span>
            <input
                    :autocomplete="autocomplete"
                    :type="inputType"
                    v-model="value"
                    @input="$emit('update:modelValue', $event.target.value)"
                    :class="inputClass ? inputClass : ''"
                    :placeholder="placeholder"
                    :name="inputName"
                    @blur="$emit('blur'); focused = false"
                    @focus="focused = true"
            >

            <span class="input-end-icon">
      <slot name="inputEndIcon">
      </slot>
    </span>

        </label>
        <span v-if="errorMessage" class="error_msg mt-10 mb-2 red">{{ errorMessage }}</span>
    </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {useField} from 'vee-validate'

const props = defineProps({
    modelValue: {
        type: String,
        required: false
    },
    inputType: {
        type: String,
        default: 'text',
        required: true
    },
    placeholder: {
        type: String,
        required: false
    },
    placeholderColor: {
        required: false
    },
    inputClass: {
        required: false,
        default: false
    },
    inputName: {
        required: false
    },
    labelName: {
        required: false
    },
    labelClass: {
        required: false
    },
    inputLink: {
        required: false
    },
    inputLinkName: {
        required: false
    },
    inputEndIcon: {
        required: false
    },
    max: {
        required: false
    },
    autocomplete: {
        required: false,
    },
    blue: {
        type: Boolean,
        default: false
    },
});

// eslint-disable-next-line no-unused-vars
const {errorMessage, value} = useField(() => props.inputName, false);
</script>

<script>
export default {
    name: "BaseInput",
    data() {
        return {
            focused: false,
        }
    }
}
</script>


<style scoped lang="scss">
.mb-12 {
  margin-bottom: 12px;
}

.w-100 {
  width: 100%;
}

.input-label {
  display: inline-block;
  transition: all .3s;
}

.input-end-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-start-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

input {
  width: 352px;
  height: 48px;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 24px;
  font-size: 16px;
  line-height: 24px;
}

.input-main {
  position: relative;
  display: block;
  input {
    border: 1px solid transparent !important;
    transition: all .5s;
    @media (max-width: 480px) {
      width: 100%
    }
  }
}

.search-input-block {
  margin: 0 32px 20px 5px;
  @media (max-width: 480px) {
    margin: 0 0 20px 0;
  }
}

.input-main--blue {
  color: #00aff0;

  .input-label {
    color: #00aff0;
  }

  input {
    border: 1px solid #00aff0 !important;
  }
}

</style>

