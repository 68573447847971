<template>
  <div class="settings-container th-container">
    <div class="settings">
      <div class="heading">Settings</div>
      <TabsList :selected="activeTab" :tabs="tabs" @select="($event) => (activeTab = $event)" :big="true"
                class="settings_tabs"/>
      <template v-if="activeTab === 'Security'">
        <div class="setting-box">
          <div class="setting-text">
            <p>Change Email</p>
          </div>
          <div class="setting-field column">
            <base-input
                :autocomplete="false"
                class="base-input"
                input-type="text"
                input-name="email'"
                placeholder="New email address"
                v-model="settings.email"
            />
            <base-button
                type="button"
                class="primary w-fit-content"
                @click="changeEmail"
            >
              Confirm
            </base-button>
          </div>
        </div>
        <div class="setting-box">
          <div class="setting-text">
            <p>Change Password</p>
          </div>
          <div class="setting-field column">
            <base-input
                :autocomplete="false"
                v-model="settings.password"
                class="base-input"
                input-type="password"
                input-name="password"
                placeholder="New password"
                label-class="mb-12"
            />
            <base-button
                type="button"
                class="primary w-fit-content"
                @click="changePassword"
            >
              Confirm
            </base-button>
          </div>
        </div>
        <div class="twitter_status_block">
          <p>Twitter Autotweets: <span class="twitter_status" :class="user.is_twitter_connected && user.twitter_autotweet ? 'active': 'inactive'">{{
              user.is_twitter_connected && user.twitter_autotweet ? 'Active' : 'Inactive'
            }}</span></p>
          <base-button
              v-if="user.is_twitter_connected"
              type="button"
              class="primary w-fit-content"
              @click="twitterModalStatus = true"
          >
            Settings
          </base-button>
          <a v-else href="https://api.giftxyz.com/twitterConnect" class="primary w-fit-content">Connect</a>
        </div>
        <div class="blocked_accounts_block">
          <p>Blocked Accounts</p>
          <p>{{ user.blocked_users.length }}</p>
        </div>
      </template>
      <template v-else-if="activeTab === 'Profile'">
        <div class="setting-box selectable-setting">
          <div class="setting-text">
            <p>Display Rank On Profile</p>
          </div>
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.display_rank_on_profile === 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'display_rank_on_profile',1)"
            >
              On
            </base-button>
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.display_rank_on_profile !== 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'display_rank_on_profile',0)"
            >
              Off
            </base-button>
          </div>
        </div>
        <div class="setting-box selectable-setting">
          <div class="setting-text">
            <p>Display account on leaderboard</p>
          </div>
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.display_account_on_leaderboard === 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'display_account_on_leaderboard',1)"
            >
              On
            </base-button>
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.display_account_on_leaderboard !== 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'display_account_on_leaderboard',0)"
            >
              Off
            </base-button>
          </div>
        </div>
        <div class="setting-box selectable-setting has_small_text">
          <div class="setting-text">
            <p>Activity Status</p>
          </div>
          <div class="setting_small_text">Allow users to see if you’re online/offline</div>
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.activity_status === 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'activity_status',1)"
            >
              On
            </base-button>
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.activity_status !== 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'activity_status',0)"
            >
              Off
            </base-button>
          </div>
        </div>
      </template>
      <template v-else-if="activeTab === 'Account'">
        <div v-if="user.role_id === 1 && !user.stripe_account_is_verified" class="setting-box stripe_field">
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content primary active"
                @click="connectStripe"
            >Connect Stripe</base-button>
          </div>
        </div>
        <div class="setting-box selectable-setting">
          <div class="setting-text">
            <p>Email Notifications</p>
          </div>
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.email_notifications === 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'email_notifications',1)"
            >
              On
            </base-button>
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.email_notifications !== 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'email_notifications',0)"
            >
              Off
            </base-button>
          </div>
        </div>
        <div class="setting-box selectable-setting">
          <div class="setting-text">
            <p>App Notifications</p>
          </div>
          <div class="setting-field">
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.email_notifications === 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'email_notifications',1)"
            >
              On
            </base-button>
            <base-button
                type="button"
                class="w-fit-content"
                :class="user.email_notifications !== 1 ? 'primary active' : 'secondary'"
                @click="updateField($event,'email_notifications',0)"
            >
              Off
            </base-button>
          </div>
        </div>
        <div class="setting-box selectable-setting currency-select">
          <div class="setting-text">
            <p>Display currency</p>
          </div>
          <div class="setting-field">
            <base-select
                v-if="currency"
                :options="currencies"
                :visible-key="'code'"
                :selected="currency"
                :search-on="true"
                :text="'Select Currency'"
                class="select"
                @selected="changeCurrency($event)"
            />

          </div>
        </div>
        <div class="setting-box">
          <div class="delete-user">
            <p @click="openDeleteAccountConfirm">Delete account</p>
          </div>
        </div>
      </template>
    </div>
    <confirm-modal
        v-if="confirmDialog"
        @result="deleteAccount"
        @close="confirmDialog = false"
    />

    <TwitterModal v-if="twitterModalStatus" @close="twitterModalStatus = false"/>
  </div>
</template>

<script setup>
import BaseInput from "../../UI/BaseInput";
import BaseButton from "../../UI/BaseButton";
import BaseSelect from "../../UI/BaseSelect";
import ConfirmModal from "../../Modals/ConfirmModal";
import TabsList from "@/router/components/tabs/TabsList.vue";
import TwitterModal from "@/router/components/dashboard/TwitterModal.vue";
</script>

<script>
import './SettingsPage.scss';
import {mapActions, mapGetters} from "vuex";
import preloader from "@/mixins/preloader";

export default {
  name: "SettingsPage",
  mixins: [preloader],
  data() {
    return {
      tabs: ['Security', 'Profile', 'Account'],
      activeTab: 'Security',
      settings: {
        email: null,
        password: null,
      },
      currencies: [],
      confirmDialog: false,
      twitterModalStatus: false,
    }
  },
  computed: {
    ...mapGetters({user: 'auth/user', currency: "currency"}),
  },
  mounted() {
    this.getCurrencies();

    const urlParams = new URLSearchParams(window.location.search);
    const twitter_access_token = urlParams.get('twitter_access_token');
    const twitter_access_secret = urlParams.get('twitter_access_secret');
    const twitter_nickname = urlParams.get('twitter_nickname');
    const twitter_id = urlParams.get('twitter_id');
    if (twitter_access_token && twitter_access_secret && twitter_nickname && twitter_id) {
      this.axios.post('connect-twitter', {
        twitter_access_token,
        twitter_access_secret,
        twitter_nickname,
        twitter_id
      }).then(() => {
        this.signIn()
        this.setLoading(false)
        this.$router.push({query: null})
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    } else {
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions({
      logoutStore: 'auth/logout',
      signIn: 'auth/login',
      getCurrency: "getCurrency"
    }),
    getCurrencies() {
      this.axios.post('currencies/get')
          .then((res) => {
            this.currencies = res.data.currencies;
          })
    },
    changeEmail() {
      this.axios.post('user/change-email',
          {
            email: this.settings.email
          }
      ).then((res) => {
        this.settings.email = null
        this.toast.info(res.data.message);
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    },
    changePassword() {
      this.axios.post('user/change-password',
          {
            password: this.settings.password
          }
      ).then((res) => {
        this.settings.password = null
        this.toast.info(res.data.message);
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    },
    openDeleteAccountConfirm() {
      this.confirmDialog = true;
    },
    deleteAccount(event) {
      if (event === true) {
        this.axios.post('user/delete-account').then((res) => {
          this.logoutStore();
          localStorage.removeItem('access_token');
          if (this.$route.name !== 'home') {
            this.$router.push({name: 'home'})
          }
          this.toast.info(res.data.message);
        }).catch((res) => {
          this.toast.error(res.response.data.message);
        })
      }
    },
    updateField(event, field, value) {
      let activeButton = event.target.parentElement.querySelector('button.active');
      if (activeButton !== event.target) {
        activeButton.classList.remove('primary');
        activeButton.classList.remove('active');
        activeButton.classList.add('secondary');
        event.target.classList.add('active');
        event.target.classList.remove('secondary');
        event.target.classList.add('primary');
      }
      this.axios.post('user/update-field',
          {
            field: field,
            field_value: value
          }).then((res) => {
        this.toast.info(res.data.message);
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      })
    },
    changeCurrency(event) {
      this.axios.post('user/update-selected-currency', {
        selected_currency_id: event.id
      }).then((res) => {
        this.signIn();
        this.getCurrency();
        this.toast.info(res.data.message);
      }).catch((res) => {
        this.toast.error(res.response.data.message);
      });
    },
    connectStripe() {
      this.setLoading(true)
      this.axios.post('/connect-stripe').then((response) => {
        if(response.data.success) {
          window.location.href = response.data.link
        } else {
          this.setLoading(false)
          this.toast.error(response.data.message)
        }
      }).catch((res) => {
        this.setLoading(false)
        this.toast.error(res.response.data.message);
      })
    },
  }
}
</script>