<template>
  <div class="gift_input">
    <input :type="type" @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder">
    <span v-if="price && currency" class="currency">{{ currency?.code }}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "GiftInput",
  mixins: [currencyHelper],
  props: {
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    price: {
      type: Boolean,
      default: null
    },
  },
  computed: {
    ...mapGetters({currency: "currency"}),
  },
}
</script>

<style lang="scss" scoped>
.gift_input {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    padding-left: 17px;
    font-weight: 700;
    font-size: 13px;
    height: 55px;
    color: #0B254E;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    border: none;
    @media (max-width: 768px) {
      height: 46px;
      font-size: 11px;
    }

    &::placeholder {
      color: rgb(11 37 78 / 20%);
    }
  }

  .currency {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    font-weight: 700;
    font-size: 13px;
    color: #0B254E;
    opacity: 0.2;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
}
</style>