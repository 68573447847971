<template>
  <div class="dashboard_notifications">
    <div v-if="notifications.length" class="dashboard_notifications_list">
      <NotificationItem v-for="notification in notifications" :key="notification" :notification="notification" @select="selectNotification"/>
    </div>
    <NothingToSee v-else/>
  </div>
</template>

<script>
import NotificationItem from "@/router/components/dashboard/NotificationItem.vue";
import NothingToSee from "@/router/components/dashboard/NothingToSee.vue";
import preloader from "@/mixins/preloader";

export default {
  name: "NotificationsBlock",
  mixins: [preloader],
  components: {NothingToSee, NotificationItem},
  data() {
    return {
      notifications: []
    }
  },
  mounted() {
    this.axios.get('/notifications').then(res => {
      this.notifications = res.data.notifications

      this.axios.post('/read-notifications').then(() => {
        this.setLoading(false)
      })
    })
  },
  methods: {
    selectNotification(notification) {
      this.axios.get('/notifications?id='+notification.id).then(res => {
        this.notifications = res.data.notifications
      })
      this.$emit('select', notification)
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard_notifications {
  max-width: 569px;
  width: 100%;
  margin-top: -52px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 0;
  }

  .dashboard_notifications_list {
    display: flex;
    flex-direction: column;
    gap: 33px;
    padding-right: 21px;
    max-height: calc(100vh - 320px);
    height: 100%;
    overflow: auto;
    padding-top: 7px;
    @media (max-width: 768px) {
      gap: 18px;
      margin-top: 13px;
      max-height: 450px;
      margin-bottom: 20px;
      margin-right: -21px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(0 0 0 / 10%);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(0 0 0 / 30%);
    }
  }
}
</style>