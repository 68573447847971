<template>
  <ul class="dashboard_tabs">
    <li v-for="tab in tabs" :key="tab" :class="{active: selected === tab.name}" @click="$emit('select', tab.name)">
      <span>{{ tab.name}}</span>
      <span v-if="typeof tab.count !== 'object'" class="line">-</span>
      <span>{{ tab.count }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "DashboardTabs",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
.dashboard_tabs {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: row;
    gap: 7px;
    margin-right: -20px;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px 0 19px;
    border-radius: 10px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: all 0.3s linear;
    @media (max-width: 768px) {
      min-width: 166px;
      height: 37px;
      border: 2px solid #0B254E80;
      justify-content: center;
    }

    &.active {
      border: 3px solid #00AFF0;
      @media (max-width: 768px) {
        border: 2px solid #00AFF0;
      }

      span {
        opacity: 1;
        color: #00aff0;
      }
    }

    .line {
      display: none;
      @media (max-width: 768px) {
        display: block;
        margin: 0 3px;
      }
    }

    span {
      font-weight: 700;
      color: #0B254E;
      opacity: 0.5;
      line-height: 55px;
      transition: all 0.3s linear;
      @media (max-width: 768px) {
        font-size: 13.8996px !important;
        line-height: 35px !important;
      }
    }

    span:first-child {
      font-size: 19.4594px;
    }

    span:last-child {
      font-size: 24px;
    }
  }
}
</style>