<template>
  <div class="add_to_cart">
    <div class="add_to_cart_left">
      <div class="basket_block">
        <BasketIcon/>
        <div class="count">{{ count }}</div>
      </div>
      <div v-if="currency" class="total">{{ formatAmount(total, currency.code) }}</div>
    </div>
    <router-link :to="{name: 'checkout'}" class="white_btn">Checkout</router-link>
  </div>
</template>

<script>
import BasketIcon from "@/router/components/icons/BasketIcon.vue";
import {mapGetters} from "vuex";
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "AddToCart",
  components: {BasketIcon},
  mixins: [currencyHelper],
  data() {
    return {
      cartItems: [],
    };
  },
  mounted() {
    let localCart = JSON.parse(localStorage.getItem("cart") || "[]");
    if (localCart.length) {
      this.cartItems = localCart;
    }
  },
  computed: {
    ...mapGetters({user: 'auth/user', authenticated: 'auth/authenticated'}),
    total() {
      if (this.authenticated) {
        return this.user?.cart?.reduce((acc, item) => item.amount ? acc + parseFloat(item.amount) : acc + this.convertPrice(item.product, parseFloat(item.product.price)), 0)
      } else {
        return this.cartItems.reduce((acc, item) => item.amount ? acc + parseFloat(item.amount) : acc + this.convertPrice(item, parseFloat(item.price)), 0)
      }
    },
    count() {
      return this.user?.cart?.length || this.cartItems.length;
    }
  }
}
</script>

<style lang="scss" scoped>
.add_to_cart {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #202429;
  border-radius: 8px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 17px 15px 28px;
  z-index: 9999;
  @media screen and (max-width: 768px) {
    width: 100%;
    bottom: 0;
    border-radius: 0;
    padding: 10px;
  }

  .add_to_cart_left {
    display: flex;
    align-items: center;
    gap: 34px;

    @media screen and (max-width: 768px) {
      gap: 20px;
    }

    .basket_block {
      position: relative;

      .count {
        position: absolute;
        right: -15px;
        top: -15px;
        width: 30px;
        height: 30px;
        background: #FFFFFF;
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        color: #202429;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          font-size: 10px;
          line-height: 20px;
          width: 20px;
          height: 20px;
          top: -5px;
          right: -10px;
        }
      }
    }

    .total {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      color: #FFFFFF;
      @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  .white_btn {
    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
      width: 166px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}
</style>