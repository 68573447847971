<template>
  <div class="library-slider">
    <div class="th-container">
      <VueSlickCarousel :arrows="true" :dots="false" :slidesToShow="slidersToShow" :swipeToSlide="true" :touchMove="true">
          <div class="products-block" :key="index" v-for="(item, index) in data">
            <div class="products-block-main">
              <p>{{ item.name }}</p>
              <base-button
                  class="primary"
              >
                Choose
              </base-button>
            </div>
          </div>

        <template #prevArrow="">
          <div class="custom-arrow">
            <ArrowLeft/>
          </div>
        </template>
        <template #nextArrow="">
          <div class="custom-arrow">
            <ArrowRight/>
          </div>
        </template>


      </VueSlickCarousel>
    </div>
  </div>
</template>


<script>

import BaseButton from '../../../UI/BaseButton.vue'

export default {
  name: "LibrarySlider",
  components:{
    'base-button': BaseButton
  },
  props: {
    data: {
      required: true
    },
    slidersToShow:{
      required: false
    }
  },
}
</script>

<script setup>
import "./LibrarySlider.scss";
import "../LibraryPage.scss";

import ArrowLeft from '../../../../assets/svg/ArrowLeft.vue'
import ArrowRight from '../../../../assets/svg/ArrowRight.vue'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

</script>

