<template>
  <div class="dashboard_page th-container">
    <ConnectStripe v-if="stripeModalStatus" @close="stripeModalStatus = false"/>
    <TabsList :selected="selectedTab" :tabs="tabs" @select="($event) => (selectedTab = $event)" class="dashboard"/>
    <div v-show="selectedTab === 'Home'" class="dashboard_home_tab">
      <UserInfo @create="createStatus = true"/>
      <NotificationsBlock @select="($event) => (selectedOrder = $event)"/>
    </div>
    <div v-show="selectedTab === 'Social'" class="dashboard_tab">
      <div class="left">
        <router-link to="/leaderboard" class="primary dashboard_big_btn">Explore Users</router-link>
        <SearchInput v-model="searchSocial" class="search"/>
        <DashboardTabs :tabs="socialTabs" :selected="socialSelectedTab" @select="changeSocialTab"/>
      </div>
      <div class="right">
        <div class="dashboard_tab_items socials_tab">
          <template v-if="filteredSocialItems.length">
            <SocialItem v-for="socialItem in filteredSocialItems" :key="socialItem.id" :item="socialItem"/>
            <div v-if="user.activity_status !== 1" class="activity_warning">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.6002 10.596V7.604" stroke="#323232" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5994 13C9.489 13 9.3994 13.0896 9.4002 13.2C9.4002 13.3104 9.4898 13.4 9.6002 13.4C9.7106 13.4 9.8002 13.3104 9.8002 13.2C9.8002 13.0896 9.7106 13 9.5994 13" stroke="#323232" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2231 3.41123L17.3503 14.1344C18.0623 15.3808 17.1623 16.932 15.7271 16.932H3.47271C2.03671 16.932 1.13671 15.3808 1.84951 14.1344L7.97671 3.41123C8.69431 2.15443 10.5055 2.15443 11.2231 3.41123Z" stroke="#323232" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Turn activity status on in <router-link to="/settings">Settings</router-link> to appear online
            </div>
          </template>
          <NothingToSee v-else/>
        </div>
      </div>
    </div>
    <div v-show="selectedTab === 'Wishlist'" class="dashboard_tab">
      <div class="left">
        <button class="primary dashboard_big_btn" @click="createStatus = true">+Create</button>
        <SearchInput v-model="searchProduct" class="search"/>
        <DashboardTabs :tabs="manageTabs" :selected="manageSelectedTab" @select="changeManageTab"/>
      </div>
      <div class="right">
        <div class="dashboard_tab_items wishlist">
          <template v-if="filteredProducts.length && selectedTab === 'Wishlist'">
            <GiftItem v-for="item in filteredProducts" :key="item.id" :item="item" @edit="editWish"
                      class="mobile_manage_item"/>
          </template>
          <NothingToSee v-else/>
        </div>
      </div>
    </div>
    <CreateModal
        v-if="createStatus"
        @close="closeModal"
        :edit-wish="editWishItem"
    />

    <OrderModal :notification="selectedOrder" @close="selectedOrder = null"/>
  </div>
</template>

<script>
import TabsList from "@/router/components/tabs/TabsList.vue";
import NotificationsBlock from "@/router/components/dashboard/Notifications.vue";
import UserInfo from "@/router/components/dashboard/UserInfo.vue";
import {mapActions, mapGetters} from "vuex";
import CreateModal from "@/router/components/dashboard/CreateModal.vue";
import GiftItem from "@/router/components/GifttItem.vue";
import SearchInput from "@/router/components/form/SearchInput.vue";
import DashboardTabs from "@/router/components/dashboard/DashboardTabs.vue";
import SocialItem from "@/router/components/dashboard/socials/SocialItem.vue";
import NothingToSee from "@/router/components/dashboard/NothingToSee.vue";
import OrderModal from "@/router/components/modals/OrderModal.vue";
import preloader from "@/mixins/preloader";
import ConnectStripe from "@/router/components/ConnectStripe.vue";
import twitter from "@/mixins/twitter";

export default {
  name: "DashboardPage",
  mixins: [preloader, twitter],
  components: {
    ConnectStripe,
    OrderModal,
    NothingToSee,
    SocialItem,
    DashboardTabs,
    SearchInput,
    GiftItem, CreateModal, UserInfo, NotificationsBlock, TabsList
  },
  computed: {
    ...mapGetters({user: 'auth/user'}),
    filteredProducts() {
      if (!this.searchProduct) return this.user.wishlists
      return this.user.wishlists.filter(item => item.name.toLowerCase().includes(this.searchProduct.toLowerCase()))
    },
    filteredSocialItems() {
      if (!this.searchSocial) return this.socialItems
      return this.socialItems.filter(item => item.username.toLowerCase().includes(this.searchSocial.toLowerCase()))
    },
    following() {
      return this.user?.favorites || []
    },
    followers() {
      return this.user?.followers || []
    }
  },
  watch: {
    user: {
      handler() {
        this.manageTabs = [
          {
            name: 'All',
            count: this.user?.wishlists?.length
          },
          {
            name: 'Newest',
            count: null
          },
          {
            name: 'Price: High-Low',
            count: null
          },
          {
            name: 'Price: Low-High',
            count: null
          }
        ]
      },
      deep: true
    },
    createStatus(val) {
      if (val) {
        document.body.classList.add("hidden");
      } else {
        document.body.classList.remove("hidden");
      }
    }
  },
  data() {
    return {
      selectedTab: "Home",
      tabs: ['Home', 'Social', 'Wishlist'],
      createStatus: false,
      editWishItem: false,
      onlineUsers: [],
      socialTabs: [],
      socialSelectedTab: 'Online',
      socialItems: [],
      searchSocial: null,
      manageTabs: [],
      manageSelectedTab: 'All',
      searchProduct: null,
      selectedOrder: null,
      stripeModalStatus: false
    }
  },
  created() {
    this.setLoading(true)
    if (!localStorage.getItem('dontShowConnectStripe')) {
      this.stripeModalStatus = true
    }
  },
  mounted() {
    this.axios.get('online').then((res) => {
      this.onlineUsers = res.data
      this.socialItems = res.data

      this.socialTabs = [
        {
          name: 'Online',
          count: this.onlineUsers.length
        },
        {
          name: 'Following',
          count: this.following.length
        },
        {
          name: 'Followers',
          count: this.followers.length
        }
      ]

      this.manageTabs = [
        {
          name: 'All',
          count: this.user?.wishlists?.length
        },
        {
          name: 'Newest',
          count: null
        },
        {
          name: 'Price: High-Low',
          count: null
        },
        {
          name: 'Price: Low-High',
          count: null
        }
      ]
    })
  },
  methods: {
    ...mapActions({
      setCreateModalStatus: "setCreateModalStatus",
    }),
    editWish(item) {
      this.editWishItem = item
      this.createStatus = true
    },
    closeModal() {
      this.createStatus = false
      this.editWishItem = false
    },
    changeSocialTab(tab) {
      if (tab === 'Online') this.socialItems = this.onlineUsers
      if (tab === 'Following') this.socialItems = this.following
      if (tab === 'Followers') this.socialItems = this.followers
      this.socialSelectedTab = tab
    },
    changeManageTab(tab) {
      this.manageSelectedTab = tab
      if (tab === 'All' || tab === 'Newest') this.filteredProducts = this.user.wishlists.sort((a, b) => a.id - b.id)
      if (tab === 'Price: High-Low') this.filteredProducts = this.user.wishlists.sort((a, b) => b.price - a.price)
      if (tab === 'Price: Low-High') this.filteredProducts = this.user.wishlists.sort((a, b) => a.price - b.price)
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard_page {
  padding-top: 137px;
  min-height: calc(100vh - 78px);
  @media (max-width: 768px) {
    min-height: auto;
    padding-top: 80px;
  }

  .dashboard_home_tab {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .dashboard_tab {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .left {
      max-width: 300px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 768px) {
        max-width: 100%;
        gap: 12px;
      }

      .search {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .dashboard_big_btn {
        @media (max-width: 768px) {
          margin-top: 0;
        }
      }
    }

    .right {
      max-width: 65%;
      width: 100%;
      margin-top: -70px;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-top: 0;
      }

      .dashboard_tab_items {
        display: flex;
        flex-direction: column;
        gap: 22px;
        padding-right: 21px;
        max-height: calc(100vh - 320px);
        height: 100%;
        overflow: auto;
        @media (max-width: 768px) {
          gap: 23px;
          margin-top: 13px;
          max-height: 450px;
          margin-bottom: 20px;
          padding-right: 0;
        }

        &.socials_tab {
          @media (max-width: 768px) {
            flex-direction: column-reverse;
          }
        }

        &.wishlist {
          flex-direction: row;
          flex-wrap: wrap;
          overflow: hidden;
          max-height: 100%;
          height: auto;
          gap: 43px;
          padding-right: 0;
          padding-bottom: 100px;
        }

        &::-webkit-scrollbar {
          width: 5px;
          @media (max-width: 768px) {
            width: 0;
          }
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgb(0 0 0 / 10%);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgb(0 0 0 / 30%);
        }

        .activity_warning {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 7px 0;
          width: 100%;
          border-radius: 14.4px;
          background: #FFE5C7;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          line-height: 24.3px;

          a {
            color: #000;
            text-decoration: underline;
            padding: 0 2px;
            font-weight: bold;
          }

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .dashboard_big_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24.3936px;
    line-height: 37px;
    width: 100%;
    height: 72px;
    margin-top: 19px;
    @media (max-width: 768px) {
      width: 100%;
      height: 45px;
      padding: 0;
      font-size: 13.552px;
      line-height: 20px;
      margin-top: 20px;
    }
  }
}
</style>