<template>
    <div class="favorites-container">
        <div class="th-container">
            <h1 class="title">{{ favorites.length }} Favorites</h1>
            <div class="favorites">
                <div class="cards">
                    <div v-for="(favorite, index) in favorites" :key="favorite.id" class="card">
                        <div class="delete-favorite" @click="deleteFavorite(favorite.id, index)">
                            <img src="@assets/images/modal-close-icon.png" alt="Delete Favorite">
                        </div>
                        <div class="content">
                            <div class="image">
                                <img v-if="favorite.avatar" :src="favorite.avatar" alt="Avatar">
                                <img v-else width="91" height="91" src="@assets/images/user-default.png" alt="Avatar">
                            </div>
                            <div class="user-status">
                                <img v-if="favorite.status==='Online'" src="/svg/favorite-online.svg" alt="Online">
                                <img v-else src="/svg/favorite-offline.svg" alt="Offline">
                            </div>
                            <div class="info">
                                <div class="status">{{ favorite.status }}</div>
                                <div class="head">
                                    <h5 class="name">{{ favorite.name }}</h5>
                                </div>
                                <p class="nick">{{ favorite.username }}</p>
                            </div>
                        </div>
                        <div class="actions">
                            <div class="button">
                                <button class="secondary">Message</button>
                            </div>
                            <div class="button">
                                <router-link :to="{name: 'user-profile', params: {username: favorite.username}}"
                                             class="primary">Profile
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <IndexLeaderboard/>
        <ConfirmDeleteFavoriteModal
                v-if="confirmDialog"
                @result="deleteModal($event)"
                @close="closeModal"
        />
    </div>
</template>

<script setup>
import './FavoritesPage.scss';
import IndexLeaderboard from '../PageIndex/IndexLeaderboard/IndexLeaderboard.vue';
import ConfirmDeleteFavoriteModal from "../../Modals/ConfirmDeleteFavoriteModal.vue";
</script>
<script>

export default {
    data() {
        return {
            favorites: [],
            confirmDialog: false,
            deleteItem: null,
            deleteIndex: null,
        }
    },
    mounted() {
        this.getFavorites()
    },
    methods: {
        getFavorites() {
            this.axios.post('user/favorites').then((res) => {
                this.favorites = res.data
            });
        },
        closeModal() {
            this.confirmDialog = false;
        },
        deleteModal() {
            this.confirmDialog = false;
            if (this.deleteItem) {
                this.axios.post('user/follow', {
                    id: this.deleteItem
                }).then(() => {
                    this.favorites.splice(this.deleteIndex, 1)
                    this.deleteItem = null
                    this.deleteIndex = null
                })
            }
        },
        deleteFavorite(id, index) {
            this.confirmDialog = true;
            this.deleteItem = id;
            this.deleteIndex = index;
        }
    }
}
</script>