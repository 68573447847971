export default {
    namespace: true,
    state: {
        path: process.env.VUE_APP_LIVE_URL_API+'chat/'
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
}