<template>
  <div class="checkout_page">
    <div class="th-container">
      <div class="back"
           @click="back">
        <BackIcon/>
      </div>
      <h1>Shopping Cart</h1>
      <div class="checkout_section" :class="'step_'+group.step" v-for="(group, index) in groupProducts" :key="index">
        <div class="checkout_section_left">
          <CheckoutInput id="from" label="From<span>(Not Required)</span>" type="text" v-model="group.from"/>
          <CheckoutInput id="message" label="Message<span>(Not Required)</span>" type="text" v-model="group.message"/>
          <CheckoutInput id="email" label="Email<span style='color:red'>*</span> Private" type="email"
                         v-model="group.email"/>
          <div class="checkboxes">
            <Checkbox :modelValue="group.hide" @update:modelValue="group.hide = $event" label="Hide Name"
                      :description="group.hideNameDescription" id="hideNameCheckbox" :checkout="true"/>
            <Checkbox :modelValue="group.agree" @update:modelValue="group.agree = $event" :smallLabel="group.agreeLabel"
                      :list="group.agreeList" id="agreeCheckbox" :checkout="true"/>
          </div>
          <div class="pay_block">
            <button @click="payNow(group)" class="dark_btn">Pay Now</button>
            <div class="secure_text">Payment secured by <b>stripe</b></div>
          </div>
        </div>
        <div class="checkout_section_right">
          <div class="user_card">
            <div class="heading"><b>{{ group.name }}’s</b> Wish Basket</div>
            <div class="checkout_products">
              <CheckoutProduct v-for="item in group.products" :key="item.id" :item="item"
                               @update-cart="generateGroupProducts"/>
            </div>
            <ul class="user_card_totals">
              <li>
                <span>Subtotal</span>
                <span>{{ formatAmount(group.subtotal, currency.code) }}</span>
              </li>
              <li>
                <span>10% Platform Fee</span>
                <span>{{ formatAmount(group.fee, currency.code) }}</span>
              </li>
            </ul>
            <div class="total_block">
              <span>Total</span>
              <span>{{ formatAmount(group.total, currency.code) }}</span>
            </div>
          </div>
          <div class="pay_block">
            <button @click="group.step = 2" class="dark_btn check_out">Check Out</button>
            <button @click="payNow(group)" class="dark_btn">Pay Now</button>
            <div class="secure_text">Payment secured by <b>stripe</b></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import currencyHelper from "@/mixins/currencyHelper";
import BackIcon from "@/router/components/icons/BackIcon.vue";
import CheckoutInput from "@/router/components/checkout/CheckoutInput.vue";
import CheckoutProduct from "@/router/components/checkout/CheckoutProduct.vue";
import Checkbox from "@/router/components/form/Checkbox.vue";
import {mapActions, mapGetters} from "vuex";
import preloader from "@/mixins/preloader";

export default {
  name: 'CheckoutPage',
  components: {Checkbox, CheckoutProduct, CheckoutInput, BackIcon},
  mixins: [currencyHelper, preloader],
  data() {
    return {
      groupProducts: [],
    }
  },
  computed: {
    ...mapGetters({user: 'auth/user', authenticated: 'auth/authenticated', currency: "currency"}),
  },
  mounted() {
    if (this.currency) {
      this.generateGroupProducts()
    }
  },
  watch: {
    user: {
      handler() {
        this.generateGroupProducts()
      },
      deep: true
    },
    currency: {
      handler() {
        this.generateGroupProducts()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      updateCartStatus: "cart/updateCartStatus",
    }),
    generateGroupProducts() {
      if (this.authenticated) {
        this.groupProducts = this.user.cart.reduce((acc, item) => {
          if (!acc[item.creator_id]) {
            acc[item.creator_id] = {
              name: item.creator.name,
              creator_id: item.creator_id,
              products: [],
              subtotal: 0,
              total: 0,
              fee: 0,
              step: 1,
              from: null,
              message: null,
              email: this.user.email,
              hide: false,
              hideNameDescription: 'If checked, your name will be completely hidden from everyone',
              agree: false,
              agreeLabel: 'I agree to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/policy" target="_blank">Privacy Policy</a> and the following statements:',
              agreeList: [
                'I am making a non-refundable cash gift donation.',
                'I expect <b><u>no</u></b> product or service in return from the gift recipient.',
                'This payment is a donation intended for the gift recipient.',
                'I have taken the necessary steps to confirm the account owner is authentic and I understand that giftxyz will not be held responsible for any issues arising from a catfishing situation.',
                'I understand that by violating these terms I may be subject to legal action or can fall a victim of scams.',
                'I understand that by checking the box above and then clicking "PURCHASE", I will have created a legally binding e-signature to this agreement.'
              ]
            }
          }
          if (this.authenticated) {
            acc[item.creator_id].buyer_id = this.user.id
          }
          acc[item.creator_id].currency_id = this.authenticated ? this.user.selected_currency_id : this.currency.id
          acc[item.creator_id].subtotal += item.amount ? parseFloat(item.amount) : this.convertPrice(item.product, parseFloat(item.product.price))
          acc[item.creator_id].fee = parseFloat(acc[item.creator_id].subtotal * 0.1)
          acc[item.creator_id].total = acc[item.creator_id].subtotal + acc[item.creator_id].fee
          acc[item.creator_id].products.push(item)
          return acc
        }, {})
      } else {
        let cart = JSON.parse(localStorage.getItem('cart')) || []
        this.groupProducts = cart.reduce((acc, item) => {
          if (!acc[item.creator_id]) {
            acc[item.creator_id] = {
              name: item.creator_name,
              creator_id: item.creator_id,
              products: [],
              subtotal: 0,
              total: 0,
              fee: 0,
              step: 1,
              from: null,
              message: null,
              email: '',
              hide: false,
              hideNameDescription: 'If checked, your name will be completely hidden from everyone',
              agree: false,
              agreeLabel: 'I agree to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/policy" target="_blank">Privacy Policy</a> and the following statements:',
              agreeList: [
                'I am making a non-refundable cash gift donation.',
                'I expect <b><u>no</u></b> product or service in return from the gift recipient.',
                'This payment is a donation intended for the gift recipient.',
                'I have taken the necessary steps to confirm the account owner is authentic and I understand that giftxyz will not be held responsible for any issues arising from a catfishing situation.',
                'I understand that by violating these terms I may be subject to legal action or can fall a victim of scams.',
                'I understand that by checking the box above and then clicking "PURCHASE", I will have created a legally binding e-signature to this agreement.'
              ]
            }
          }

          acc[item.creator_id].currency_id = this.authenticated ? this.user.selected_currency_id : this.currency.id
          acc[item.creator_id].subtotal += item.amount ? parseInt(item.amount) : parseInt(this.convertPrice(item, item.price))
          acc[item.creator_id].fee = parseFloat(acc[item.creator_id].subtotal * 0.1)
          acc[item.creator_id].total = acc[item.creator_id].subtotal + acc[item.creator_id].fee
          let productItem = item.amount ? item : {product: item}
          acc[item.creator_id].products.push(productItem)
          return acc
        }, {})
      }
      this.setLoading(false);
    },
    back() {
      this.$router.go(-1)
    },
    payNow(group) {
      if (!group.email) {
        this.toast.error('Please enter email')
        return
      }
      if (!group.agree) {
        this.toast.error('Please agree to the terms')
        return
      }

      this.setLoading(true);
      this.axios.post('order/create', group)
          .then((res) => {
            if (res.data.checkout_url) {
              localStorage.removeItem('cart')
              this.updateCartStatus()
              window.location.href = res.data.checkout_url
            } else {
              this.setLoading(false);
              this.toast.error(res.data.message)
            }
          }).catch((res) => {
        this.setLoading(false);
        this.toast.error(res.response.data.message);
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout_page {
  padding-top: 47px;
  background-image: url("@assets/images/checkout_bg.png");
  background-size: 100%;
  background-color: #F7F7F7;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 10px 0 50px;
    background-size: 100% 100%;
  }

  .back {
    position: absolute;
    top: 15px;
    left: -69px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: #252525;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    @media (max-width: 768px) {
      left: 20px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 66px;
    color: #0B254E;
    @media (max-width: 768px) {
      font-size: 24px;
      text-align: center;
    }
  }

  .checkout_section {
    display: flex;
    gap: 103px;
    padding-bottom: 138px;
    @media (max-width: 768px) {
      padding-bottom: 0;

      &.step_1 {
        .dark_btn:not(.check_out) {
          display: none;
        }
      }

      &.step_2 {
        .checkout_section_left {
          display: block;
        }

        .checkout_section_right, .check_out {
          display: none;
        }
      }
    }

    .checkout_section_left {
      width: 60%;
      @media (max-width: 768px) {
        width: 100%;
        display: none;
      }

      .checkboxes {
        margin-top: 32px;
      }

      .pay_block {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }
    }

    .checkout_section_right {
      width: 40%;
      @media (max-width: 768px) {
        width: 100%;
      }

      .user_card {
        margin-top: 50px;
        padding: 7px 34px 26px 25px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 8px;
        @media (max-width: 768px) {
          margin-top: 35px;
          padding: 12px 25px;
        }

        .heading {
          font-size: 20px;
          line-height: 66px;
          color: #0B254E;
          @media (max-width: 768px) {
            font-size: 15px;
          }
        }

        .checkout_products {
          display: flex;
          flex-direction: column;
          gap: 26px;
        }

        .user_card_totals {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            justify-content: space-between;

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 32px;
              color: #0B254E;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
          }
        }

        .total_block {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid rgba(11, 37, 78, 0.1);
          padding-top: 6px;
          margin-top: 20px;
          @media (max-width: 768px) {
            margin-top: 10px;
          }

          span {
            font-weight: 700;
            font-size: 32px;
            line-height: 66px;
            color: #0B254E;
            @media (max-width: 768px) {
              font-size: 23.52px;
              line-height: 49px;
            }
          }
        }
      }
    }

    .pay_block {
      .dark_btn {
        font-weight: 700;
        font-size: 39.6px;
        line-height: 53px;
        height: 97px;
        margin-top: 21px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 29.106px;
          line-height: 39px;
          height: 70px;
        }
      }

      .check_out {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }

      .secure_text {
        font-weight: 400;
        font-size: 19.2px;
        line-height: 38px;
        color: #0B254E;
        text-align: center;
        margin-top: 17px;
        @media (max-width: 768px) {
          font-size: 14.112px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>