<template>
 <div class="tooltip" :style="cssProps" :class="{ active: active, center: center }">
  <span class="tooltip-text">{{ tooltipText }} </span>
 </div>
</template>

<script>
export default {
 name: "BaseToolTip",
 props: {
  active: {
   required: false,
  },
  center: {
   required: false,
  },
  tooltipText: {
   required: true,
  },
  bgc: {
   required: false,
  },
 },
 computed: {
  cssProps() {
   return {
    "--bgc": this.bgc || "#FA4769FF",
   };
  },
 },
};
</script>

<style scoped lang="scss">
.tooltip {
 align-items: center;
 background-color: var(--bgc);
 bottom: -5px;
 color: #fff;
 display: none;
 min-height: 2.1rem;
 padding: 4px 16px;
 right: 0;
 width: -webkit-fit-content;
 width: -moz-fit-content;
 width: fit-content;
 z-index: 8;
 transform: translate(0%, 100%);
 transform-origin: center top 0px;
 position: absolute;
 border-radius: 0.25rem;

 .tooltip-text {
  &::after {
   content: "";
   position: absolute;
   bottom: 100%;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: transparent transparent var(--bgc) transparent;
  }
 }

 &.center {
  right: -50%;
  transform-origin: center top;
 }
 &.active {
  display: flex;
 }
}
</style>
