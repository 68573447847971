import { createRouter, createWebHistory } from "vue-router";

import PageIndex from "./pages/PageIndex/PageIndex.vue";
import LeaderboardPage from "./pages/LeaderboardPage/LeaderboardPage.vue";
import ProfilePage from "./pages/ProfilePage/ProfilePage.vue";
import LoginPage from "./pages/LoginPage/LoginPage.vue";
import ContactPage from "./pages/ContactPage/ContactPage.vue";
import RegisterPage from "./pages/RegisterPage/RegisterPage.vue";
import VerifyPage from "./pages/VerifyPage/VerifyPage.vue";
import StripeVerifyPage from "./pages/VerifyPage/StripeVerifyPage.vue";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword.vue";
import ResetPassword from "./pages/ResetPassword/ResetPassword.vue";
import ChatPage from "./pages/ChatPage/ChatPage.vue";
import PageNotFound from "./pages/PageNotFound/PageNotFound.vue";
import SettingsPage from "./pages/SettingsPage/SettingsPage.vue";
import FavoritesPage from "./pages/FavoritesPage/FavoritesPage.vue";
import LibraryPage from "./pages/LibraryPage/LibraryPage.vue";
import RestrictedGifts from "@/router/pages/RestrictedGifts/RestrictedGifts.vue";
import Dashboard from "@/router/pages/Dashboard.vue";
import CheckoutPage from "@/router/pages/CheckoutPage/CheckoutPage.vue";
import SuccessPage from "@/router/pages/SuccessPage/SuccessPage.vue";
import FailPage from "@/router/pages/FailPage.vue";
import AlmostTherePage from "@/router/pages/VerifyPage/AlmostTherePage.vue";
import TwitterPage from "@/router/pages/TwitterPage.vue";
import PrivacyPolicy from "@/router/pages/PrivacyPolicy.vue";
import StripeSuccess from "@/router/pages/StripeSuccess.vue";

const routes = [
    {
        path: "/",
        component: PageIndex,
        name: "home",
    },
    {
        path: "/leaderboard",
        component: LeaderboardPage,
        name: "leaderboard",
    },
    // {
    //     path: "/profile",
    //     component: ProfilePage,
    //     meta: {
    //         middleware: "auth",
    //     },
    //     name: "profile",
    //     params: true,
    //     props: true,
    // },
    {
        path: "/login",
        component: LoginPage,
        name: "login",
        meta: {
            middleware: "guest",
        },
    },
    {
        path: "/register",
        component: RegisterPage,
        name: "register",
        meta: {
            middleware: "guest",
        },
    },
    {
        path: "/dashboard",
        component: Dashboard,
        params: true,
        name: "dashboard",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/contact",
        component: ContactPage,
        name: "contact",
    },
    {
        path: "/verify",
        component: VerifyPage,
        props: true,
        name: "verify",
    },
    {
        path: "/email-verify",
        component: AlmostTherePage,
        name: "email-verify",
        meta: {
            middleware: "guest",
        },
    },
    {
        path: "/forgot-password",
        component: ForgetPassword,
        props: true,
        name: "forget-password",
        meta: {
            middleware: "guest",
        },
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        props: true,
        name: "reset-password",
        meta: {
            middleware: "guest",
        },
    },
    {
        path: "/chat/:id?",
        component: ChatPage,
        props: true,
        name: "chat",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/stripe-account-validate",
        component: StripeVerifyPage,
        props: true,
        name: "stripe-verify",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/stripe-success",
        component: StripeSuccess,
        props: true,
        name: "stripe-success",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/settings",
        component: SettingsPage,
        props: true,
        name: "settings",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/favorites",
        component: FavoritesPage,
        props: true,
        name: "favorites",
        meta: {
            middleware: "auth",
        },
    },
    {
        path: "/twitter",
        component: TwitterPage,
        props: true,
        name: "twitter",
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
        props: true,
        name: "privacy-policy",
    },
    {
        path: "/library",
        component: LibraryPage,
        props: true,
        name: "library",
    },
    {
        path: "/create-post",
        name: "create-post",
        component: () => import(/* webpackChunkName: "CreatePost" */ "../router/pages/CreatePostPage/CreatePostPage.vue"),
    },
    {
        path: "/restricted-gifts",
        name: "restricted-gifts",
        component: RestrictedGifts,
    },
    {
        path: "/:username?",
        component: ProfilePage,
        name: "user-profile",
        params: true,
        props: true,
    },
    {
        path: "/checkout",
        component: CheckoutPage,
        name: "checkout",
        props: true,
    },
    {
        path: "/success",
        component: SuccessPage,
        name: "success",
    },
    {
        path: "/error",
        component: FailPage,
        name: "error",
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
    },
]

const route = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
})

route.beforeEach((to, from, next) => {
    const access_token = localStorage.getItem("access_token");

    if (to.meta.middleware === "guest") {
        if (access_token) {
            next({
                name: "home",
                params: {
                    code: 301,
                },
            });
        }
        next();
        return;
    } else if (access_token) {
        next();
        return;
    } else if (to.meta.middleware === "auth") {
        next({
            name: "home",
            params: {
                code: 301,
                with: "no-login",
            },
        });
        return;
    }
    next();
    return;
});

export default route;
