<template>
    <base-modal
            class="chooseToCreate"
            @close="$emit('close')"
            :padding="'14px 13px 17px 13px'"
    >
        <template v-slot:body>
            <div
                    class="d-flex flex-column align-items-center modal-body-main choose-create-popup"
            >
                <h3>Choose to Create</h3>

                <div
                        class="chooseToCreate__products create-grid-main d-flex flex-row justify-content-center align-items-center"
                >
                    <div class="create-card text-center" :class="{active: active === card.cap}"
                         v-for="(card, index) in cards" :key="index" @click="active = card.cap">
                        <div class="icon">
                            <img :src="card.icon" alt="Icon1"/>
                        </div>
                        <h4>{{ card.cap }}</h4>
                    </div>
                </div>

                <button @click="choose()" class="btn primary py-3">Next</button>
            </div>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "../UI/BaseModalBuy";

export default {
    name: "ChooseToCreate",
    components: {
        "base-modal": BaseModal,
    },
    data() {
        return {
            active: null,
            openModal: false,
            cards: [
                {
                    icon: require("@assets/images/membership-icon.svg"),
                    cap: "Member",
                },
                {
                    icon: require("@assets/images/gift-icon.svg"),
                    cap: "Wishlist",
                },
                {
                    icon: require("@assets/images/article-icon.svg"),
                    cap: "Post",
                },
            ],
        };
    },
    methods: {
        choose() {
            if (this.active) {
                this.$emit('selected', this.active)
            }
        },
    },
};
</script>

<style scoped lang="scss">
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0b254e;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 13px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0b254e;
}

.buttons {
  display: flex;
  padding: 40px;
  gap: 10px;
  width: 100%;

  button {
    width: 100%;
  }
}

.btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 3px 0;
  width: 100%;
  max-width: unset;
}

.chooseToCreate__products {
  width: 100%;
  justify-content: space-between;
  gap: 8px;

  .create-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    cursor: pointer;
    transition: all .3s;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-bottom: 13px;

    h4 {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #0B254E;
    }

    &.active {
      border-color: #00aff0;
    }
  }
}
</style>
