<template>
  <div class="completeModal">
    <BaseModalBuy padding="8px 9px" class="complete" :close="false">
      <template v-slot:body>
        <div class="completeModal__wrapper">
          <h3>{{ title }}</h3>
          <img
            class="completeModal__img"
            :src="require('@/assets/images/buy/complete.svg')"
            alt=""
          />
          <div class="completeModal__note">
            <a href="">View profile</a> to see it!
          </div>
          <div class="completeModal__tools">
            <span @click="$emit('close')">Close</span>
          </div>
        </div>
      </template>
    </BaseModalBuy>
  </div>
</template>

<script>
import BaseModalBuy from "@/router/UI/BaseModalBuy.vue";

export default {
  name: "CompleteModal",
  components: {
    BaseModalBuy,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #0b254e;
  margin-bottom: 29px;
  margin-top: 26px;
}

.completeModal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.completeModal__img {
  margin-bottom: 18px;
}

.completeModal__note {
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 10px;
}

.completeModal__tools{
  margin-bottom: 21px;

  span{
    font-weight: 700;
    color: #0b254e;
    cursor: pointer;
  }
}

a {
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height, or 200% */

  text-align: center;

  /* PRIMARY */

  color: #00aff0;
}
</style>
