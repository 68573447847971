<template>
  <label class="reward">
    <span v-if="label">{{ label }}</span>
    <span class="support">Support me on a monthly basis</span>
    <span class="support">Exclusive content</span>
  </label>
</template>

<script>
export default {
  name: "RewardInput",
  props: {
    label: {
      type: String,
    },
    value: {
      require: false,
    },
    support: {
      type: String,
      required: false,
    },
  },
  methods: {
    action(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
label {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 21px;

  span {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0b254e;
    margin-bottom: 6px;
  }
}

input {
  background: #eeeeee;
  border-radius: 12px;
  min-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
}

.labelPrice {
  input {
    padding-left: 40px;
  }

  span {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 35px;
      height: 45px;
      padding: 10px 12px 11px 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      background: url("../../../assets/images/$.svg") no-repeat center center;
      color: #0b254e;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 12px;
    }
  }
}

.support {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0b254e;
  text-align: left;
  padding-left: 19px;
  display: inline-block;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    background: url("@/assets/images/buy/support.svg") no-repeat center center;
    position: absolute;
    left: 0;
    top: calc(50% - 7px);
  }
}

input {
  width: 100%;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* BLACK */

    color: #0b254e;
    opacity: 0.5;
  }
}

.reward__container {
  padding-right: 31px;
  display: block;
  position: relative;

  &:before{
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    background: url('@/assets/images/buy/plus.svg') no-repeat center center;
    top: calc(50% - 10px);
    right: 0;
  }
  
}
</style>
