<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#202429"/>
        <path d="M16.8002 7.19998L7.2002 16.8" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.2002 7.19998L16.8002 16.8" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "CloseBlack"
}
</script>