<template>
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.24175 0.110096C0.660171 0.322294 0.275071 0.699534 0.086451 1.24968C0.0235775 1.4383 0 3.03371 0 6.68823V11.8674H1.68972C2.61711 11.8674 3.37945 11.8439 3.37945 11.8203C3.37945 11.7888 3.2144 11.5924 3.01793 11.3802C2.3106 10.6178 1.94908 9.4861 2.0984 8.48798C2.38133 6.68037 3.89815 5.34431 5.68219 5.34431C6.64101 5.34431 7.4505 5.65082 8.11067 6.26383L8.48792 6.62536L8.4722 3.33236L8.44862 0.0393629L4.99058 0.0236454C2.26344 0.00792694 1.46967 0.0315037 1.24175 0.110096Z" fill="#00AFF0"/>
        <path d="M9.76908 2.24777C9.80052 3.48952 9.82409 4.8413 9.82409 5.25784V6.01232L9.96556 5.77655C10.1699 5.4386 10.8222 4.87274 11.3016 4.61339C11.781 4.35403 12.7791 4.08682 13.2743 4.08682C15.6085 4.09468 17.534 6.02018 17.5261 8.35436C17.5261 9.47036 17.0938 10.4999 16.2922 11.2937L15.7971 11.7888H20.6698H25.5425V6.61748C25.5425 1.5876 25.5346 1.44614 25.3774 1.10033C25.1809 0.660218 24.9609 0.424443 24.5601 0.212245L24.2457 0.0393429L16.9838 0.0157661L9.72192 4.76837e-05L9.76908 2.24777Z" fill="#00AFF0"/>
        <path d="M12.6847 5.34426C12.2132 5.43857 11.8202 5.61933 11.388 5.94156C11.0893 6.16162 10.94 6.35024 10.7357 6.78249C10.3113 7.67058 9.98905 9.23455 9.85544 10.9793C9.79257 11.8359 9.64325 11.7652 11.1208 11.5923C13.0384 11.3644 14.5159 10.9793 15.129 10.547C15.5691 10.2405 15.9306 9.72968 16.0799 9.21884C16.2528 8.62154 16.2057 7.56841 15.9777 7.06542C15.687 6.41311 14.9168 5.70578 14.2409 5.47001C13.8322 5.32854 13.0698 5.26567 12.6847 5.34426Z" fill="#00AFF0"/>
        <path d="M4.88856 6.74311C3.7647 7.14393 3.1281 8.28351 3.37174 9.44667C3.48177 9.97323 3.85901 10.5705 4.23625 10.822C4.77853 11.1914 6.24034 11.5294 7.85933 11.6708L8.45663 11.7258L8.39375 10.9871C8.24443 9.29734 7.88291 7.87483 7.45851 7.36398C6.91623 6.70381 5.76879 6.42874 4.88856 6.74311Z" fill="#00AFF0"/>
        <path d="M15.6005 13.0224L10.7278 13.046L12.4175 14.7357L14.1072 16.4254L13.675 16.8577L13.2427 17.2899L11.5373 15.5845L9.82397 13.8712V19.4355V24.9919H16.9994C23.7897 24.9919 24.1827 24.9841 24.4892 24.8426C24.8743 24.6619 25.3301 24.151 25.448 23.7423C25.5188 23.5301 25.5423 21.9504 25.5423 18.2016V12.9674L23.0117 12.9831C21.6127 12.9988 18.2805 13.0146 15.6005 13.0224Z" fill="#00AFF0"/>
        <path d="M0.0943316 18.4533L0.117909 23.8604L0.337966 24.1905C0.455854 24.3713 0.699489 24.6149 0.88025 24.7328L1.21034 24.9528L4.91987 24.9764L8.6294 25L8.59011 19.5143C8.56653 16.4964 8.53509 14.0129 8.51152 13.9893C8.4958 13.9736 7.90636 14.5316 7.20689 15.231L5.9337 16.5042L5.50145 16.072L5.06919 15.6397L6.36596 14.343L7.66272 13.0462H3.86674H0.0786133L0.0943316 18.4533Z" fill="#00AFF0"/>
    </svg>
</template>

<script>
export default {
    name: "FooterLogo"
}
</script>