<template>
  <div class="report_modal_wrapper">
    <div class="report_modal_overlay" @click="$emit('close')"></div>
    <div class="report_modal">
      <div class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="report_modal_content">
        <div class="report_modal_title">Reason for report</div>
        <div class="report_modal_textarea">
          <textarea v-model="reason" placeholder="Type message here ..."></textarea>
        </div>
        <button class="primary" @click="sendReport">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportModal",
  data() {
    return {
      reason: null,
    }
  },
  props: {
    id: {
      type: Number,
    }
  },
  methods: {
    sendReport() {
      this.axios.post('/report', {
        reason: this.reason,
        reportedUser: this.id,
      }).then(() => {
        this.toast.info('Report sent')
        this.$emit('close')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.report_modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;

  .report_modal_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .report_modal {
    position: absolute;
    left: 50%;
    bottom: -100%;
    transform: translate(-50%, 50%);
    width: 100%;
    max-width: 400px;
    padding: 32px 48px 44px 42px;
    background: #fff;
    border-radius: 14px;
    text-align: center;
    z-index: 9999;
    transition: bottom 0.3s ease-in-out;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform: none;
      bottom: -100%;
      left: 0;
    }

    &:before {
      content: '';
      display: none;
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
      width: 89px;
      height: 2px;
      background: rgba(11, 37, 78, 0.2);
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    .close {
      position: absolute;
      right: 12px;
      top: 9px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .report_modal_title {
      font-weight: 700;
      font-size: 17.3333px;
      line-height: 29px;
      color: #0B254E;
      margin-bottom: 23px;
    }

    textarea {
      width: 100%;
      border: 1.08333px solid rgba(0, 0, 0, 0.1);
      border-radius: 7.34067px;
      font-weight: 400;
      font-size: 10.8333px;
      line-height: 29px;
      color: rgba(11, 37, 78, 0.5);
      padding: 5px 12px;
      height: 95px;
    }

    .primary {
      width: 100%;
      font-weight: 700;
      font-size: 14.6813px;
      line-height: 22px;
      color: #FFFFFF;
      margin-top: 30px;
    }

  }
}

.report_modal_wrapper.active {
  visibility: visible;

  .report_modal_overlay {
    opacity: 1;
  }


  .report_modal {
    bottom: 50%;
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
    }
  }
}
</style>