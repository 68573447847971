<template>
    <base-modal
            @close="$emit('close')"
            :width="'480px'"
            :height="'146px'"
    >
        <template v-slot:body>
            <div class="d-flex flex-column align-items-center gap-30 modal-body-main">
                <div class="text">
                    <p>Remove Favorite?</p>
                </div>
                <div class="action">
                    <a @click="this.$emit('result', true)">Remove</a>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
import BaseModal from "../UI/BaseModal"

export default {
    name: "ConfirmDeleteFavoriteModal",
    components: {
        'base-modal': BaseModal,
    },
    data() {
        return {
            openModal: false,
            isLgView: false,
        }
    },
    props: {
        value: {
            required: false
        },
        receiver: {
            required: false
        },
    }
}
</script>

<style scoped lang="scss">

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #0B254E;
}

.action {
  display: flex;
  width: 100%;
  justify-content: center;

  a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00AFF0;
    cursor: pointer;
  }
}

</style>