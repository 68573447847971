<template>
  <div class="dashboard_input">
    <input :type="type" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)"
           :value="modelValue">
    <div v-if="price" class="price">{{ currencyCode }}</div>
  </div>
</template>

<script>
import currencyHelper from "@/mixins/currencyHelper";

export default {
  name: "InputDashboard",
  mixins: [currencyHelper],
  props: {
    modelValue: {
      required: true
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    price: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard_input {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    border: 1px solid rgba(11, 37, 78, 0.2);
    border-radius: 13.3px;
    font-weight: 300;
    font-size: 18.62px;
    line-height: 23px;
    color: rgba(11, 37, 78, 0.5);
    padding: 21px 30px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 17px;
      padding: 15px 14px 15px 22px;
    }
  }

  .price {
    font-weight: 700;
    font-size: 18.62px;
    line-height: 23px;
    color: rgba(11, 37, 78, 0.5);
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>