import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            loading: "loading"
        }),
    },
    methods: {
        ...mapActions({
            setLoading: 'setLoading',
        }),
    }
}