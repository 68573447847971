<template>
  <svg id="loadingSvg"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       style="margin: auto; background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
       width="24px" height="auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" fill="none" :stroke="color" stroke-width="7" r="24"
            stroke-dasharray="113.09733552923255 39.69911184307752"
            style="animation-play-state: running; animation-delay: 0s;">
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2.272727272727273s"
                        values="0 50 50;360 50 50" keyTimes="0;1"
                        style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
    </circle>
  </svg>
</template>

<script>

export default {
  name: 'LoadingIcon',
  props: {
    color: {
      required: true
    }
  }
}

</script>

<style>
#loadingSvg {
  width: 24px;
  height: auto;
}
</style>