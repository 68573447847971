<template>
  <div class="delete-chat-modal-wrapper">
    <div class="modal-overlay" @click="$emit('close')"></div>
    <div class="delete-chat-modal">
      <div class="modal-close">
        <svg @click="$emit('close')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <h3>Delete Chat</h3>
      <p class="text">
        Are you sure you want to delete the chat?
        All data will be lost.
      </p>

      <base-button
          class="delete-button"
          @click="$emit('delete')"
      >
        Delete
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "../UI/BaseButton";

export default {
  name: "DeleteChatConfirmModal",
  components: {
    'base-button': BaseButton,
  }
}
</script>

<style scoped lang="scss">
.delete-chat-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .modal-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: table;
    transition: opacity .3s ease;
  }

  .delete-chat-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 12px;
    z-index: 9999;
    max-width: 352px;
    width: 100%;
    padding: 67px 12px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0,0,0,.33);

    .modal-close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #0B254E;
      margin-bottom: 8px;
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #0B254E;
      margin-bottom: 32px;
      white-space: pre-line;
    }
    .delete-button {
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #EC8D8D;
    }
  }
}
</style>