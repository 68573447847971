<template>
    <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.12599 1.15411L1.16837 7.19619L7.21045 13.1538" stroke="white" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "BackIcon"
}
</script>

<style lang="scss" scoped>
svg {
    @media (max-width: 768px) {
        width: 6px;
    }
}
</style>