<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.4014 0.849083C13.7517 0.952443 13.0762 1.2293 12.5447 1.60582C12.242 1.82361 8.75358 5.22709 8.63176 5.42643C8.53578 5.58147 8.55055 5.69221 8.67237 5.81772C8.77203 5.91369 8.80526 5.92477 9.03412 5.92477C9.57307 5.92477 10.2449 6.04658 10.7912 6.24223C11.2453 6.40465 11.1124 6.49324 12.5889 5.02406C13.3088 4.30793 13.9695 3.6767 14.0544 3.62133C14.7706 3.17836 15.7266 3.26695 16.3505 3.83543C17.0113 4.44451 17.1405 5.50764 16.6384 6.26068C16.4723 6.5117 12.2973 10.6793 12.0758 10.8159C11.4003 11.233 10.5218 11.185 9.9053 10.6978C9.24453 10.181 8.8274 10.0924 8.28477 10.3508C8.0153 10.4837 7.82334 10.6793 7.69414 10.9562C7.60555 11.1518 7.59817 11.2072 7.61293 11.4914C7.63508 11.8901 7.74213 12.1263 8.04483 12.4143C8.61699 12.968 9.37742 13.3629 10.1932 13.5328C10.5255 13.5992 10.651 13.6066 11.1493 13.5881C12.1718 13.5512 12.7809 13.3666 13.5487 12.8535C13.9437 12.5914 18.4804 8.05471 18.7278 7.67449C19.6211 6.30498 19.7281 4.66969 19.0231 3.25219C18.3845 1.9602 17.1478 1.06318 15.7266 0.845392C15.3058 0.782638 14.8222 0.786329 14.4014 0.849083Z"
        :fill="color"/>
    <path
        d="M8.21853 6.9399C7.6279 7.05064 7.06312 7.28689 6.50572 7.64865C6.22148 7.82952 1.76226 12.2703 1.47064 12.6579C1.20855 13.0086 0.883704 13.6583 0.776654 14.0459C0.485032 15.1053 0.555169 16.1167 0.987064 17.0913C1.4005 18.0178 2.26798 18.8853 3.19452 19.2987C4.37208 19.8229 5.66408 19.8192 6.85271 19.284C7.47656 19.0034 7.69066 18.8188 9.66925 16.8366C11.5076 14.9945 11.5297 14.9724 11.5039 14.8506C11.4633 14.6254 11.3636 14.5774 10.869 14.5516C10.3042 14.522 9.87597 14.4445 9.38132 14.2858C9.16353 14.2157 8.94574 14.1566 8.89775 14.1566C8.8313 14.1566 8.46954 14.4925 7.48025 15.4818C6.67921 16.2755 6.07751 16.8439 5.97046 16.9067C5.67515 17.0765 5.37245 17.154 5.00331 17.1577C4.46806 17.1614 4.04355 16.9879 3.67072 16.6151C3.29788 16.2422 3.12808 15.8288 3.12808 15.2825C3.12439 14.8838 3.22036 14.5663 3.43077 14.2415C3.61904 13.9573 7.82355 9.7712 8.04872 9.6457C8.14101 9.59402 8.32927 9.5165 8.46585 9.46851C8.67995 9.39837 8.77593 9.3873 9.09339 9.40206C9.63972 9.4279 9.80583 9.49804 10.4481 9.99269C10.7213 10.2031 10.8948 10.2622 11.2602 10.2622C11.777 10.2585 12.2237 9.95577 12.4046 9.48327C12.5153 9.18427 12.4969 8.78929 12.3529 8.49767C12.0834 7.94396 11.0388 7.25736 10.0901 7.00634C9.62865 6.88822 8.68734 6.85499 8.21853 6.9399Z"
        :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "CopyIcon",
  props: {
    color: {
      type: String,
      default: "#0B254E"
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  @media (max-width: 768px) {
    width: 12px;
    height: 12px;
  }
}
</style>