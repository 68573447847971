<template>
    <div class="send-to-favorites-slider">
        <div class="th-container">

            <div class="slider-header">
                <div class="top">
                    <h1 class="title text-center">Send to favorites</h1>
                    <base-button class="primary">
                        Deselect All
                    </base-button>
                </div>
                <div class="bottom">
                    <p>0 Selected</p>
                </div>
            </div>

            <VueSlickCarousel :arrows="true" :dots="false" :slidesToShow="slidersToShow" :swipeToSlide="true"
                              :touchMove="true">

                <div class="favorite-user-block" v-for="(index) in 9" :key="index">
                    <img @click="selectUser($event)" width="91" src="@assets/images/user-default.png" alt="#">
                </div>

                <template #prevArrow="">
                    <div class="custom-arrow">
                        <ArrowLeft/>
                    </div>
                </template>
                <template #nextArrow="">
                    <div class="custom-arrow">
                        <ArrowRight/>
                    </div>
                </template>


            </VueSlickCarousel>
        </div>
    </div>
</template>


<script>

import BaseButton from '../../../UI/BaseButton.vue'

export default {
    name: "LibrarySlider",
    data() {
        return {
            selectedCount: 0,
            selectedUsers: [],
        }
    },
    components: {
        'base-button': BaseButton
    },
    props: {
        data: {
            required: false
        },
        slidersToShow: {
            required: false
        }
    },
    methods: {
        selectUser(e) {
            !e.target.classList.contains('selected') ? e.target.classList.add('selected') : e.target.classList.remove('selected');
        }
    }
}
</script>

<script setup>
import "./SendToFavoritesSlider.scss";
import "../LibraryPage.scss";

import ArrowLeft from '../../../../assets/svg/ArrowLeft.vue'
import ArrowRight from '../../../../assets/svg/ArrowRight.vue'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

</script>

