<template>
  <div class="not-found-container">
    <div class="not-found-container-main">
      <div class="form-section">
        <div class="not-found-form">
          <div class="not-found-form-title">
            <h2>404</h2>
          </div>
          <div class="not-found-form-small-title">
            <p>Oops! We can’t find what you’re looking for</p>
          </div>
          <div class="not-found-form-textarea">
            <div class="not-found-form-small-title text-area">
              <p>Send us a message here and we will get back to you</p>
            </div>
            <base-textarea
                :placeholder="'Message'"
                :area-name="'message'"
                v-model="form.message"
                v-validate="'required|max:500'"
            />
            <span v-if="errors.first('message')" class="error_msg mb-2 red">{{ errors.first('message') }}</span>
          </div>
          <div class="not-found-form-button">
            <base-button
                @click="sendMessage"
                class="primary"
            >
              Submit
            </base-button>
          </div>
        </div>
      </div>
      <div class="image-section">
        <div class="anim">
          <div
              class="hero-section-bg"
              v-observe-visibility="{
          callback: onObserveVisibilityHero2,
          once: true,
        }"
          >
            <transition name="fade-in-down">
              <LottieAnimation
                  v-if="$store.state.siteMode === 'light'"
                  :auto-play="showHero2"
                  path="animations/json-1.json"
              />
              <LottieAnimation
                  v-else
                  :auto-play="showHero2"
                  path="animations/dark-json-1.json"
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <IndexLeaderboard/>
  </div>
</template>

<script>
import BaseTextArea from "../../UI/BaseTextArea";
import BaseButton from "../../UI/BaseButton";

export default {
  name: "PageNotFound",
  components: {
    'base-textarea': BaseTextArea,
    'base-button': BaseButton
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        message: null,
      }
    }
  },
  methods: {

    toastrMessage(data, error = false) {
      this.$toastr.defaultClassNames = ["animated"];
      if (!error) {
        this.$toastr.s(data.data.message);
      } else {
        this.$toastr.e(data.message);
      }
      this.$toastr.defaultTimeout = 2000;
      this.$toastr.positionClass = "toast-bottom-center";
    },

    clearForm() {
      this.form.name = null
      this.form.email = null
      this.form.message = null
    },

    sendMessage() {
      const self = this
      this.$validator.validate().then(valid => {
        if (valid) {
          this.axios({
            url: 'contact',
            method: "POST",
            data: this.form,
          }).then((res) => {
            this.toastrMessage(res);
            this.clearForm();
            this.$validator.reset();
          }).catch(function (error) {
            self.toastrMessage(error, true);
          });
        }
      });
    },
  },
}
</script>

<script setup>
import './PageNotFound.scss'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import IndexLeaderboard from '../PageIndex/IndexLeaderboard/IndexLeaderboard.vue';
</script>