<template>
    <div class="file">
        <span class="file__cap" v-if="label">Upload image</span>
        <label>
            <span class="file__button">Browse</span>
            <input @change="previewImage" ref="file_input" type="file"/>
        </label>
        <ProductItem :product-data="product"/>
    </div>
</template>

<script>
import ProductItem from "@/router/components/ProductItem.vue";

export default {
    name: "FileInput",
    components: {
        ProductItem,
    },
    props: {
        value: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        productName: {
            type: String,
            required: false,
        },
        productPrice: {
            type: String,
            required: false,
        }
    },
    watch: {
        productName(value) {
            this.product.name = value;
        },
        productPrice(value) {
            this.product.price = value;
        }
    },
    data() {
        return {
            url: null,
            product: {
                name: '',
                price: "",
                image: null,
                showProductName: true,
            },
        };
    },
    methods: {
        previewImage() {
            const [file] = this.$refs.file_input.files;
            if (file) {
                this.product.image = URL.createObjectURL(file);
                this.$emit('image', file);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
img.file__image {
  width: 176px;
  height: 173px;
}

label {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 0px;
}

span.file__cap {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0b254e;
  margin-bottom: 13px;
  display: block;
}

.file {
  width: 100%;

  span {
    text-align: center;
    margin-bottom: 13px;
  }

  input {
    display: none;
  }

  span.file__button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #00aff0;
    cursor: pointer;
    padding: 12px;
    border: 1px solid #00aff0;
    border-radius: 8px;
    display: block;
  }
}
</style>
