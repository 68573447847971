<template>
  <div class="choose-products-modal-wrapper">
    <div class="modal-overlay" @click="$emit('close')"></div>
    <div class="choose-products-modal">
      <div class="modal-close">
        <CloseIcon @click="$emit('close')"/>
      </div>
      <h3>Select product to send</h3>

      <ul class="choose-product-tabs">
        <li v-for="tab in tabs" :key="tab" :class="{ 'active' : activeTab === tab }" @click="changeTab(tab)">{{ tab }}</li>
      </ul>

      <div class="products-section-footer">
        <div class="sort-block">
          <p class="sort-name">SORT BY</p>
          <div class="filter-dropdown">
            <base-select
                :options="filterOptions"
                :selected="selectedFilter"
                @selected="changeSort"
                :text="selectedFilter"
                :no-show-seleceted="true"
                class="select"
            />
          </div>
        </div>
        <div class="search-block">
          <base-input
              v-model="productsSearch"
              :input-type="'text'"
              :input-class="'searchInput'"
              :placeholder="'Search content'"
          />
        </div>
      </div>

      <div v-if="activeTab === 'Wishlist'" class="products-count">Products: {{ filteredProducts.length }}</div>

      <template v-if="activeTab === 'Wishlist'">
        <div class="choose-products-block">
          <product-item
              v-for="product in filteredProducts"
              :key="product.id"
              :product-data="product"
              :selectable="true"
              :selected-product="selectedProduct"
              @select-product="selectProduct(product)"
          />
        </div>

        <base-button
            class="secondary"
            @click="sendProduct"
        >
          Send
        </base-button>
      </template>
      <template v-else>
        <div class="members">
          <member-item
              v-for="membership in filteredMemberships"
              :key="membership.id"
              :membership="membership"
              :user="user"
              :selectable="true"
              :selected="selectedMembership"
              @selected="selectMembership(membership)"
          />
        </div>

        <base-button
            class="secondary"
            @click="sendMembership"
        >
          Send
        </base-button>
      </template>
    </div>
  </div>

</template>

<script>
import BaseButton from "../UI/BaseButton";
import BaseInput from "../UI/BaseInput";
import BaseSelect from "../UI/BaseSelect";
import ProductItem from "../components/ProductItem";
import MemberItem from "../components/MemberItem";
import CloseIcon from "@/router/components/icons/CloseIcon.vue";

export default {
  name: "ChooseProductModal",
  components: {
      CloseIcon,
    'base-input': BaseInput,
    'base-select': BaseSelect,
    'base-button': BaseButton,
    'product-item': ProductItem,
    'member-item': MemberItem,
  },
  props: {
    user: {
      required: true
    },
    products: {
      required: true
    },
  },
  data() {
    return {
      filterOptions: ['Newest', 'Best Selling', 'Oldest'],
      selectedFilter: "Newest",
      productsSearch: null,
      tabs: ['Member', 'Wishlist', 'Posts'],
      activeTab: 'Wishlist',
      filteredProducts: [],
      filteredMemberships: [],
      selectedProduct: null,
      selectedMembership: null,
    }
  },
  created() {
    const products = this.products
    this.filteredProducts = products.sort((a, b) => b.id - a.id)
    const memberships = this.user.memberships
    this.filteredMemberships = memberships.sort((a, b) => b.id - a.id)
  },
  watch: {
    productsSearch(value) {
      if(value) {
        this.filteredProducts = this.products.filter(product => product.name.toLowerCase().includes(value.toLowerCase()))
        this.filteredMemberships = this.user.memberships.filter(membership => membership.name.toLowerCase().includes(value.toLowerCase()))
      } else {
        this.filteredProducts = this.products
        this.filteredMemberships = this.user.memberships
      }
    },
    selectedFilter(value) {
      if(value === 'Newest') {
        this.filteredProducts = this.filteredProducts.sort((a, b) => b.id - a.id)
        this.filteredMemberships = this.filteredMemberships.sort((a, b) => b.id - a.id)
      } else if(value === 'Best Selling') {
        this.filteredProducts = this.filteredProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
        this.filteredMemberships = this.filteredMemberships.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      } else if(value === 'Oldest') {
        this.filteredProducts = this.filteredProducts.sort((a, b) => a.id - b.id)
        this.filteredMemberships = this.filteredMemberships.sort((a, b) => a.id - b.id)
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    selectProduct(product) {
       if (this.selectedProduct === product) {
         this.selectedProduct = null
       } else {
         this.selectedProduct = product
       }
    },
    sendProduct() {
      this.$emit('send-product', this.selectedProduct)
    },
    selectMembership(membership) {
      if (this.selectedMembership === membership) {
        this.selectedMembership = null
      } else {
        this.selectedMembership = membership
      }
    },
    sendMembership() {
      this.$emit('send-membership', this.selectedMembership)
    },
    changeTab(tab) {
      if(tab === 'Posts') {
        this.$toastr.s('test')
      } else {
        this.activeTab = tab
      }
    },
    changeSort(sort) {
      this.selectedFilter = sort
    }
  },
}
</script>

<style lang="scss" scoped>
.choose-products-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .modal-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: table;
    transition: opacity .3s ease;
  }

  .choose-products-modal {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 12px;
    z-index: 9999;
    max-width: 390px;
    width: 100%;
    padding: 35px 12px 28px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0,0,0,.33);

    @media only screen and (max-width: 420px){
      width: 90%;
    }

    .modal-close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #0B254E;
      margin-bottom: 14px;
    }

    .choose-product-tabs {
      display: flex;
      justify-content: center;
      gap: 12px;
      li{
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 12px;
        color: #0B254E;
        opacity: 0.5;
        cursor: pointer;
        &.active{
          opacity: 1;
          color: #00aff0;
          border-bottom: 2px solid #00aff0;
        }
      }
    }

    .products-count {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #0B254E;
      opacity: 0.5;
      margin-bottom: 10px;
    }

    .choose-products-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
      margin: 0 -12px 22px 0;
      max-height: 366px;
      overflow-y: auto;
      padding-right: 10px;

      @media only screen and (max-width: 420px){
        grid-template-columns: repeat(1, 1fr);
      }

      .product {
        width: 100%;
      }
    }

    .members{
      display: flex;
      gap: 14px;
      overflow: auto;
      margin-bottom: 47px;
      padding-bottom: 15px;
    }

    .secondary{
      padding: 5px 24px;
    }
  }
}
</style>

<style lang="scss">
.choose-products-modal{
  .products-section-footer{
    display: flex;
    gap: 0;
    margin: 20px 0 9px;

    @media only screen and (max-width: 420px){
      gap: 10px;
    }

    .sort-block {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 420px){
        width: 100%;
      }
      .sort-name{
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
        margin-right: 6px;
      }
    }
    .search-block{
      margin-left: 10px;
      @media only screen and (max-width: 420px){
        margin-left: 0;
        width: 100%;
      }
      .searchInput{
        &::-webkit-input-placeholder {
          color: rgb(158 173 193 / 60%);
        }
      }
    }
  }
  .filter-dropdown {
    @media only screen and (max-width: 420px){
      width: 100px;
    }
    .dropdown-wrapper {
      &.opened{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .selected-item {
        font-size: 16px;
      }
      .dropdown-popover {
        .options {
          ul {
            li {
              font-size: 16px;
              border-radius: 0;
              border-top: 1px solid rgb(11 37 78 / 20%);
              &:last-child{
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>