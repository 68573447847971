<template>
    <div class="textarea_label">
        <h4>{{ label }}</h4>
        <textarea @input="$emit('update:modelValue', $event.target.value)"></textarea>
    </div>
</template>

<script>
export default {
    name: "TextareaLabel",
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>
.textarea_label {
  width: 100%;

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0B254E;
    margin-bottom: 9px;
  }

  textarea {
    width: 100%;
    height: 176px;
    background: #EEEEEE;
    border-radius: 12px;
    border: none;
    padding: 10px;
  }
}
</style>