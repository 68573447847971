<template>
  <div>
    <div class="chat-message" :class="animationClass" :key="`message_${message.id || index}`" v-for="(message, index) in messages">
      <div class="chat-message-left">
        <div class="chat-message-left-img">
          <template v-if="user.id === message.sender_id">
            <img :src="user.avatar || '/img/user-default.4388e4e4.png'" alt="#">
          </template>
          <template v-else>
            <img v-if="activeUser.avatar" :src="activeUser.avatar" alt="Avatar">
            <img v-else src="@assets/images/user-default.png" alt="#">
          </template>
          <p>{{ user.id === message.sender_id ? 'You' : receiver.username || receiver.username }}</p>
          <base-button
              class="primary rate-blue-button"
              v-if="message.type === 'tip'  && message.object && message.object.status_id == 2 && !rateIsSend(message)"
              @click="$emit('rating', message.object)"
          >
              Rate me
          </base-button>

        </div>
        <div class="chat-message-left-text">
          <div v-if="message.type === 'tip'" class="tip-message">
            <template v-if="message.object && message.object.status_id == 2">
              <p class="tip-message-user">
                {{ message.object.sender_id === user.id ? `${receiver.username} tipped` : `You tipped ${receiver.username}` }}
              </p>

              <div class="tip-message-main">
                  <span class="tip-message-amount" v-if="message.object && message.object.amount">
                {{ formatAmount(conversationAmount(message.object, message.object.amount), currency.code) }}
                </span>
                <div v-if="getRate(message)">
                  <span
                      v-for="i in 5 "
                      :key="`rate_${message.id}${i}`"
                      class="icon-star" :class="{'tip-blue-before' : i <= (getRate(message)['rate'] || 0)}"></span>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="tip-message-user">
                {{
                  message.sender_id === user.id &&  message.object && !message.object.is_send === 1 ? `You requested ${receiver.username}` : `${receiver.username} Requested`
                }}
              </p>
              <div class="tip-message-main">
                  <span class="tip-message-amount" v-if="message.object  && message.object.amount">
                  {{ formatAmount(conversationAmount(message.object, message.object.amount), currency.code) }}
                </span>

                <base-button
                    class="primary tip-message-send"
                    @click="sendTipAction(message)"
                    v-if="message.sender_id !== user.id && message.object && !message.object.is_send === 1"
                >
                    Send
                </base-button>
              </div>
            </template>
          </div>
          <div v-if="message.type === 'product'" class="product-message">
            <product-item
                :product-data="message.object"
                :is-message="true"
            />
          </div>
          <div v-else-if="message.type === 'membership'" class="membership-message">
            <MemberItem
                :membership="message.object"
                :user="user"
                :is-message="true"
            />
          </div>
          <p v-else>{{ message.text }}</p>

        </div>
      </div>
      <div class="chat-message-right">
        <div class="chat-message-right-text" v-if="message.created_at">
          <p>{{ moment(message.created_at).format('MMM D') }}</p>
          <p>{{ moment(message.created_at).format('h:mm A') }}</p>
        </div>
        <div class="chat-message-right-text" v-else>
          <p>{{ moment().format('MMM D') }}</p>
          <p>{{ moment().format('h:mm A') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import BaseButton from "../../../UI/BaseButton";
import currencyHelper from "../../../../mixins/currencyHelper";
import ProductItem from "../../../components/ProductItem.vue";

export default {
  name: "ChatMessage",
  components: {
    'base-button': BaseButton,
    'product-item': ProductItem,
  },
  data() {
    return {
      tip: null,
      sendTipStepTwo: false,
      sendTipStepThree: false,
      sendTipStepFour: false,
      policyChecked: false,
      policyCheckedTwo: false,

      rate_id: null,
      payLoading: false,
      stars: {
        count: null,
        fixed: false
      },
    }
  },
  props: {
    messages: {
      required: false,
    },
    receiver: {
      required: true,
    },
    animationClass: {
      required: false,
    },
    activeUser: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({user: 'auth/user', currency: "currency"})
  },
  mixins: [currencyHelper],
  methods: {
    rateIsSend(msg) {
      if (!msg.object) {
        return null;
      }
      let rate = msg.object.rates;
      if (!rate) {
        return null;
      }
      return rate.findIndex(i => i.rater_id === this.user.id) > -1;
    },
    getRate(msg) {
      if (!msg.object) {
        return null;
      }
      let rate = msg.object.rates;
      if (!rate) {
        return null;
      }
      rate = rate.find(i => i.user_id === this.user.id);

      if (!rate) {
        return null;
      }

      return rate.rater_id !== this.user.id ? rate : null;
    },
    sendTipAction(message) {
      this.$emit('sendTip', message);
    },
    openTipStepTwo() {
      this.sendTipStepTwo = true;
    },
    openTipStepThree() {
      this.sendTipStepTwo = false;
      this.sendTipStepThree = true;
    },
    openTipStepFour() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.payLoading = true
          this.axios.post(
              'tip/sent',
              {
                tip_id: this.tip.id,
                holder: this.card.holder,
                number: this.card.number,
                expiration_date: this.card.expirationDate,
                cvv: this.card.cvv,
                chat_id: this.receiver.id,
              }
          ).then((res) => {
            this.card.holder = null;
            this.card.number = null;
            this.card.expirationDate = null;
            this.card.cvv = null;
            this.$validator.reset();
            this.toast.info(res.data.message);
            this.sendTipStepThree = false;
            this.policyChecked = false;
            this.policyCheckedTwo = false;
            this.sendTipStepFour = true
          }).catch((res) => {
            this.toast.error(res.response.data.message);
          })
        }
      })
    },
    rate(e, number) {
      if (number !== 0 && this.stars.fixed === true && !e.target.classList.contains('icon-star')) {
        this.stars.count = number
        e.preventDefault();
      } else {
        if (number === 0 && !e.target.classList.contains('send-modal-user-rate') && !e.target.classList.contains('icon-star') && this.stars.fixed === false) {
          this.stars.count = 0
        } else if (number !== 0 && this.stars.fixed === false) {
          this.stars.count = number
        }
      }
    },

    sendTipStepFiveByClose() {
      this.axios.post('rate/create',
          {
            user_id: this.receiver.user_id,
            rate: this.stars.count || 0,
            type: 'tip',
            type_id: this.tip.id,
            chat_id: this.receiver.id
          }
      ).then((res) => {
        this.rate_id = res.data.rate_id
        this.sendTipStepFour = false
        this.stars.count = null;
        this.stars.fixed = false
      })
    },

    changedTipStepFive() {
      this.axios.post('rate/create',
          {
            rate_id: this.rate_id || this.tip.id,
            rate: this.stars.count,
            user_id: this.receiver.user_id,
            chat_id: this.receiver.id,
            type_id: this.tip.id,
            type: 'tip',
          }
      ).then(() => {
        this.sendTipStepFour = false
        this.stars.count = null;
        this.stars.fixed = false
      })
    }
  }
}
</script>
<script setup>
import './ChatMessage.scss'
import MemberItem from "@/router/components/MemberItem.vue";
</script>