<template>
  <div class="copy_modal_wrapper">
    <div class="copy_modal_overlay" @click="$emit('close')"></div>
    <div class="copy_modal">
      <div class="copy_modal_content" v-if="product">
        <div class="user_name">{{ name }}’s Wishlist</div>
        <div class="product_info">
          <div class="product_image">
            <img :src="productImage" :alt="product?.name">
          </div>
          <div class="product_left_info">
            <div class="product_name">{{ product?.name }}</div>
            <div class="product_price">{{ formatAmount(convertPrice(product, product.price), currency?.code) }}</div>
          </div>
        </div>
        <div class="close_modal_buttons">
          <button class="white_btn" @click="addToCart">Add To Cart</button>
          <button class="dark_btn" @click="buyNow">Buy Now</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyHelper from "@/mixins/currencyHelper";
import {mapActions, mapGetters} from "vuex";
import preloader from "@/mixins/preloader";

export default {
  name: "CopyWishlistModal",
  mixins: [currencyHelper, preloader],
  data() {
    return {
      reason: null,
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({authenticated: 'auth/authenticated'}),
    productImage() {
      return this.product.image ? process.env.VUE_APP_LIVE_URL + this.product.image : require('@/assets/images/wish-default.svg')
    },
  },
  methods: {
    ...mapActions({
      updateUser: "auth/login",
      updateCartStatus: "cart/updateCartStatus",
    }),
    addToCart() {
      if (this.authenticated) {
        this.setLoading(true);
        this.axios.post('cart/add', {product_id: this.product.id})
            .then((res) => {
              this.updateUser();
              this.toast.info(res.data.message);
            }).catch((res) => {
          this.toast.error(res.response.data.message);
        }).finally(() => {
          this.setLoading(false);
        })
      } else {
        let guestProduct = {...this.product};
        guestProduct.creator_name = this.name;
        this.cart = [...JSON.parse(localStorage.getItem('cart') || '[]'), guestProduct]
        localStorage.setItem('cart', JSON.stringify(this.cart));
        this.updateCartStatus(+new Date());
        this.toast.info('Wish added to cart successfully');
      }
    },
    buyNow() {
      this.addToCart()
      this.$router.push({name: 'checkout'})
    }
  }
}
</script>

<style lang="scss" scoped>
.copy_modal_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;

  .copy_modal_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .copy_modal {
    position: absolute;
    left: 50%;
    bottom: -100%;
    transform: translate(-50%, 50%);
    width: 100%;
    max-width: 468px;
    padding: 24px 40px 40px;
    background: #fff;
    border-radius: 14px;
    text-align: center;
    z-index: 9999;
    transition: bottom 0.3s ease-in-out;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform: none;
      bottom: -100%;
      left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 7px;
      transform: translateX(-50%);
      width: 89px;
      height: 2px;
      background: rgba(11, 37, 78, 0.2);
    }

    .user_name {
      font-weight: 600;
      font-size: 19.2px;
      line-height: 30px;
      text-align: center;
      color: #0B254E;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 25px;
      }
    }

    .product_info {
      margin-top: 24px;
      display: flex;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }

      .product_image {
        max-width: 224px;
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .product_left_info {

        .product_name {
          font-weight: 700;
          font-size: 28.8px;
          line-height: 30px;
          text-align: center;
          color: #0B254E;
          @media screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 25px;
          }
        }

        .product_price {
          font-weight: 900;
          font-size: 28.8px;
          line-height: 30px;
          color: #00AFF0;
          margin-top: 13px;
          @media screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 25px;
            margin-top: 11px;
          }
        }
      }
    }

    .close_modal_buttons {
      display: flex;
      gap: 11px;
      margin-top: 46px;
      @media screen and (max-width: 768px) {
        margin-top: 38px;
        gap: 9px;
      }

      .white_btn {
        flex-basis: calc(50% - 5.5px);
        background: rgba(255, 255, 255, 0.5);
        border: 1.2px solid #202429;
        backdrop-filter: blur(2.4px);
        border-radius: 11.1786px;
        font-weight: 700;
        font-size: 23.0733px;
        line-height: 27px;
        color: #202429;
        padding: 18px 0;
        @media screen and (max-width: 768px) {
          font-size: 19.2277px;
          line-height: 22px;
          padding: 15px 0;
        }
      }

      .dark_btn {
        flex-basis: calc(50% - 5.5px);
        font-size: 23.0733px;
        line-height: 27px;
        @media screen and (max-width: 768px) {
          font-size: 19.2277px;
          line-height: 22px;
        }
      }
    }

  }
}

.copy_modal_wrapper.active {
  visibility: visible;

  .copy_modal_overlay {
    opacity: 1;
  }


  .copy_modal {
    bottom: 50%;
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
    }
  }
}
</style>