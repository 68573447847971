<template>
  <div class="follow_item">
    <div class="follow_item_left">
      <router-link :to="{name: 'user-profile', params: {username: item.username}}" class="avatar">
        <div v-if="item.is_online" class="online"></div>
        <img :src="item.avatar ? item.avatar : require('@/assets/images/user-default.png')" alt="Avatar"/>
      </router-link>
      <h4 class="username">@{{ item.username }}</h4>
    </div>
    <button :class="following ? 'secondary' : 'primary'" @click="toggleFollow">{{ following ? 'Unfollow' : 'Follow' }}</button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SocialItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deleteItem: null,
      deleteIndex: null,
    }
  },
  computed: {
    ...mapGetters({user: 'auth/user', onlineUsers: "auth/onlineUsers"}),
    following() {
      return !!(this.user.favorites && this.user.favorites.find(e => e.id === this.item.id));
    }
  },
  methods: {
    ...mapActions({
      refresh: 'auth/login'
    }),
    toggleFollow() {
      this.axios.post('user/follow', {
        id: this.item.id
      }).then(() => {
        this.refresh()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard_tab_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .follow_item {
    width: calc(100% - 20px);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }

    .follow_item_left {
      display: flex;
      align-items: center;
      gap: 12px;
      @media (max-width: 768px) {
        gap: 11px;
      }

      .avatar {
        position: relative;

        img {
          width: 102px;
          height: 102px;
          border-radius: 50%;
          object-fit: cover;
          @media (max-width: 768px) {
            max-width: 77px;
            max-height: 77px;
          }
        }

        .online {
          position: absolute;
          right: 13px;
          bottom: 11px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: #00c853;
          border: 2px solid #fff;
          @media (max-width: 768px) {
            right: 9px;
            bottom: 4px;
          }
        }
      }

      .username {
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: #0B254E;
        @media (max-width: 768px) {
          font-size: 13.56px;
          line-height: 25px;
        }
      }
    }

    button {
      width: 203px;
      @media (max-width: 768px) {
        font-size: 15.3138px;
        line-height: 23px;
        min-width: 113px;
        max-width: 113px;
        height: 46px;
      }
    }
  }
}
</style>