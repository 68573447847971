<template>
    <form class="buyForm">
        <div class="buyForm__method">
            <label class="buyForm__label_cc" :class="{active: method === 'cc'}">
                <img
                        class="buyForm__icon"
                        :src="require('@/assets/images/buy/cc.svg')"
                        alt=""
                />
                <span>Credit Card</span>
                <input type="radio" value="cc" v-model="method"/>
            </label>
            <label class="buyForm__label_pay" :class="{active: method === 'pay'}">
                <span>Pay</span>
                <input type="radio" value="pay" v-model="method"/>
            </label>
        </div>


        <div class="buyForm__cc" v-show="method === 'cc'">
            <span>Card Information</span>
            <div class="buyForm__card">
                <input class="card_number" type="text">
                <input type="text">
                <input type="text">
            </div>

            <span>Country or Region</span>
            <input type="text">

            <span>Email*</span>
            <input type="text">
        </div>

        <button type="button" class="btn primary" @click="$emit('purchase')">Purchase</button>
        <p class="buyForm__note">
            Payment secured by <b>stripe</b>. By clicking ”Purchase” you agree to the Terms
            and Privacy.
        </p>
    </form>
</template>

<script>
export default {
    name: "buyForm",
    data() {
        return {
            method: "pay",
        };
    },
};
</script>

<style lang="scss" scoped>
.buyForm {
  min-width: 297px;
  width: 297px;

  @media (min-width: 500px) {
    width: 350px;
  }
}

input[type="text"] {
  background: #F3F3F3;
  border-radius: 8px;
  height: 48px;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
}

.buyForm__method {
  display: flex;
  flex-direction: column;

  input {
    display: none;
  }

  label {
    width: 100%;
    background: #f3f3f3;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    box-sizing: border-box;
    max-height: 48px;
    margin-bottom: 19px;
    color: #000000;
    cursor: pointer;

    &.buyForm__label_pay {
      background: #000;
      color: #fff;
      margin-bottom: 35px;
    }

    &.active {
      padding: 6px;
      border: 2px solid #00AFF0;
    }
  }
}

.buyForm__icon {
  margin-right: 7px;
}

.buyForm__card {
  margin-bottom: 11px;

  input {
    width: calc(50% - 2px);
  }

  .card_number {
    width: 100%;
  }
}

.buyForm__cc, .buyForm__card {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #0B254E;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  input {
    margin-bottom: 7px;
  }
}

.btn {
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  height: 59px;
}

.buyForm__note {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #0B254E;

}

.checkbox {
  position: relative;
  padding-left: 38px;
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
  margin-top: 12px;

  input {
    display: none;
  }

  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #0B254E;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 30px;
      height: 30px;
      background: #F3F3F3;
      border-radius: 8px;
    }
  }
}
</style>
