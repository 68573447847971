<template>
    <div class="giftInfo">
        <div class="giftInfo__charged">You’ll be charged <span class="currency">${{ product.price }}</span></div>
        <div class="giftInfo__stats">
            <span>{{ product.name }}</span><span>${{ product.price }}</span>
        </div>
        <div class="giftInfo__description">
            Description can go here
        </div>
        <div class="giftInfo__container_img">
            <img class="gifInfo__img" :src="product.productImage" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: 'giftInfo',
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({
                name: 'Product Name',
                price: '123',
                productImage: require('@/assets/images/buy/item.jpg')
            })
        }
    },
    data() {
        return {}
    }
}

</script>

<style lang="scss" scoped>
.giftInfo {
  width: 100%;
  margin-bottom: 39px;
}
.giftInfo__stats {
  padding: 3px 15px;
  max-width: 407px;
  margin: 0 auto;
  background: #EEEEEE;
  border-radius: 12px;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: #0B254E;
  display: flex;
  justify-content: space-between;
}

.giftInfo__charged {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.3;
  color: #0B254E;
  margin-bottom: 3px;

}

.currency {
  font-weight: 700;
  font-size: 13px;
  line-height: 40px;
  text-align: center;
  color: #0B254E;
}

.giftInfo__description {
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  color: #0B254E;
  opacity: 0.5;
  margin-bottom: 27px;
}

.giftInfo__img {
  border-radius: 16px;
  width: 253px;
  height: 249px;
}

.giftInfo__container_img {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>